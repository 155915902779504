import React, { Component } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import ModalBoot from '../../components/Modal';
import localhostName from "../../config.json";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import Services from "./Services.json";
import "./Checkout.css"
import { setCookie, getCookie, delete_cookie } from '../../functions/functions.js';
import $ from 'jquery';


export default class CheckoutTest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            country: "de",
            shopping_cart: [],
            customer_data: {},
            zipcodes: { DE: "Postal Code", US: "Zip Code", UK: "Postcode", AU: "Postcode", CA: "Postal Code" },
            billing_country: ("UK" || "US"),
            session: { id: "fb" },
            citiesdata: (this.props.cities_list || []),
            show_contact: false,
            country: (this.props.ip_country || ""),
            checkout: false,
            currency: "USD",
            price: 1,
            vat: 0.2,
            cat: "",
            total_price: 0,
            sites: [],
            state_more: "",
            golftus_special: "",
            address: "",
            error_text: "Please fill in the Form",
            ad_tracker: {
                cities: [],
                tabs: [],
                filter: [],
                pages: []
            }
        };
        this.showFormButton = this.showFormButton.bind(this);
        this.showFormButtonItem = this.showFormButtonItem.bind(this);
        this.changeShipping = this.changeShipping.bind(this);
    }
    componentDidMount() {
        let url = new URL(window.location.href);
        let city = url.searchParams.get("city");
        let country = url.searchParams.get("country");
        let cat = url.searchParams.get("cat");
        if (cat) {
            $(".form-check-input[value='" + cat + "']").prop("checked", true);
        };
        this.setState({ city, country, cat });
        if (country === "UK") {
            this.setState({ currency: "USD" });
        };
        axios.get(localhostName.host + "/Data/citiesover/")
            .then(res => {
                let citiesdata = (Array.isArray(res.data) ? res.data : []);
                this.setState({ citiesdata });
            })
            .catch(function (error) {
                console.log(error);
            });
        this.setState({ shopping_cart: this.state.shopping_cart });
        let ad_tracker = getCookie("ad_tracker");
        if (ad_tracker) {
            this.setState({ ad_tracker: JSON.parse(ad_tracker) });
        };
        this.ad_tracker();
    }
    ad_tracker() {
        let ad_tracker = this.state.ad_tracker;
        let types = { cities: "city", tabs: "tab", filter: "filter", pages: "page" };
        let type = "pages";
        let value = window.location.pathname;
        if (!(ad_tracker[type].includes(value))) {
            ad_tracker[type].push(value);
            this.setState({ ad_tracker: ad_tracker });
            setCookie("ad_tracker", JSON.stringify(ad_tracker), 365);
            axios.post(localhostName.host + '/api/adtrackers/add', { type: types[type], [types[type]]: value })
                .then(res => {
                })
                .catch((error) => {
                });
        };
    }
    showFormButton(targetShow, change) {
        this.setState({ [targetShow]: change });
    }
    showFormButtonItem(targetShow, change, parent) {
        this.setState({ [targetShow]: change, parent: parent });
    }
    changeShipping(e) {
        this.setState({ shipping_address: e.target.value });
    }
    change_billing(value) {
        this.setState({ billing_dif: value });
    }
    change_Form(name, value) {
        this.setState({ [name]: value });
    }
    createSendSession() {
        let shopping_cart = this.state.shopping_cart;
        const newSession = {
            session_id: this.state.session_id,
            shopping_cart: this.state.shopping_cart,
            u_ip: this.state.u_ip,
            country: this.state.i_country,
            city: this.state.i_city,
            lastupdate: new Date()
        };
        axios.post(localhostName.host + '/api/sessions/add', newSession)
            .then(res => window.location.href = '/login')
            .catch(error => { console.log('Logout error') });
    }
    ga_tag(name) {
        window.gtag('event', name, {
            'event_label': 'Dinc Art',
            'event_category': 'form_send',
            'value': true
        });
    }
    change_Form(name, value) {
        let state = this.state;
        state[name] = value;
        this.setState({ [name]: value });
        if (name === "country" && value === "UK") {
            this.setState({ currency: "USD" });
        };
        this.checkData(state);
    }
    change_add_sites(e) {
        let sites = this.state.sites;
        if (e.target.checked) {
            sites.push(e.target.value);
        } else if (!e.target.checked) {
            let index = sites.findIndex((c) => c === e.target.value);
            sites.splice(index, 1);
        };
        this.setState({ sites });
    }
    checkData(state) {
        let check = true;
        let error_text = "";
        let check_list = [
            {
                name: "country",
                err_text: "Country is missing."
            },
            {
                name: "city",
                err_text: "City is missing."
            },
            {
                name: "cat",
                err_text: "Category is missing."
            },
            {
                name: "business_name",
                err_text: "Name is missing."
            },
            {
                name: "address",
                err_text: "Address is missing."
            },
            {
                name: "billing_country",
                err_text: "Billing Country is missing."
            },
            {
                name: "billing_city",
                err_text: "Billing City is missing."
            },
            {
                name: "billing_zipcode",
                err_text: "Billing Zipcode is missing."
            },
            {
                name: "billing_street",
                err_text: "Billing Address is missing."
            },
            {
                name: "email",
                err_text: "Email is missing."
            },
            {
                name: "email_repeat",
                err_text: "Email is missing."
            }

        ];
        check_list.reverse().map((list) => {
            if (!((state[list.name] || "").length > 0)) {
                check = false;
                error_text = list.err_text;
            };
        })
        if (state.email !== state.email_repeat) {
            console.log(state.email_repeat);
            console.log(state.email);
            check = false;
            error_text = "E-Mail repeat is wrong."
        };
        this.setState({ error_text });
        if (check) {
            let ad_tracker = this.state.ad_tracker;
            console.log(ad_tracker);
            if (!ad_tracker.ad_info || ad_tracker.ad_info.name !== this.state.business_name) {
                let value = {
                    category: this.state.cat,
                    country: this.state.country,
                    city: this.state.city,
                    name: this.state.business_name,
                    address: this.state.address,
                    sites: this.state.sites,
                    sites_more: this.state.sites_more,
                    email: this.state.email,
                    info: {
                        website: this.state.info_website,
                        facebook: this.state.info_facebook,
                        twitter: this.state.info_twitter,
                        instagram: this.state.info_instagram,
                        youtube: this.state.info_youtube,
                        tiktok: this.state.info_tiktok,
                        linkedin: this.state.info_linkedin,
                    },
                    golftus_special: this.state.golftus_special,
                    billing: {
                        country: this.state.billing_country,
                        city: this.state.billing_city,
                        company: this.state.billing_company,
                        zipcode: this.state.billing_zipcode,
                        address: this.state.billing_street,
                        region: this.state.billing_region,
                    }
                };
                if (ad_tracker["ad_info"] !== value) {
                    ad_tracker["ad_info"] = value;
                    this.setState({ ad_tracker: ad_tracker });
                    setCookie("ad_tracker", JSON.stringify(ad_tracker), 365);
                    axios.post(localhostName.host + '/api/adtrackers/add', { type: "ad_info", ad_info: value })
                        .then(res => {
                        })
                        .catch((error) => {
                        });
                };
            }
        }
    }
    render() {
        let checkout_step = this.state.checkout_step;
        let shopping_cart = this.state.shopping_cart;
        let total_price = 0;
        shopping_cart.map((item) => {
            total_price = total_price + (item.cart_amount * item.price);
        });
        total_price = Number(total_price.toFixed(2));
        let country = this.state.country;
        let city = this.state.city;
        let zipcodes = this.state.zipcodes;
        let shipping = 30;
        let items = [];
        shopping_cart.map((item) => {
            let new_item = {
                name: item.name.slice(0, 60) + " " + this.state.sizes[item.size],
                unit_amount: { value: item.price, currency_code: 'USD' },
                quantity: item.cart_amount
            };
            items.push(new_item);
        });
        items.push({
            name: "Shipping",
            unit_amount: { value: shipping, currency_code: 'USD' },
            quantity: 1
        });
        let id = this.state.session.id;
        let countries = {
            UK: "United Kingdom", DE: "Germany", ES: "Spain", PT: "Portugal", FR: "France", IE: "Ireland", IT: "Italy", TR: "Turkey", NL: "Netherlands",
            US: "USA", MX: "Mexico", CA: "Canada", AU: "Australia", NZ: "New Zealand", JP: "Japan", CR: "Costa Rica", DO: "Dominican Republic", TH: "Thailand", CO: "Colombia", KR: "South Korea", VN: "Vietnam", BR: "Brazil", AR: "Argentina", CH: "Switzerland", AT: "Austria", ZA: "South Africa", JM: "Jamaica", IS: "Iceland", SE: "Sweden", TW: "Taiwan", NO: "Norway", IN: "New Delhi", BS: "Bahamas", KE: "Kenya", MU: "Mauritius", PHL: "Philippines", PE: "Peru", MY: "Malaysia", AE: "United Arab Emirates", CN: "China", BE: "Belgium", HU: "Hungary", GR: "Greece", GI: "Gibraltar", CZ: "Czech Republic", DK: "Denmark", HK: "Hong Kong", SG: "Singapore", ID: "Indonesia", NP: "Nepal", QA: "Qatar", MT: "Malta", MC: "Monaco", KY: "Cayman Islands", CL: "Chile", NA: "Namibia", GH: "Ghana"
        };
        let countries_names = {};
        Object.keys(countries).map((key, index) => {
            countries_names[countries[key]] = key;
        });
        countries_names = Object.keys(countries_names).sort().reduce(
            (obj, key) => {
                obj[key] = countries_names[key];
                return obj;
            },
            {}
        );
        let cities = this.state.citiesdata.filter((c) => c.country === this.state.country);
        let session_id = Math.random().toString(16).slice(2);
        let price = (this.state.city === "London" ? 1 : 1);
        return (
            <div className="checkout_data">
                <div className="checkout_data_form">
                    <h2>Neu bei Ispiration Art</h2>
                    <div className="">
                        <div className="form_headline">
                            <h3>Golftus Lifetime Listing</h3>
                            <h4>Fill in your Data</h4>
                        </div>
                        <div className="form_in">
                            <label for="cars">Country*</label>
                            <select onChange={(e) => this.change_Form("country", e.target.value)} value={this.state.country}>
                                <option value="" selected="true" disabled="disabled">Choose Country</option>
                                <option value="AU">Australia</option>
                                <option value="CA">Canada</option>
                                <option value="DE">Germany</option>
                                <option value="UK">United Kingdom</option>
                                <option value="US">United States</option>
                                <option value="">---</option>
                                {Object.keys(countries_names).map((key, index) => {
                                    return (
                                        <option value={countries_names[key]}>{key}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <div className="form_in">
                            <label for="cars">City*</label>
                            <select onChange={(e) => this.change_Form("city", e.target.value)} value={this.state.city}>
                                <option selected="true" >Choose City</option>
                                {cities.sort((a, b) => a.name.localeCompare(b.name)).map((city) => {
                                    return (
                                        <option value={city.name}>{city.name}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <div className="form_in_full form_box" onChange={(e) => this.change_Form("cat", e.target.value)} value={this.state.cat}>
                            <div className="label_c headline">Category*</div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="Hotel" />
                                <label className="form-check-label" for="flexRadioDefault2">
                                    Golf Resort & Hotel
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value="Golf" />
                                <label className="form-check-label" for="flexRadioDefault1">
                                    Golf Store
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value="Aesthetics" checked={this.state.cat === "Aesthetics" ? true : ""} />
                                <label className="form-check-label" for="flexRadioDefault1">
                                    Aesthetic Surgery
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="Dental" />
                                <label className="form-check-label" for="flexRadioDefault2">
                                    Dental
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value="Experience" />
                                <label className="form-check-label" for="flexRadioDefault1">
                                    Experience
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value="Coaching" />
                                <label className="form-check-label" for="flexRadioDefault1">
                                    Coaching & Consulting
                                </label>
                            </div>
                            <div className="form-check betaND">
                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value="Workshop" />
                                <label className="form-check-label" for="flexRadioDefault1">
                                    Workshop
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value="Wellness" />
                                <label className="form-check-label" for="flexRadioDefault1">
                                    Wellness & Massage
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="EstateAgent" />
                                <label className="form-check-label" for="flexRadioDefault2">
                                    Estate Agent
                                </label>
                            </div>
                            <div className="form-check betaND">
                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="Bar" />
                                <label className="form-check-label" for="flexRadioDefault2">
                                    Bar/Pub & Cafes
                                </label>
                            </div>
                            <div className="form-check betaND">
                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="Restaurant" />
                                <label className="form-check-label" for="flexRadioDefault2">
                                    Restaurant
                                </label>
                            </div>
                            <div className="form-check betaND">
                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="Fashion" />
                                <label className="form-check-label" for="flexRadioDefault2">
                                    Fashion
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="Antiques" />
                                <label className="form-check-label" for="flexRadioDefault2">
                                    Antiques
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="Art" />
                                <label className="form-check-label" for="flexRadioDefault2">
                                    Art Gallery
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="Jewelry" />
                                <label className="form-check-label" for="flexRadioDefault2">
                                    Jewelry
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="Wine" />
                                <label className="form-check-label" for="flexRadioDefault2">
                                    Wine & Delicacies
                                </label>
                            </div>
                            <div className="form-check betaND">
                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="Entertainment" />
                                <label className="form-check-label" for="flexRadioDefault2">
                                    Entertainment
                                </label>
                            </div>
                        </div>
                    </div>
                    {Services[this.state.cat] ? <div className="Services"><div className="headline">Services</div>
                        {Services[this.state.cat].map((service) => {
                            return (
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value={service} id="Services" />
                                    <label className="form-check-label" for="flexCheckDefault">
                                        {service}
                                    </label>
                                </div>
                            )
                        })}
                    </div> : ""}
                    <div className="data_company form_box">
                        {this.state.cat === "Aesthetics" ?
                            <label>Clinic Name *</label> :
                            <label>Business/Shop Name or your Full Name *</label>
                        }
                        <input type="text" onChange={(e) => this.change_Form("business_name", e.target.value)} value={this.state.business_name} />
                    </div>
                    <div className="data_street  flex form_box">
                        <div className="street">
                            <label>Address* (shown on the map)</label>
                            <input type="text" onChange={(e) => this.change_Form("address", e.target.value)} value={this.state.address} />
                        </div>
                    </div>
                    <div className="business_info form_box">
                        <div className="business_info_title">Your clinic has ...</div>
                        <div className="select_sites" >
                            <div className="form-check">
                                <label className="form-check-label">
                                    Website
                                </label>
                                <input className="" type="text" onChange={(e) => this.change_Form("info_website", e.target.value)} value={this.state.info_website} />
                            </div>
                            <div className="form-check">
                                <label className="form-check-label"><img src="/icons/facebook.png" />Facebook</label>
                                <input className="" type="text" onChange={(e) => this.change_Form("info_facebook", e.target.value)} value={this.state.info_facebook} />
                            </div>
                            <div className="form-check">
                                <label className="form-check-label"><img src="/icons/instagram.png" />Instagram</label>
                                <input className="" type="text" onChange={(e) => this.change_Form("info_instagram", e.target.value)} value={this.state.info_instagram} />
                            </div>
                            <div className="form-check">
                                <label className="form-check-label"><img src="/icons/twitter.png" />Twitter</label>
                                <input className="" type="text" onChange={(e) => this.change_Form("info_twitter", e.target.value)} value={this.state.info_twitter} />
                            </div>
                            <div className="form-check">
                                <label className="form-check-label"><img src="/icons/tiktok.png" />Linkedin</label>
                                <input className="" type="text" onChange={(e) => this.change_Form("info_linkedin", e.target.value)} value={this.state.info_linkedin} />
                            </div>
                            <div className="form-check">
                                <label className="form-check-label"><img src="/icons/tiktok.png" />Youtube</label>
                                <input className="" type="text" onChange={(e) => this.change_Form("info_youtube", e.target.value)} value={this.state.info_youtube} />
                            </div>
                            <div className="form-check">
                                <label className="form-check-label"><img src="/icons/tiktok.png" />TikTok</label>
                                <input className="" type="text" onChange={(e) => this.change_Form("info_tiktok", e.target.value)} value={this.state.info_tiktok} />
                            </div>
                            {/*<div className="more_sites">
                                <div className="data_info_urls">
                                    <label>Something you want to add?</label>
                                    <textarea id="sites_more" type="text" className="info_urls" placeholder="Write a website or websites where we can retrieve information about your company. For example, it can be a Google map url or a Linkedin url etc..." onChange={(e) => this.change_Form("sites_more", e.target.value)} value={this.state.sites_more} ></textarea>
                                </div>
                            </div>*/}
                        </div>
                        <div>
                            <div className="form_box">
                                <div className="data_info_urls">
                                    <label>Do you want to offer a Golftus Special?(optional)</label>
                                    <div className="label_desc">A Special is a gateway to extraordinary benefits and privileges for Golftus Members. It can manifest as a remarkable discount, an exquisite gift, or an extraordinary service —- the sky's the limit with a Golftus Special.</div>
                                    <textarea id="golftus_special" type="text" className="info_urls" placeholder="Write a Golftus Special ..." onChange={(e) => this.change_Form("golftus_special", e.target.value)} value={this.state.golftus_special} ></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="business_info_text form_box example">
                            <span>We aim to collect information about your clinic promptly and have your listing created within 24 hours.</span><br /><br />
                            <div>An <strong>Example</strong> how a listing looks like:</div>
                            <a target="_blank" href="/?city=London&tab=Shops&cat=Aesthetics">See on golftus.com/?city=London&tab=Shops&cat=Aesthetics</a>
                            <img src="/plastic_surgery_example.png" />
                        </div>
                    </div>
                    <h3>Billing</h3>
                    <div className="data_name flex form_box">
                        <div className="data_company">
                            <label>Company or Name *</label>
                            <input type="text" onChange={(e) => this.change_Form("billing_company", e.target.value)} value={this.state.billing_company} />
                        </div>
                        {/*<div className="data_firstname">
                            <label>Firstname*</label>
                            <input type="text" onChange={(e) => this.change_Form("billing_firstname", e.target.value)} value={this.state.billing_firstname} />
                        </div>
                        <div className="data_secondname">
                            <label>Lastname*</label>
                            <input type="text" onChange={(e) => this.change_Form("billing_lastname", e.target.value)} value={this.state.billing_lastname} />
                        </div>*/}
                    </div>
                    <div className="form_headline shipping">
                        <div>Billing Address</div>
                    </div>
                    <div className="country_select">
                        <label>Country*</label>
                        <select onChange={(e) => this.change_Form("billing_country", e.target.value)} value={this.state.billing_country}>
                            <option value="AU">Australia</option>
                            <option value="CA">Canada</option>
                            <option value="DE">Germany</option>
                            <option value="JP">Japan</option>
                            <option value="CH">Switzerland</option>
                            <option value="UK">United Kingdom</option>
                            <option value="US">United States</option>
                            <option disabled="" value="">---</option>
                            <option value="AR">Argentina</option>
                            <option value="AU">Australia</option>
                            <option value="AT">Austria</option>
                            <option value="BE">Belgium</option>
                            <option value="BR">Brazil</option>
                            <option value="BG">Bulgaria</option>
                            <option value="CA">Canada</option>
                            <option value="HR">Croatia</option>
                            <option value="CY">Cyprus</option>
                            <option value="CZ">Czechia</option>
                            <option value="DK">Denmark</option>
                            <option value="EE">Estonia</option>
                            <option value="FI">Finland</option>
                            <option value="FR">France</option>
                            <option value="DE">Germany</option>
                            <option value="GI">Gibraltar</option>
                            <option value="GR">Greece</option>
                            <option value="HK">Hong Kong SAR</option>
                            <option value="HU">Hungary</option>
                            <option value="IS">Iceland</option>
                            <option value="IN">India</option>
                            <option value="ID">Indonesia</option>
                            <option value="IE">Ireland</option>
                            <option value="IL">Israel</option>
                            <option value="IT">Italy</option>
                            <option value="JP">Japan</option>
                            <option value="LV">Latvia</option>
                            <option value="LI">Liechtenstein</option>
                            <option value="LT">Lithuania</option>
                            <option value="LU">Luxembourg</option>
                            <option value="MT">Malta</option>
                            <option value="MX">Mexico</option>
                            <option value="MC">Monaco</option>
                            <option value="NL">Netherlands</option>
                            <option value="NZ">New Zealand</option>
                            <option value="NO">Norway</option>
                            <option value="PL">Poland</option>
                            <option value="PT">Portugal</option>
                            <option value="RO">Romania</option>
                            <option value="SM">San Marino</option>
                            <option value="SG">Singapore</option>
                            <option value="SK">Slovakia</option>
                            <option value="SI">Slovenia</option>
                            <option value="ES">Spain</option>
                            <option value="SE">Sweden</option>
                            <option value="CH">Switzerland</option>
                            <option value="TR">Turkey</option>
                            <option value="AE">United Arab Emirates</option>
                            <option value="GB">United Kingdom</option>
                            <option value="US">United States</option>
                        </select>
                    </div>
                    <div className="data_street  flex form_box">
                        <div className="street">
                            <label>Address*</label>
                            <input type="text" onChange={(e) => this.change_Form("billing_street", e.target.value)} value={this.state.billing_street} />
                        </div>
                    </div>
                    <div className="data_city  flex form_box">
                        <div className="zipcode">
                            <label>{(zipcodes[this.state.country] || "Postal Code") + "*"}</label>
                            <input type="text" onChange={(e) => this.change_Form("billing_zipcode", e.target.value)} value={this.state.billing_zipcode} />
                        </div>
                        <div className="city">
                            <label>City*</label>
                            <input type="text" onChange={(e) => this.change_Form("billing_city", e.target.value)} value={this.state.billing_city} />
                        </div>
                    </div>
                    {country !== "UK" ? <div className="state">
                        <label>Region/State*</label>
                        <RegionDropdown
                            countryValueType="short"
                            country={this.state.billing_country}
                            value={this.state.billing_region}
                            onChange={(e) => this.change_Form("billing_region", e)} />
                    </div> : ""}
                    <div className="data_email flex form_box">
                        <div className="data_email_first">
                            <label>Email*</label>
                            <input type="email" onChange={(e) => this.change_Form("email", e.target.value)} value={this.state.email} />
                        </div>
                        <div className="data_email_second">
                            <label>Email Repeat*</label>
                            <input type="email" onChange={(e) => this.change_Form("email_repeat", e.target.value)} value={this.state.email_repeat} />
                        </div>
                    </div>
                    <div className="checkout_info_list">
                        <div className="order_summary">
                            <h3>Product Summary</h3>
                            <div>
                                -Lifetime Listing in {this.state.city} Shop & more Tab
                            </div>
                        </div>
                    </div>
                    <div className="checkout_info_list">
                        <div className="order_summary">
                            <h3>Summary</h3>
                            <div className="cart_total_sum" >
                                <div className="total_sum_part">
                                    <div>Lifetime Listing:</div>
                                    <div>{this.state.currency === "GBP" ? "£" : "$"}{price} {this.state.currency}</div>
                                </div>
                                <div className="total_sum_part">
                                    <div>VAT:</div>
                                    <div>{this.state.currency === "GBP" ? "£" : "$"}{(price * this.state.vat).toFixed(2)}</div>
                                </div>
                                <div className="total_sum_part total">
                                    <div>Total:</div>
                                    <div>{this.state.currency === "GBP" ? "£" : "$"}{Number(price) + Number((price * this.state.vat).toFixed(2))}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="payment_overview">
                        <h3 className="title" >Payment</h3>
                        <div>All transactions are secure and encrypted.</div>
                        {this.state.error_text.length > 0 ? this.state.error_text : ""}
                        {this.state.error_text.length > 0 ? <div className="error_box" onClick={() => this.showFormButton("error_text_show", true)}></div> : ""}
                        <div className="payment_paypal" onClick={() => { this.ga_tag("checkout_paypal"); }}>
                            <PayPalScriptProvider options={{ "client-id": "AcA4epZFEIH-ShSQGJe5P4BYET7ZnWamGXOvznu3ziuB415-4UZS5eaXO-Hg34L0dRWh04XomoGqqVod", currency: 'USD', intent: 'capture' }}>
                                <PayPalButtons
                                    disabled={false}
                                    fundingSource={undefined}
                                    createOrder={(data, actions) => {
                                        return actions.order.create({
                                            purchase_units: [
                                                {
                                                    amount: {
                                                        value: (Number(price) + Number((price * this.state.vat).toFixed(2))),
                                                        currency_code: 'USD',
                                                        breakdown: {
                                                            item_total: { value: (Number(price) + Number((price * this.state.vat).toFixed(2))), currency_code: 'USD' }
                                                        }
                                                    },
                                                    invoice_id: session_id,
                                                    items: [{
                                                        name: "Golftus Lifetime Listing - " + this.state.business_name,
                                                        unit_amount: { value: price, currency_code: 'USD' },
                                                        quantity: 1
                                                    },
                                                    {
                                                        name: "VAT - " + this.state.company,
                                                        unit_amount: { value: (price * this.state.vat).toFixed(2), currency_code: 'USD' },
                                                        quantity: 1
                                                    },
                                                    ]
                                                },
                                            ],
                                        });
                                    }}
                                    onApprove={(data, actions) => {
                                        return actions.order.capture().then((details) => {
                                            let order = {
                                                category: this.state.cat,
                                                country: this.state.country,
                                                city: this.state.city,
                                                name: this.state.business_name,
                                                address: this.state.address,
                                                sites: this.state.sites,
                                                sites_more: this.state.sites_more,
                                                price: price,
                                                vat: (price * this.state.vat).toFixed(2),
                                                total_price: (Number(price) + Number((price * this.state.vat).toFixed(2))),
                                                email: this.state.email,
                                                currency: this.state.currency,
                                                id: session_id,
                                                info: {
                                                    website: this.state.info_website,
                                                    facebook: this.state.info_facebook,
                                                    twitter: this.state.info_twitter,
                                                    instagram: this.state.info_instagram,
                                                    youtube: this.state.info_youtube,
                                                    tiktok: this.state.info_tiktok,
                                                    linkedin: this.state.info_linkedin,
                                                },
                                                golftus_special: this.state.golftus_special,
                                                billing: {
                                                    country: this.state.billing_country,
                                                    city: this.state.billing_city,
                                                    company: this.state.billing_company,
                                                    zipcode: this.state.billing_zipcode,
                                                    address: this.state.billing_street,
                                                    region: this.state.billing_region,
                                                }
                                            };
                                            console.log(order);
                                            let getCookie = function (name) {
                                                var nameEQ = name + "=";
                                                var ca = document.cookie.split(';');
                                                for (var i = 0; i < ca.length; i++) {
                                                    var c = ca[i];
                                                    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
                                                    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
                                                }
                                                return null;
                                            };
                                            function delete_cookie(name, path, domain) {
                                                if (getCookie(name)) {
                                                    document.cookie = name + "=" +
                                                        ((path) ? ";path=" + path : "") +
                                                        ((domain) ? ";domain=" + domain : "") +
                                                        ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
                                                }
                                            };
                                            const responsePromise = axios.post(localhostName.host + '/api/orders/add', order, { withCredentials: true })
                                                .then(res => {
                                                    window.location.href = '/order?id=' + res.data.id;
                                                })
                                                .catch(function (error) {
                                                    console.log(error);
                                                });
                                        });
                                    }}
                                />
                            </PayPalScriptProvider>
                        </div>
                    </div>
                </div>
                <footer>
                    <div className="container">
                        <ul>
                            {/*<li class="nav-item">
                            <a class="nav-link" href="/datenschutz">FAQ</Link>
                        </li>*/}
                            <li className="nav-item">
                                <Link className="nav-link" to="#contact" onClick={() => this.change_Form("show_contact", true)}>Contact</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/terms-and-conditions">Ts&Cs</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/privacy-policy">Privacy & Cookies</Link>
                            </li>
                        </ul>
                        <ul className="payment_list">
                            <li className="payment_item">
                                <svg viewBox="0 0 38 24" xmlns="http://www.w3.org/2000/svg" width="38" height="24" role="img" aria-labelledby="pi-paypal"><title id="pi-paypal">PayPal</title><path opacity=".07" d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z"></path><path fill="#fff" d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32"></path><path fill="#003087" d="M23.9 8.3c.2-1 0-1.7-.6-2.3-.6-.7-1.7-1-3.1-1h-4.1c-.3 0-.5.2-.6.5L14 15.6c0 .2.1.4.3.4H17l.4-3.4 1.8-2.2 4.7-2.1z"></path><path fill="#3086C8" d="M23.9 8.3l-.2.2c-.5 2.8-2.2 3.8-4.6 3.8H18c-.3 0-.5.2-.6.5l-.6 3.9-.2 1c0 .2.1.4.3.4H19c.3 0 .5-.2.5-.4v-.1l.4-2.4v-.1c0-.2.3-.4.5-.4h.3c2.1 0 3.7-.8 4.1-3.2.2-1 .1-1.8-.4-2.4-.1-.5-.3-.7-.5-.8z"></path><path fill="#012169" d="M23.3 8.1c-.1-.1-.2-.1-.3-.1-.1 0-.2 0-.3-.1-.3-.1-.7-.1-1.1-.1h-3c-.1 0-.2 0-.2.1-.2.1-.3.2-.3.4l-.7 4.4v.1c0-.3.3-.5.6-.5h1.3c2.5 0 4.1-1 4.6-3.8v-.2c-.1-.1-.3-.2-.5-.2h-.1z"></path></svg>
                            </li>
                            <li className="payment_item">
                                <svg viewBox="0 0 38 24" xmlns="http://www.w3.org/2000/svg" width="38" height="24" role="img" aria-labelledby="pi-maestro"><title id="pi-maestro">Maestro</title><path opacity=".07" d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z"></path><path fill="#fff" d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32"></path><circle fill="#EB001B" cx="15" cy="12" r="7"></circle><circle fill="#00A2E5" cx="23" cy="12" r="7"></circle><path fill="#7375CF" d="M22 12c0-2.4-1.2-4.5-3-5.7-1.8 1.3-3 3.4-3 5.7s1.2 4.5 3 5.7c1.8-1.2 3-3.3 3-5.7z"></path></svg>
                            </li>
                            <li className="payment_item">
                                <svg viewBox="0 0 38 24" xmlns="http://www.w3.org/2000/svg" role="img" width="38" height="24" aria-labelledby="pi-master"><title id="pi-master">Mastercard</title><path opacity=".07" d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z"></path><path fill="#fff" d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32"></path><circle fill="#EB001B" cx="15" cy="12" r="7"></circle><circle fill="#F79E1B" cx="23" cy="12" r="7"></circle><path fill="#FF5F00" d="M22 12c0-2.4-1.2-4.5-3-5.7-1.8 1.3-3 3.4-3 5.7s1.2 4.5 3 5.7c1.8-1.2 3-3.3 3-5.7z"></path></svg>
                            </li>
                            <li className="payment_item">
                                <svg viewBox="0 0 38 24" xmlns="http://www.w3.org/2000/svg" role="img" width="38" height="24" aria-labelledby="pi-visa"><title id="pi-visa">Visa</title><path opacity=".07" d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z"></path><path fill="#fff" d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32"></path><path d="M28.3 10.1H28c-.4 1-.7 1.5-1 3h1.9c-.3-1.5-.3-2.2-.6-3zm2.9 5.9h-1.7c-.1 0-.1 0-.2-.1l-.2-.9-.1-.2h-2.4c-.1 0-.2 0-.2.2l-.3.9c0 .1-.1.1-.1.1h-2.1l.2-.5L27 8.7c0-.5.3-.7.8-.7h1.5c.1 0 .2 0 .2.2l1.4 6.5c.1.4.2.7.2 1.1.1.1.1.1.1.2zm-13.4-.3l.4-1.8c.1 0 .2.1.2.1.7.3 1.4.5 2.1.4.2 0 .5-.1.7-.2.5-.2.5-.7.1-1.1-.2-.2-.5-.3-.8-.5-.4-.2-.8-.4-1.1-.7-1.2-1-.8-2.4-.1-3.1.6-.4.9-.8 1.7-.8 1.2 0 2.5 0 3.1.2h.1c-.1.6-.2 1.1-.4 1.7-.5-.2-1-.4-1.5-.4-.3 0-.6 0-.9.1-.2 0-.3.1-.4.2-.2.2-.2.5 0 .7l.5.4c.4.2.8.4 1.1.6.5.3 1 .8 1.1 1.4.2.9-.1 1.7-.9 2.3-.5.4-.7.6-1.4.6-1.4 0-2.5.1-3.4-.2-.1.2-.1.2-.2.1zm-3.5.3c.1-.7.1-.7.2-1 .5-2.2 1-4.5 1.4-6.7.1-.2.1-.3.3-.3H18c-.2 1.2-.4 2.1-.7 3.2-.3 1.5-.6 3-1 4.5 0 .2-.1.2-.3.2M5 8.2c0-.1.2-.2.3-.2h3.4c.5 0 .9.3 1 .8l.9 4.4c0 .1 0 .1.1.2 0-.1.1-.1.1-.1l2.1-5.1c-.1-.1 0-.2.1-.2h2.1c0 .1 0 .1-.1.2l-3.1 7.3c-.1.2-.1.3-.2.4-.1.1-.3 0-.5 0H9.7c-.1 0-.2 0-.2-.2L7.9 9.5c-.2-.2-.5-.5-.9-.6-.6-.3-1.7-.5-1.9-.5L5 8.2z" fill="#142688"></path></svg>
                            </li>
                        </ul>
                        <p className="copyright">©golftùs 2023</p>
                    </div>
                </footer>
                <ModalBoot show={this.state.error_text_show} hide={this.showFormButton} content={"error_text_show"} className="modal_Login errorModal">
                    <div>{this.state.error_text}</div>
                </ModalBoot >
            </div>
        )
    }
} 