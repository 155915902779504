import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Routes, BrowserRouter } from "react-router-dom";
import $ from 'jquery';
import website_info from "./Data/website.json";

export default class PageHeaderMobile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nav_menu: false,
        };
    }
    componentDidMount() {
    }
    nav_menu() {
        this.setState({ nav_menu: !this.state.nav_menu });
    }
    render() {
        return (
            <div className={"cities_page_header mobile_extern_header" + (this.state.nav_menu ? " nav_menu_open" : "")} ><nav className="navbar navbar_mobile navbar-expand-lg navbar-light bg-light" >
                <button onClick={() => this.nav_menu()} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <Link className="navbar-brand" to="/"><div className="mob_title">Golftus</div></Link>
                <div class="nav_tab login">
                    {this.props.logged ?
                        <div className="user_log"><Link to={"/profile#user"}><img className="" src="/icons/userA.png" /><span>{this.props.user.firstname} {this.props.user.lastname.charAt(0)}.</span></Link></div>
                        :
                        <button onClick={() => $('#Login_modal').addClass('show')}>Login</button>
                    }
                </div>
                <div className="navbar-collapse" id="navbarNavAltMarkup">
                    <div className="navbar-nav">
                        <Link className="nav-item nav-link" to="/">Cities <span className="sr-only">(current)</span></Link>
                        <hr className="nav_line"></hr>
                        <div className="techniques_nav">{website_info.more_nav}</div>
                        {(website_info.technique_pages || []).map(p => {
                            return (<a className="nav-item nav-link" href={p.url} unstable_viewTransition>{p.name}</a>)
                        })}
                        <hr className="nav_line"></hr>
                        <Link className="nav-item nav-link" to="/join">Join Golftus</Link>
                        <Link className="nav-item nav-link" to="/membership">Membership</Link>
                        <Link className="nav-item nav-link" to="/aboutus">About us</Link>
                        <Link className="nav-item nav-link" to="/contact">Contact</Link>
                        <Link className="nav-item nav-link" to="/terms-and-conditions">Ts&Cs</Link>
                        <Link className="nav-item nav-link" to="/privacy-policy">Privacy & Cookies</Link>
                        <hr className="nav_line"></hr>
                        <div>
                            <div className="login_b">Login</div>
                        </div>
                    </div>
                </div>
            </nav>
            </div>
        )
    }
}  