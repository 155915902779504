import React, { Component } from 'react';
import axios from 'axios';
import { Container, Row, Col, Button, DropdownButton, Dropdown, Form } from 'react-bootstrap';
import { Link } from "react-router-dom";
import BucketButtonModal from './BucketButtonModal';
import PageHeaderDesktop from './PageHeaderDesktop';
import PageHeaderMobile from './PageHeaderMobile';
import MembershipFAQ from './MembershipFAQ';
import Footer from './Footer';
import $ from 'jquery';
import content from "./Data/content.json";
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";


export default class MembershipPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            language: "de",
            actualArtwork: {},
            cities_list: [],
            cities_filter: [],
            devoPics: [],
            settings: { _id: "df" },
            userInfo: this.props.userInfo,
            selected_city: {},
            show_filters: false,
            weather_filter: "",
            weather_month: (new Date).getMonth() + 1,
            continent_filter: [],
            pop_filter: "",
            gol_cont_filter: "",
            surf_points: 0,
            search_city: "",
            sort_by: "",
            filter_country: "",
            not_rainy: false,
            sunbathing: false,
            data_loaded: false,
            filter: [],
            filter_values: {},
            slider_filter: {}
        };
        this.showFormButton = this.showFormButton.bind(this);
        this.onChange = this.onChange.bind(this);
    }
    onChange(e) {
        this.setState({ [e.target.id]: e.target.value });
    }
    setCookie(name, value, days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }
    getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }
    componentDidMount() {
        
    }
    showFormButton(targetShow, change) {
    }
    nav_menu() {
        this.setState({ nav_menu: !this.state.nav_menu });
    }
    openLogin(){
        $('#Login_modal').addClass('show')
    }
    render() {
        let filter = this.state.filter;
        let check_filter = function (name) {
            let filter_index = filter.findIndex((filter) => filter.name == name);
            return ((filter[filter_index] || { value: false }).value ? "selected" : "not_selected");
        };
        let filter_value = function (name) {
            let filter_index = filter.findIndex((filter) => filter.name == name);
            return (filter[filter_index] || { value: false }).value;
        };
        let countries = {
            UK: "United Kingdom", DE: "Germany", ES: "Spain", PT: "Portugal", FR: "France", IE: "Ireland", IT: "Italy", TR: "Turkey", NL: "Netherlands",
            US: "USA", MX: "Mexico", CA: "Canada", AU: "Australia", NZ: "New Zealand", JP: "Japan", CR: "Costa Rica", DO: "Dominican Republic", TH: "Thailand", CO: "Colombia", KR: "South Korea", VN: "Vietnam", BR: "Brazil", AR: "Argentina", CH: "Switzerland", AT: "Austria", ZA: "South Africa", JM: "Jamaica", IS: "Iceland", SE: "Sweden", TW: "Taiwan", NO: "Norway", IN: "New Delhi", BS: "Bahamas", KE: "Kenya", MU: "Mauritius", PHL: "Philippines", PE: "Peru", MY: "Malaysia", AE: "United Arab Emirates", CN: "China", BE: "Belgium", HU: "Hungary", GR: "Greece", GI: "Gibraltar", CZ: "Czech Republic", DK: "Denmark", HK: "Hong Kong", SG: "Singapore", ID: "Indonesia", NP: "Nepal", QA: "Qatar", MT: "Malta", MC: "Monaco", KY: "Cayman Islands", CL: "Chile", NA: "Namibia", GH: "Ghana"
        };
        let countries_names = {};
        Object.keys(countries).map((key, index) => {
            countries_names[countries[key]] = key;
        });
        countries_names = Object.keys(countries_names).sort().reduce(
            (obj, key) => {
                obj[key] = countries_names[key];
                return obj;
            },
            {}
        );
        return (
            <div className={"membership_page cities_page " + (isMobile ? "mobile" : "")}>
                {isMobile ?
                    <PageHeaderMobile key={this.props.logged} logged={this.props.logged} user={this.props.user} />
                    :
                    <PageHeaderDesktop key={this.props.logged} logged={this.props.logged} user={this.props.user} />
                }
                <div className="page_content container">
                    <h1>Membership</h1>
                    <div>
                        <div className="member_over_vid">
                            <h2>What you get as Golftus Basic Lifetime Member</h2>
                            <h3 className="content_title">Access all data all time 24/7</h3>
                            <div>Get unlimited members-only access to cities across 70+ countries and to over 20.000 golf courses worldwide. Research the best places to golf, sightsee and have fun. The data and number of Cities is growing by every month.</div>
                            <img src="/member1.gif" />
                        </div>
                        <div className="content_list">
                            <div className="content_title">Additional content beyond golf destinations</div>
                            {content.map(c => {
                                return (
                                    <Link to={c.url} target="_blank" className="main_c">
                                        <img src={c.image} />
                                        <div className="overlay"></div>
                                        <div className="c_text">
                                            <div>{c.name}</div>
                                        </div>
                                    </Link>
                                )
                            })}
                        </div>
                        <div className="member_features">
                            <img src="/profile_exp.png" />
                            <div className="box">
                                <img src="/icons/man.png" />
                                <h3>Manage your Profile</h3>
                                <div>Add countries and cities you've played in and track your travels. Collect badges and more. Add your favourite Golf Courses and Travel Destinations to your Bucket List.</div>
                            </div>
                            <div className="box">
                                <img src="/icons/filter.png" />
                                <h3>Unlock Filters</h3>
                                <div>Unlock hundreds of filters to select golf travel destinations according to your preferences and find the perfect place to play golf and travel.</div>
                            </div>
                            <div className="box">
                                <img src="/icons/onetime_payment.png" />
                                <h3>One Time Payment</h3>
                                <div>Golftus Lifetime Membership is available for a one-time payment of $49. Unlike other platforms that charge recurring monthly fees, we offer an incredible deal – just one payment for a lifetime of valuable content and resources. </div>
                            </div>
                            <div className="box">
                                <img src="/icons/future_updates.png" />
                                <h3>Future Updates</h3>
                                <div>Golftus Lifetime Membership guarantees access to all future updates and enhancements on our website.</div>
                            </div>
                        </div>
                        <div className="bottom_register header_register_box">
                            <div className="box">
                                <div className="member_text"><div>Golftus Memebership</div>Lifetime membership now for just $49. <div className="sub_headline">No monthly payments - only a one-time payment of $49</div><div className="money_back_join">+ 100-Day Money Back Guarantee</div>  <span onClick={()=> {$('#MembershipFAQ_modal').addClass('show')}}>+learn more</span></div>
                                {/*<input type="email" id="register_email" onChange={(e) => {this.onChange(e);this.setCookie("register_email",e.target.value,1)}} value={this.state.register_email} placeholder="Type your email..." />*/}
                                <Link to="/join" state={{ register_email: "this.state.register_email" }}><button>Proceed to registration</button></Link>
                                <div className="already_login" >Already a member? <span onClick={() => $('#Login_modal').addClass('show')}>Log in</span></div>
                            </div>
                        </div>
                        <div>
                            <h1>FAQ</h1>
                            <MembershipFAQ />
                        </div>
                    </div>
                </div>
                <BucketButtonModal />
                <Footer />
            </div>
        )

    }
}    