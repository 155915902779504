import React, {Component} from 'react';
import localhostName from "./config.json";

import AppDesktop from './AppDesktop';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";


export default class App extends Component {
  constructor() {
      super();
      this.state = {
      };
  }
  render() {
    if(isBrowser){
      return(
        <AppDesktop />
      )
    }
    else if(isMobile){
      return(
        <AppDesktop />
      )
    }else{
      return(
        <AppDesktop />
      );
    };     
  }
}


