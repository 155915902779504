import React, { Component } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import localhostName from "../config.json";
import $ from 'jquery';
import CityOverview from './CityOverview';

export default class BucketPlayedButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            language: "de",
            actualArtwork: {},
            clicked: false,
        };
    }
    componentDidMount() {
        $(".BucketButtonModal .btn-close").click(function () {
            $(".BucketButtonModal").removeClass("show");
        });
    }
    login(e) {
        e.preventDefault();
        this.setState({ clicked: true });
    }
    addCity(type, e) {
        let new_cities = { cities: [{ name: this.props.cityName, country: this.props.country }] };
        if (type === "played") {
            axios.post(localhostName.host + '/api/users/addcity', new_cities, { withCredentials: true })
                .then(res => {
                    alert("saved");
                    this.props.reload();
                })
                .catch(function (error) {
                    console.log(error);
                });
        } if (type === "played_remove") {
            axios.post(localhostName.host + '/api/users/removecity', new_cities, { withCredentials: true })
                .then(res => {
                    alert("removed");
                    this.props.reload();
                })
                .catch(function (error) {
                    console.log(error);
                });
        } else if (type === "bucket") {
            axios.post(localhostName.host + '/api/users/addbucketcity', new_cities, { withCredentials: true })
                .then(res => {
                    alert("saved");
                    this.props.reload();
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
        else if (type === "bucket_remove") {
            axios.post(localhostName.host + '/api/users/removebucketcity', new_cities, { withCredentials: true })
                .then(res => {
                    alert("removed");
                    this.props.reload();
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }
    clickButton(type, e) {
        e.preventDefault();
        console.log(this.props.logged);
        if (this.props.logged) {
            this.addCity(type, e);
        } else {
            if (!$(e.target).closest(".BucketButtonModal #logreg-forms").length) {
                $(".BucketButtonModal").removeClass("show");
            };
            if ($(e.target).closest(".to_collect").length) {
                $(".BucketButtonModal").addClass("show");
                $(".must_logged").html("!You have to be logged in to add something to your collection.");

            };
            if ($(e.target).closest(".to_bucketlist").length) {
                $(".BucketButtonModal").addClass("show");
                $(".must_logged").html("!You have to be logged in to add something to your bucket list.");
            };
        }
    }
    render() {
        return (
            (!this.props.played ?
                <div className="BucketCollectButton">
                    <div className="to_collect" onClick={(e) => { this.clickButton("played", e) }}>+Played</div>
                    {!this.props.bucket ?
                    <div className="to_bucketlist" onClick={(e) => { this.clickButton("bucket", e) }}>+Bucket List</div>
                    :
                    <div className="to_bucketlist bucked" onClick={(e) => { this.clickButton("bucket_remove", e) }}><i className="fa fa-check"></i>On Bucket List</div>
                    }
                </div> :
                <div className="BucketCollectButton played">
                    <div className="to_collect" onClick={(e) => { this.clickButton("played_remove", e) }}><i className="fa fa-check"></i>Played</div>
                </div>
            )
        )
    }
}    