import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Navbar from '../components/Navbar';
import Footer from './Footer';
import UserProfile from './UserProfile';
import ClubMail from './User/ClubMail.js';
import Collection from './User/Collection.js';
import axios from 'axios';
import LoginModal from './LoginModal';
import ModalBoot from '../components/Modal';
import localhostName from "../config.json";
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
import $ from 'jquery';

export default class ProfilePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: "profile",
            buddies: [],
            user_data: {},
            user: {},
            cities_over: [],
            nav_menu: false,
            showLogin: false
        };
        this.showFormButton = this.showFormButton.bind(this);
    }
    checkLogged() {
        axios.get(localhostName.host + '/api/users/logged', { withCredentials: true })
            .then(res => {
                if (res.data.status) {
                    this.setState({ user: res.data.user, logged: true });
                } else {
                    this.setState({ logged: false, user: {} });
                    this.showFormButton("showLogin", true);
                }
            })
            .catch(function (error) {
                window.location.replace("/");
                console.log(error);
            });
        axios.get(localhostName.host + '/Data/citiesover')
            .then(res => {
                this.setState({ cities_over: res.data });
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    componentDidMount() {
        this.checkLogged();
    }
    showFormButton(targetShow, change) {
        this.setState({ [targetShow]: change });
    }
    changePage(value) {
        this.setState({ page: value })
    }
    logout() {
        axios.get(localhostName.host + '/api/users/logout', { withCredentials: true })
            .then(res => {
                window.location.replace("/");
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    nav_menu() {
        this.setState({ nav_menu: !this.state.nav_menu });
    }
    render() {
        return (
            <div>
                {isMobile ?
                    <header className="App-header">
                        <nav className={"navbar navbar-profile " + (this.state.nav_menu ? " nav_menu_open" : "")}>
                            <Link className="nav_a" to="/" >
                                <img src="/icons/cityscape.png" />
                                <div>Cities</div>
                            </Link>
                            {/*<a className="nav_a" href="#mail" onClick={()=>{this.changePage("club_mail")}}>
                            <img src="/icons/mail.png" />
                            <div>ClubMail</div>
                        </a>*/}
                            {/*<a className="nav_a" href="#collection" onClick={()=>{this.changePage("collection")}}>
                            <img src="/icons/collection.png" />
                            <div>Collection</div>
                        </a>*/}
                            <Link className="nav_a" to="/profile" onClick={() => { this.changePage("profile") }}>
                                <img src="/icons/user.png" />
                                <div>Profile</div>
                            </Link>
                            <div className="nav_a" onClick={() => { this.logout() }}>
                                <img src="/icons/logout.png" />
                                <div>Logout</div>
                            </div>
                            <div className="nav_a" onClick={() => this.nav_menu()}>
                                <img src="/icons/menu.png" />
                                <div>Menu</div>
                            </div>
                            <div className="navbar-collapse" id="navbarNavAltMarkup">
                                <div className="navbar-nav">
                                    <Link className="nav-item nav-link" to="/membership">Membership</Link>
                                    <Link className="nav-item nav-link" to="/aboutus">About us</Link>
                                    <Link className="nav-item nav-link" to="/contact">Contact</Link>
                                    <Link className="nav-item nav-link" to="/terms-and-conditions">Ts&Cs</Link>
                                    <Link className="nav-item nav-link" to="/privacy-policy">Privacy & Cookies</Link>
                                </div>
                            </div>
                        </nav>
                    </header>
                    :
                    <header className="App-header desktop">
                        <nav className="navbar navbar-profile">
                            <a className="nav_a brand" href="/" >
                                Golftus
                            </a>
                            <a className="nav_a" href="/" >
                                <div>Cities</div>
                            </a>
                            {/*<a className="nav_a" href="#mail" onClick={()=>{this.changePage("club_mail")}}>
                            <div>ClubMail</div>
                        </a>*/}
                            {/*<a className="nav_a" href="#collection" onClick={()=>{this.changePage("collection")}}>
                            <div>Collection</div>
                        </a>*/}
                            <a className="nav_a" href="/profile" onClick={() => { this.changePage("profile") }}>
                                <div>Profile</div>
                            </a>
                            <a className="nav_a" href="/profile" onClick={() => { this.changePage("profile") }}>
                                <div>Settings</div>
                            </a>
                            <a className="nav_a" href="#logout" onClick={() => { this.logout() }}>
                                <div>Logout</div>
                            </a>
                            <a className="nav_a menu_nav dropdown" href="#">
                                <img src="/icons/menu.png" />
                                {/*<div>Menu</div>*/}
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <Link class="dropdown-item" to="/aboutus"><img className="nav_icon" src="/icons/group.png" />About us</Link>
                                    <Link class="dropdown-item" to="/contact"><img className="nav_icon" src="/icons/letter.png" />Contact</Link>
                                    <Link class="dropdown-item" to="/terms-and-conditions"><img className="nav_icon" src="/icons/terms-and-conditions.png" />Ts&Cs</Link>
                                    <Link class="dropdown-item" to="/privacy-policy"><img className="nav_icon" src="/icons/privacy-policy.png" />Privacy & Cookies</Link>
                                </div>
                            </a>
                        </nav>
                    </header>
                }
                <ModalBoot show={this.state.showLogin} hide={this.showFormButton} content={"showLogin"} className="modal_Login">
                    <LoginModal hide={this.showFormButton} checkLogged={this.checkLogged} />
                </ModalBoot >
                <div key={this.state.user_data}>
                    {this.state.page === "profile" && this.state.user._id ? <UserProfile status={"self"} cities_over={this.state.cities_over} user={this.state.user} key={this.state.user + this.state.cities_over} /> : ""}
                </div>
                {this.state.page === "club_mail" ? <ClubMail /> : ""}
                {this.state.page === "collection" ? <Collection /> : ""}
                <Footer />
            </div>
        )
    }
}    