import React, { Component } from 'react';
import { Link } from "react-router-dom";
import $ from 'jquery';

export default class MustMember extends Component {
    render() {
        return (
            <div className="must_member member_ship">
                <Link to="/join" state={{ register_email: "this.state.register_email" }}><button>Proceed to registration</button></Link>
                <div className="already_login">Already a member? <span>Log in</span></div>
            </div>
        )
    }
}    