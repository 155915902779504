import React, { Component } from 'react';
import Checkout from './Checkout/Checkout.js';
import axios from 'axios';
import { Link } from "react-router-dom";
import ModalBoot from '../components/Modal';
import localhostName from "../config.json";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';

import $ from 'jquery';

export default class AdvertisePageCheckout extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    componentDidMount() {
    }
    render() {
        return (
            <div className="advertise_checkout">
                <div className="navbar container">
                    <div className="nav_logo">
                        <div><strong>Golftus</strong></div>
                        <div className="adv">Advertising</div>
                    </div>
                </div>
                <Checkout />
            </div>
        )
    }
} 