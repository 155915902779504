import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Routes, BrowserRouter } from "react-router-dom";
import $ from 'jquery';
import website_info from "./Data/website.json";

export default class PageHeaderDesktop extends Component {
    constructor(props) {
        super(props);
        this.props = {
        };
    }
    componentDidMount() {
    }
    updateContents() {
    }
    render() {
        return (
            <div className="cities_page_header desktop_extern_header"> <div className="header_navbar">
                <Link to="/"><div className="logo_name">Golftus</div></Link>
                <div className="links_navbar">
                    <Link to="/"><div className="nav_tab">Cities</div></Link>
                    <Link to="/membership"><div className="nav_tab">Membership</div></Link>
                    <Link to="/faq"><div className="nav_tab betaND">FAQ</div></Link>
                    <a class="dropdown">
                        <div className="nav_tab">
                            {website_info.more_nav}
                        </div>
                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                            <div className="techniques">
                                <div className="techniques_nav">Improve your game</div>
                                {(website_info.technique_pages || []).map(p => {
                                    return (<div class="dropdown-item" onClick={() => { window.location.href = p.url; }} ><img className="nav_icon" src={"/icons/" + p.icon} />{p.name}</div>)
                                })}
                            </div>
                            <div>
                                <Link class="dropdown-item" to="/aboutus"><img className="nav_icon" src="/icons/group.png" />About us</Link>
                                <Link class="dropdown-item" to="/contact"><img className="nav_icon" src="/icons/letter.png" />Contact</Link>
                                <Link class="dropdown-item" to="/terms-and-conditions"><img className="nav_icon" src="/icons/terms-and-conditions.png" />Ts&Cs</Link>
                                <Link class="dropdown-item" to="/privacy-policy"><img className="nav_icon" src="/icons/privacy-policy.png" />Privacy & Cookies</Link>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="nav_tab login">
                    {this.props.logged ?
                        <div className="user_log"><Link to={"/profile#user"}><img className="" src="/icons/userA.png" /><span>{this.props.user.firstname} {this.props.user.lastname.charAt(0)}.</span></Link></div>
                        :
                        <button onClick={() => $('#Login_modal').addClass('show')}>Login</button>
                    }
                </div>
            </div>
            </div>
        )
    }
}  