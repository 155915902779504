import React, { Component } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import localhostName from "../config.json";
import { MapContainer, TileLayer, useMap, Marker, Popup, Circle } from 'react-leaflet';
import BucketCollectButton from './BucketCollectButton';
import ModalBoot from '../components/Modal';
import LoadingSVG from './LoadingSVG';
import {BeautyClinicShop, DefaultShop} from './Shops';
import InstagramEmbed from './InstagramEmbed';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import $ from 'jquery';

export default class CityShops extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clinics: [],
            shops: [],
            citiesgeo: "",
            data_loaded: false,
            selected_shop: {youtube: []},
            selected_video: {},
            filter: {
                distance: "100",
                type: [],
                misc: []
            }
        };
        this.showFormButton = this.showFormButton.bind(this);
        this.shopHover = this.shopHover.bind(this);
        this.selectShop = this.selectShop.bind(this);
    }
    componentDidMount() {
        axios.get(localhostName.host + "/Data/Shops/Cities/" + this.props.cityName)
            .then(res => {
                let shops = res.data;
                this.setState({ data_loaded: true, shops: shops });
            })
            .catch(function (error) {
                console.log(error);
            });
        axios.get(localhostName.host + "/Data/Citygeo/" + this.props.cityName)
            .then(res => {
                let citiesgeo = res.data;
                this.setState({ citiesgeo: citiesgeo });
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    setFilter(filt, value) {
        let filter = this.state.filter;
        if (filter[filt] === value && filt !== "distance") {
            filter[filt] = ""
        } else {
            filter[filt] = value;
        };
        let courses = this.state.courses;
        courses = courses.filter((c) => Number(c.citydistance) < (filter.distance + 5));
        if (filter.ranking.length > 0) {
            courses = courses.filter((c) => c.rankings.findIndex((r) => r.name === filter.ranking) > -1);
        };
        this.setState({ filter: filter, courses_number: courses.length });
    }
    showFormButton(targetShow, change) {
        this.setState({ [targetShow]: change });
    }
    selectShop(shop){
        this.setState({ selected_shop: shop });
    }
    setFilterArray(filt, value) {
        let filter = this.state.filter;
        if (filter[filt].includes(value)) {
            filter[filt] = filter[filt].filter((f) => f !== value);
        } else {
            filter[filt].push(value);
        };
        this.setState({ filter: filter });
    }
    renderShopsMap() {
        let shops = this.state.shops;
        let filter = this.state.filter;
        let filter_type = this.state.filter.type;
        if (filter_type.length > 0) {
            shops = shops.filter((shop) => filter_type.includes(shop.type));
        };
        return shops.map((shop) => {
            let geolocation = shop.geolocation || {latitude: "",longitude: ""};
            let pos_city_num = [Number(geolocation.latitude), Number(geolocation.longitude)];
            let icon = new L.Icon.Default;
            icon.options.className = 'leaflet-marker-icon course';
            return (
                <Marker position={pos_city_num} alt={shop.id} icon={icon} eventHandlers={{
                    click: (e) => {
                        this.marker(e, shop);
                    },
                    mouseover: (event) => event.target.openPopup(),
                }}>
                    <Popup>
                        {shop.name}
                    </Popup>
                </Marker>
            );
        });
    }
    renderShops() {
        let shops = this.state.shops;
        let filter_type = this.state.filter.type;
        function shuffle(arr) {
            for (let i = arr.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [arr[i], arr[j]] = [arr[j], arr[i]];
            }
        };
        if (filter_type.length > 0) {
            shops = shops.filter((shop) => filter_type.includes(shop.type));
        };
        let type_icons = { Fashion: { name: "Fashion", icon: "clothes-rack.png" }, Hotel: { name: "Hotel", icon: "hotel.png" }, Jewelry: { name: "Jewelry", icon: "jewelry.png" },Golf: { name: "Golf", icon: "golf-shop.png" },Restaurant: { name: "Restaurant", icon: "food.png" },Wellness: { name: "Wellness", icon: "massage.png" },Art: { name: "Art", icon: "many_art_galleries.png" },Wine: { name: "Wine", icon: "wine.png" } };
        let index_clinic = shops.findIndex(c => c.type === "BeautyClinic");
        function array_move(arr, old_index, new_index) {
            if (new_index >= arr.length) {
                var k = new_index - arr.length + 1;
                while (k--) {
                    arr.push(undefined);
                }
            }
            arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
            return arr;
        };
        if(index_clinic > -1){
            shops[index_clinic].golftus_fav = true;
            shops =  array_move(shops, index_clinic, 0);
        };
        return shops.map((shop) => {
            if(shop.type === "BeautyClinic"){
                return (
                    <BeautyClinicShop reload={this.props.reload} user_data={this.props.user_data || {}} key={this.props.logged} logged={this.props.logged} shop={shop} shopHover={this.shopHover} selectShop={this.selectShop} showFormButton={this.showFormButton} />
                );
            }else{
                return (
                    <DefaultShop reload={this.props.reload} user_data={this.props.user_data || {}} key={this.props.logged} logged={this.props.logged} shop={shop} shopHover={this.shopHover} selectShop={this.selectShop} showFormButton={this.showFormButton} />
                );
            };
        });
    }
    shopHover(shop) {
        $(".leaflet-marker-icon").each(function () {
            $(this).removeClass("hover");
        });
        $("img.leaflet-marker-icon[alt='" + shop.id + "']").addClass("hover");
    }
    render() {
        let pos_city = this.state.citiesgeo.split(",");
        let pos_city_num = [Number(pos_city[0]), Number(pos_city[1])];
        return (
            <div className="city_shops city_golf_courses">
                <div className="shops_filter">
                    <div className="filter_buttons">
                        <h3>Filter </h3>
                        <div className="filter_settings">
                            <div className="filter_box">
                                <div className={"f_button " + (this.state.filter.misc.includes("Special") ? "selected" : "")} onClick={() => { this.setFilterArray("misc", "Special") }}><img className="image_icon" src="/icons/membership.png" />with a Golftus Members Special</div>
                            </div>
                        </div>
                        <div className="filter_settings">
                            <div className="filter_box">
                                <div className="filter_tit">For</div>
                                <div className={"f_button " + (this.state.filter.type.includes("Hotel") ? "selected" : "")} onClick={() => { this.setFilterArray("type", "Hotel") }} ><img className="image_icon" src="/icons/hotel.png" />Golf Resort & Hotel</div>
                                <div className={"f_button " + (this.state.filter.type.includes("Golf") ? "selected" : "")} onClick={() => { this.setFilterArray("type", "Golf") }}><img className="image_icon" src="/icons/golf-shop.png" />Golf Store</div>
                                <div className={"betaND f_button " + (this.state.filter.type.includes("Experience") ? "selected" : "")} onClick={() => { this.setFilterArray("type", "Experience") }}><img className="image_icon" src="/icons/adventurer.png" />Experience</div>
                                <div className={"f_button " + (this.state.filter.type.includes("BeautyClinic") ? "selected" : "")} onClick={() => { this.setFilterArray("type", "BeautyClinic") }}><img className="image_icon" src="/icons/botox.png" />Aesthetics & Surgery</div>
                                <div className={"f_button " + (this.state.filter.type.includes("Dental") ? "selected" : "")} onClick={() => { this.setFilterArray("type", "Dental") }}><img className="image_icon" src="/icons/clinic.png" />Dental</div>
                                <div className={"betaND f_button " + (this.state.filter.type.includes("Coaching") ? "selected" : "")} onClick={() => { this.setFilterArray("type", "Coaching") }}><img className="image_icon" src="/icons/brainstorming.png" />Coaching & Consulting</div>
                                <div className={"betaND f_button " + (this.state.filter.type.includes("Workshop") ? "selected" : "")} onClick={() => { this.setFilterArray("type", "Workshop") }}><img className="image_icon" src="/icons/coaching.png" />Workshop</div>
                                <div className={"betaND f_button " + (this.state.filter.type.includes("Wellness") ? "selected" : "")} onClick={() => { this.setFilterArray("type", "Wellness") }}><img className="image_icon" src="/icons/massage.png" />Wellness & Massage</div>
                                <div className={"f_button " + (this.state.filter.type.includes("EstateAgent") ? "selected" : "")} onClick={() => { this.setFilterArray("type", "EstateAgent") }}><img className="image_icon" src="/icons/bald.png" />Real Estate Agent</div>
                                <div className={"f_button " + (this.state.filter.type.includes("Art") ? "selected" : "")} onClick={() => { this.setFilterArray("type", "Art") }}><img className="image_icon" src="/icons/many_art_galleries.png" />Art Gallery</div>
                                <div className={"betaND f_button " + (this.state.filter.type.includes("Bar") ? "selected" : "")} onClick={() => { this.setFilterArray("type", "Bar") }}><img className="image_icon" src="/icons/cocktail.png" />Bar/Pub & Cafe</div>
                                <div className={"betaND f_button " + (this.state.filter.type.includes("Restaurant") ? "selected" : "")} onClick={() => { this.setFilterArray("type", "Restaurant") }}><img className="image_icon" src="/icons/food.png" />Restaurant</div>
                                <div className={"f_button " + (this.state.filter.type.includes("Antiques") ? "selected" : "")} onClick={() => { this.setFilterArray("type", "Antiques") }}><img className="image_icon" src="/icons/gramophone.png" />Antiques</div>
                                <div className={"f_button " + (this.state.filter.type.includes("Jewelry") ? "selected" : "")} onClick={() => { this.setFilterArray("type", "Jewelry") }}><img className="image_icon" src="/icons/jewelry.png" />Jewelry</div>
                                <div className={"f_button " + (this.state.filter.type.includes("Wine") ? "selected" : "")} onClick={() => { this.setFilterArray("type", "Wine") }}><img className="image_icon" src="/icons/wine.png" />Wine & Delicacies</div>
                                <div className={"betaND f_button " + (this.state.filter.type.includes("Entertainment") ? "selected" : "")} onClick={() => { this.setFilterArray("type", "Entertainment") }}><img className="image_icon" src="/icons/theater.png" />Entertainment</div>
                                <div className={"f_button " + (this.state.filter.type.includes("Fashion") ? "selected" : "")} onClick={() => { this.setFilterArray("type", "Fashion") }}><img className="image_icon" src="/icons/hand-bag.png" />Luxury Fashion</div>
                            </div>
                        </div>
                        <div className="filter_settings">
                            <div className="filter_box">
                                <div className="filter_tit">Misc</div>
                                <div className={"f_button " + (this.state.filter.misc.includes("Google") ? "selected" : "")} onClick={() => { this.setFilterArray("misc", "Google") }}><img className="image_icon" src="/icons/review.png" />High Rating on Google</div>
                                <div className={"f_button " + (this.state.filter.misc.includes("Unique") ? "selected" : "")} onClick={() => { this.setFilterArray("misc", "Unique") }}><img className="image_icon" src="/icons/stern.png" />Unique</div>
                                <div className={"f_button " + (this.state.filter.misc.includes("Historic") ? "selected" : "")} onClick={() => { this.setFilterArray("misc", "Historic") }}><img className="image_icon" src="/icons/many_museums.png" />{"> 50 years old"}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="map mobile_map">
                {this.state.citiesgeo.length > 0 ?
                    <MapContainer center={pos_city_num} zoom={12.5} scrollWheelZoom={false}>
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Marker position={pos_city_num} alt="center" eventHandlers={{
                            click: (e) => {
                                this.marker(e);
                            },
                        }}>
                            <Popup>
                                {this.props.cityName}
                            </Popup>
                        </Marker>
                        {this.renderShopsMap()}
                    </MapContainer>: ""}
                </div>
                <div className={"shops_list " + (!this.state.data_loaded ? "data_loading" : "")}>
                    <div className="claim_list">
                        <div>!You want your shop or business listed on Golftus?</div>
                        <a className="claim_b" target="_blank" href="/advertise"><img class="image_icon" src="https://cdn-icons-png.flaticon.com/512/3597/3597088.png" />Click here to claim your Golftus Listing</a>
                    </div>
                    <div className="scroll_box">
                        <div className="loading_svg">
                            <LoadingSVG />
                        </div>
                        {this.renderShops()}
                    </div>
                </div>
                <div className="map">
                {this.state.citiesgeo.length > 0 ?
                    <MapContainer center={pos_city_num} zoom={12.5} scrollWheelZoom={false}>
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Marker position={pos_city_num} alt="center" eventHandlers={{
                            click: (e) => {
                                this.marker(e);
                            },
                        }}>
                            <Popup>
                                {this.props.cityName}
                            </Popup>
                        </Marker>
                        {this.renderShopsMap()}
                    </MapContainer>: ""}
                </div>
                <ModalBoot show={this.state.showVideos} hide={this.showFormButton} content={"showVideos"} className="courseVideosModal">
                    <div key={this.state.selected_shop}>
                        <h2>{this.state.selected_shop.name}</h2>
                        <div className="course_videos_list">
                            {(this.state.selected_shop.youtube || []).map(y => {
                                return(
                                    <div className="youtube_single" >
                                        <div className="box">
                                            <div className="youtube_video" onClick={() => this.playVideo(y)}>
                                                <img className="thumb" src={"https://i.ytimg.com/vi/" + y.id + "/hqdefault.jpg"} />
                                                <img className="play_button" src="/play2.png" />
                                                <div className="title">{y.title}</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <ModalBoot show={this.state.playVideo} hide={this.showFormButton} content={"playVideo"} className="modal_youtubeVideo">
                        <iframe width="800" height="600"
                            src={"https://www.youtube.com/embed/" + this.state.selected_video.id + "?autoplay=1"} allow='autoplay'>
                        </iframe>
                    </ModalBoot >
                </ModalBoot >
                <ModalBoot show={this.state.showServices} hide={this.showFormButton} content={"showServices"} className="courseVideosModal">
                    <div key={this.state.selected_shop}>
                        <h2>Services - {this.state.selected_shop.name}</h2>
                        <div className="course_videos_list">
                            {(this.state.selected_shop.services || []).map(main_service => {
                                return(
                                    <div className="main_service">
                                        <div className="service_name">{main_service.name}</div>
                                        {main_service.services.map(service => {
                                            return(
                                                <div className="service" >
                                                    <div className="box">
                                                        <div className="name">{service.name}</div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </ModalBoot >
                <ModalBoot show={this.state.showPhotos} hide={this.showFormButton} content={"showPhotos"} className="courseVideosModal instagram_modal">
                    <div key={this.state.selected_shop}>
                        <h2>Photos - {this.state.selected_shop.name}</h2>
                        <InstagramEmbed urls={this.state.selected_shop.instagram} />
                    </div>
                </ModalBoot >
            </div>
        )
    }
}    