import React, { Component } from 'react';
import axios from 'axios';
import { Container, Row, Col, Button, DropdownButton, Dropdown, Form } from 'react-bootstrap';
import { Link } from "react-router-dom";
import localhostName from "../config.json";
import LoadingSVG from './LoadingSVG';
import { MapContainer, TileLayer, useMap, Marker, Popup, Circle } from 'react-leaflet';
import BucketPlayedButtonCourse from './BucketPlayedButtonCourse';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import RangeSlider from './UI/RangeSlider';
import ModalBoot from '../components/Modal';
import $ from 'jquery';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
function deleteStringFromArray(arr, str) {
    const index = arr.indexOf(str);
    if (index > -1) {
        arr.splice(index, 1);
    }
}
export default class GolfEntertainment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            courses: [],
            search_course: "",
            citiesgeo: "",
            filter: {
                distance: 100,
                ranking: ""
            },
            year_min: 1850,
            year_max: 2023,
            data_loaded: false,
            list_map_select: false,
            courses_number: 0,
            youtube: [],
            selected_video: {},
            selected_course: { youtube: [] },
            city_architects: [],
            architect_filter: []
        };
        this.showFormButton = this.showFormButton.bind(this);
    }
    componentDidMount() {
        axios.get(localhostName.host + "/Data/GolfEntertainment/Cities/" + this.props.cityName)
            .then(res => {
                let courses = (Array.isArray(res.data) ? res.data : []);
                this.setState({ data_loaded: true, courses: courses, courses_number: courses.length });
            })
            .catch(function (error) {
                console.log(error);
            });
        axios.get(localhostName.host + "/Data/Citygeo/" + this.props.cityName)
            .then(res => {
                let citiesgeo = res.data;
                this.setState({ citiesgeo: citiesgeo });
            })
            .catch(function (error) {
                console.log(error);
            });
        axios.get("/AllCityArchitects.json")
            .then(res => {
                this.setState({ city_architects: (res.data[this.props.cityName] || []) });
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    showFormButton(targetShow, change) {
        this.setState({ [targetShow]: change });
    }
    architect_filter(name) {
        let architect_filter = this.state.architect_filter;
        if (architect_filter.includes(name)) {
            deleteStringFromArray(architect_filter, name)
        } else {
            architect_filter.push(name);
        };
        this.setState({ architect_filter });
    }
    search_course(e) {
        this.setState({ search_course: e.target.value })
    }
    renderCourses() {
        let courses = this.state.courses;
        let filter = this.state.filter;
        let search_course = this.state.search_course;
        if (search_course.length > 0) {
            courses = courses.filter((c) =>
                c.name.toLowerCase().includes(search_course.toLowerCase()) ||
                c.address.toLowerCase().includes(search_course.toLowerCase()) ||
                c.short_desc.toLowerCase().includes(search_course.toLowerCase())

            );
        };
        courses = courses.filter((c) => Number(c.citydistance) < (filter.distance + 5));
        if (filter["high_ratings"]) {
            courses = courses.filter((c) => Number(c.rating) > 4.5);
        };
        let courses_played = (this.props.user_data.played_golf_entertainment || []);
        let courses_bucket = (this.props.user_data.bucket_golf_entertainment || []);
        setTimeout(function () {
            $(".course_number").html($(".scroll_box .course").length);
        }, 1000)
        return courses.map((course) => {
            let pp_data = (course.pp_data || { ratings: {} });
            let ranking_name = { country: course.country, region: "Region", world: "World", continent: "Continent" };
            let played = courses_played.includes(course.id);
            let bucket = courses_bucket.includes(course.id);
            let rating = (course.review_point || ((course.g || {}).review_point) || course.rating);
            if (rating) { rating = rating.replace(",", ".") };
            let rating_star = 0;
            let rating_star_half = false;
            if (rating > 3.2 && rating <= 3.7) { rating_star = 3; rating_star_half = true; };
            if (rating > 3.7 && rating <= 4.2) { rating_star = 4; };
            if (rating > 4.2 && rating <= 4.7) { rating_star = 4; rating_star_half = true; };
            if (rating > 4.7) { rating_star = 5; };
            function return_stars() {
                let stars = [];
                for (let i = 0; i < rating_star; i++) { stars.push(<img src='/icons/rating_star.png' />) };
                return stars;
            };
            return (
                <div className="course" id={course.name} onMouseEnter={() => { this.courseHover(course) }} onMouseLeave={() => { this.courseHover({}) }}>
                    <div className="box">
                        <div className="name">{course.name}</div>
                        {(course.review_point || ((course.g || {}).review_point) || course.rating) ? <div className="rating">{return_stars()}{rating_star_half ? <img src='/icons/rating_star_half.png' /> : ''} <span className="tags">{course.tags.map(t => (<span>{t}</span>))}</span></div> : ""}
                        <div className="distance_city_centre"><img src="/distance.png" />{course.citydistance.toFixed(2) + " km [" + (Number(course.citydistance) / 1.61).toFixed(2) + " miles] to " + this.props.cityName + " City Centre"}</div>
                        <div className="address">{course.address}</div>
                        <div className="course_data">
                            {pp_data.holes ? <div className="data_c">Holes <div>{pp_data.holes}</div></div> : ""}
                            {pp_data.par ? <div className="data_c">Par <div>{pp_data.par}</div></div> : ""}
                            {pp_data.course_length_yards ? <div className="data_c">Length <div>{pp_data.course_length_yards}</div></div> : ""}
                            {pp_data.course_slope ? <div className="data_c">Slope <div>{pp_data.course_slope}</div></div> : ""}
                            {pp_data.course_rating ? <div className="data_c">Rating <div>{pp_data.course_rating}</div></div> : ""}
                        </div>
                        {(pp_data || {}).architect ? <div className="course_data">
                            <div className="architect_names"><span className="h">Architect:</span> {pp_data.architect.map((d, index) => <span>{d.name} {index < pp_data.architect.length - 1 ? ", " : ""}</span>)}</div>
                        </div> : ""}
                        {course.youtube ?
                            <div className="course_videos" onClick={() => { this.selectCourse(course); this.showFormButton("showVideos", true); }}>
                                <img class="play_button" src="/play2.png" />Videos({course.youtube.length})
                            </div>
                            : ""}
                        {course.tus_desc ?
                            <div className="tus_desc">
                                {course.tus_desc}
                            </div>
                            : ""}
                        <div className="website_social_media">
                            {(course.website || "").length > 0 ? <div className="website"><a target="_blank" href={course.website}><img src='/icons/worldwideweb.png'></img></a></div> : ""}
                            {(course.facebook || "").length > 0 ? <div className="facebook"><a target="_blank" href={course.facebook}><img src='https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/Facebook_icon_2013.svg/640px-Facebook_icon_2013.svg.png'></img></a></div> : ""}
                            <BucketPlayedButtonCourse reload={this.props.reload} course_id={course.id} logged={this.props.logged} played={played} bucket={bucket} />
                        </div>
                    </div>
                </div>
            );
        });
    }
    renderCoursesMap() {
        let courses = this.state.courses;
        let filter = this.state.filter;
        let search_course = this.state.search_course;
        if (search_course.length > 0) {
            courses = courses.filter((c) =>
                c.name.toLowerCase().includes(search_course.toLowerCase()) ||
                c.address.toLowerCase().includes(search_course.toLowerCase()) ||
                c.short_desc.toLowerCase().includes(search_course.toLowerCase())

            );
        };
        courses = courses.filter((c) => Number(c.citydistance) < (filter.distance + 5));
        if (filter["high_ratings"]) {
            courses = courses.filter((c) => Number(c.rating) > 4.5);
        };
        let courses_played = (this.props.user_data.played_golf_entertainment || []);
        return courses.map((course) => {
            let pos_city = course.geolocation.geo.split(",");
            let pos_city_num = [Number(pos_city[0]), Number(pos_city[1])];
            let icon = new L.Icon.Default;
            icon.options.className = 'leaflet-marker-icon course';
            return (
                <Marker position={pos_city_num} alt={course.url} icon={icon} eventHandlers={{
                    click: (e) => {
                        this.marker(e, course);
                    },
                }}>
                    <Popup>
                        {course.name}
                    </Popup>
                </Marker>
            );
        });
    }
    ranking_Numbers(rank) {
        let courses = this.state.courses;
        let filter = this.state.filter;
        courses = courses.filter((c) => Number(c.citydistance) < (filter.distance + 5));
        courses = courses.filter((c) => (c.rankings || []).findIndex((r) => r.name === rank) > -1);
        return (courses.length);
    }
    marker(e) {
    }
    courseHover(course) {
        $(".leaflet-marker-icon").each(function () {
            $(this).removeClass("hover");
        });
        $("img.leaflet-marker-icon[alt='" + course.url + "']").addClass("hover");
    }
    setFilter(filt, value) {
        let filter = this.state.filter;
        if (filter[filt] === value && filt !== "distance") {
            filter[filt] = ""
        } else {
            filter[filt] = value;
        };
        this.setState({ filter: filter });
    }
    changeYear(name, value) {
        if (name === "year_min") {
            this.setState({ year_min: value });
            this.setFilter("year_built", [value, this.state.year_max]);
        };
        if (name === "year_max") {
            this.setState({ year_max: value });
            this.setFilter("year_built", [this.state.year_min, value]);
        };
    }
    changeMapSelect(value) {
        this.setState({ list_map_select: value });
    }
    selectCourse(course) {
        this.setState({ selected_course: course });
    }
    playVideo(video) {
        this.setState({ selected_video: video });
        this.showFormButton("playVideo", true);
    }
    render() {
        let courses = this.state.courses;
        let pos_city = this.state.citiesgeo.split(",");
        let pos_city_num = [Number(pos_city[0]), Number(pos_city[1])];
        return (
            <div className="city_golf_courses golf_entertainment">
                <div className="golf_filter">
                    <div className="filter_buttons">
                        <h3>Filter </h3>
                        <div className="filter_settings">
                            <div className="filter_box course_distance">
                                <div className="filter_tit">Distance to City Centre</div>
                                <div className={"f_button " + (this.state.filter.distance === 25 ? "selected" : "")} onClick={() => { this.setFilter("distance", 25) }}>{"< 25km"}</div>
                                <div className={"f_button " + (this.state.filter.distance === 50 ? "selected" : "")} onClick={() => { this.setFilter("distance", 50) }}>{"< 50km"}</div>
                                <div className={"f_button " + (this.state.filter.distance === 100 ? "selected" : "")} onClick={() => { this.setFilter("distance", 100) }}>{"< 100km"}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"courses_list " + (!this.state.data_loaded ? "data_loading" : "")}>
                    <div className="filter_box">
                        <div className="filter_search">
                            <input placeholder="Search (by Name, Address)" />
                            <div className="input_plus betaND">+</div>
                        </div>
                    </div>
                    {courses.length < 1 ? <div className='no_courses_text'>!There is no club listed for this city</div> : ""}
                    <div className="list_map_select">
                        <div className={"select_t " + (!this.state.list_map_select ? "selected" : "")} onClick={() => this.changeMapSelect(false)}><img src="/icons/list_a.png" /></div>
                        <div className={"select_t " + (this.state.list_map_select ? "selected" : "")} onClick={() => this.changeMapSelect(true)}><img src="/icons/map.png" /></div>
                    </div>
                    {this.state.list_map_select ? <div className="map mobile_map">
                        {this.state.citiesgeo.length > 0 ?
                            <MapContainer center={pos_city_num} zoom={8} scrollWheelZoom={false}>
                                <TileLayer
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                <Marker position={pos_city_num} alt="center" eventHandlers={{
                                    click: (e) => {
                                        this.marker(e);
                                    },
                                }}>
                                    <Popup>
                                        {this.props.cityName}
                                    </Popup>
                                </Marker>
                                {this.renderCoursesMap()}
                            </MapContainer> : ""}
                    </div> : ""}
                    <div className="scroll_box" >
                        <div className="loading_svg">
                            <LoadingSVG />
                        </div>
                        {this.renderCourses()}
                    </div>
                </div>
                {!isMobile ? <div className="map">
                    {this.state.citiesgeo.length > 0 ?
                        <MapContainer center={pos_city_num} zoom={8} scrollWheelZoom={false} >
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <Marker position={pos_city_num} alt="center" eventHandlers={{
                                click: (e) => {
                                    this.marker(e);
                                },
                            }}>
                                <Popup>
                                    {this.props.cityName}
                                </Popup>
                            </Marker>
                            {this.renderCoursesMap()}
                        </MapContainer> : ""}
                </div>:""}
                <ModalBoot show={this.state.showVideos} hide={this.showFormButton} content={"showVideos"} className="courseVideosModal">
                    <div key={this.state.selected_course}>
                        <h2>{this.state.selected_course.name}</h2>
                        <div className="course_videos_list">
                            {this.state.selected_course.youtube.map(y => {
                                return (
                                    <div className="youtube_single" >
                                        <div className="box">
                                            <div className="youtube_video" onClick={() => this.playVideo(y)}>
                                                <img className="thumb" src={"https://i.ytimg.com/vi/" + y.id + "/hqdefault.jpg"} />
                                                <img className="play_button" src="/play2.png" />
                                            </div>
                                            <div className="title">{y.title}</div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <ModalBoot show={this.state.playVideo} hide={this.showFormButton} content={"playVideo"} className="modal_youtubeVideo">
                        <iframe className="iframe_youtube" width={isMobile ? "100%" : "800"} height={isMobile ? "auto" : "800"}
                            src={"https://www.youtube.com/embed/" + this.state.selected_video.id + "?autoplay=1"} allow="autoplay; encrypted-media allowfullscreen">
                        </iframe>
                    </ModalBoot >
                </ModalBoot >
            </div>
        )
    }
}    