import React, { Component } from 'react';
import axios from 'axios';
import localhostName from "../../config.json";
import $ from 'jquery';

export default class Collection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.city_name,
            buddies: [],
            step: "overview",
            user_data: {}
        };
    }
    componentDidMount() {
    }
    renderMails() {
        let data = [
            {
                photo: "/deniro.jpg",
                name: "Dinçer Bekmezci",
                city: "Munich",
                country: "Germany",
                message: "hey, happy wednesday. lets play on friday. for 50 dollars",
                date: ""
            },
            {
                photo: "/deniro.jpg",
                name: "Dinçer Bekmezci",
                city: "Munich",
                country: "Germany",
                message: "play or not play, fucker asshole. lets play on friday. for 50 dollars",
                date: ""
            }
        ];
        return data.map((message) => {
            return (
                <div className="message_box">
                    <div>
                        <img src={message.photo} />
                    </div>
                    <div className="message_overview">
                        <div className="name">{message.name}</div>
                        <div className="text">{message.message.slice(0, 100)}</div>
                    </div>
                    <div className="message_right">

                    </div>
                </div>
            )
        })
    }
    changeStep(value) {
        this.setState({ step: value })
    }
    render() {
        return (
            <div className="collection_page">
                <div className="collection_tabs">
                    <div className={this.state.step === "overview" ? "selected" : ""} onClick={() => { this.changeStep("overview") }}>Overview</div>
                    <div className={this.state.step === "courses" ? "selected" : ""} onClick={() => { this.changeStep("courses") }}>Courses</div>
                    <div className={this.state.step === "badges" ? "selected" : ""} onClick={() => { this.changeStep("badges") }}>Badges</div>
                </div>
                {this.state.step === "overview" ?
                    <div className="overview_tab">
                        <div className="played_and_bucket">
                            <div className="sub_title">Played</div>
                            <div className="box">
                                <div className="title">Countries</div>
                                <div className="countries_list">
                                    <div className="country">Germany</div>
                                    <div className="country">France</div>
                                    <div className="country">Canada</div>
                                </div>
                            </div>
                            <div className="box">
                                <div className="title">Cities</div>
                                <div className="cities_list">
                                    <div className="city">London</div>
                                    <div className="city">Berlin</div>
                                </div>
                            </div>
                            <div className="sub_title">Bucket List</div>
                            <div className="box">
                                <div className="title">Cities</div>
                                <div className="cities_list">
                                    <div className="city">Istanbul</div>
                                    <div className="city">Tokyo</div>
                                </div>
                            </div>
                        </div>
                        <div class="collection_badges">
                            <div class="title">Badges</div>
                            <div class="collection_badge">
                                <label id="first-title">Europe</label>
                                <label id="second-title">20 Countries</label>
                                <img src="/icons/europe.png" />
                            </div>
                            <div class="collection_badge">
                                <label id="first-title">US Top 10</label>
                                <label id="second-title">10 of 20 Courses</label>
                                <img src="/icons/europe.png" />
                            </div>
                        </div>
                    </div> : ""}
                {this.state.step === "courses" ?
                    <div className="overview_tab">
                        <div class="collection_badges">
                            <div class="title">Your Badges</div>
                            <div class="collection_badge">
                                <label id="first-title">Europe</label>
                                <label id="second-title">20 Countries</label>
                                <img src="/icons/europe.png" />
                            </div>
                            <div class="collection_badge">
                                <label id="first-title">US Top 10</label>
                                <label id="second-title">10 of 20 Courses</label>
                                <img src="/icons/europe.png" />
                            </div>
                        </div>
                        <div class="collection_badges">
                            <div class="title">Badges You could get</div>
                            <div class="collection_badge">
                                <label id="first-title">Asia</label>
                                <label id="second-title">10 Countries</label>
                                <img src="/icons/europe.png" />
                            </div>
                            <div class="collection_badge">
                                <label id="first-title">World Continent</label>
                                <label id="second-title">6 Continents</label>
                                <img src="/icons/europe.png" />
                            </div>
                            <div class="collection_badge">
                                <label id="first-title">Roman City</label>
                                <label id="second-title">8 Roman Cities</label>
                                <img src="/icons/europe.png" />
                            </div>
                        </div>
                    </div> : ""}
                {this.state.step === "badges" ? <div className="overview_tab">
                    <div class="collection_badges">
                        <div class="title">Badges</div>
                        <div class="collection_badge">
                            <label id="first-title">Europe</label>
                            <label id="second-title">20 Countries</label>
                            <img src="/icons/europe.png" />
                        </div>
                        <div class="collection_badge">
                            <label id="first-title">US Top 10</label>
                            <label id="second-title">10 of 20 Courses</label>
                            <img src="/icons/europe.png" />
                        </div>
                    </div>
                    <div class="collection_badges">
                        <div class="title">Badges You could get</div>
                        <div class="collection_badge">
                            <label id="first-title">Asia</label>
                            <label id="second-title">10 Countries</label>
                            <img src="/icons/europe.png" />
                        </div>
                        <div class="collection_badge">
                            <label id="first-title">World Continent</label>
                            <label id="second-title">6 Continents</label>
                            <img src="/icons/europe.png" />
                        </div>
                        <div class="collection_badge">
                            <label id="first-title">Roman City</label>
                            <label id="second-title">8 Roman Cities</label>
                            <img src="/icons/europe.png" />
                        </div>
                    </div>
                </div> : ""}

            </div>
        )
    }
}    