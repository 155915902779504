import React, { Component } from 'react';
import axios from 'axios';
import LoadingSVG from './LoadingSVG';
import localhostName from "../config.json";
import { Tooltip } from 'react-tooltip';
import $ from 'jquery';

export default class CityCulture extends Component {
    constructor(props) {
        super(props);
        this.state = {
            language: "de",
            actualArtwork: {},
            artworks: [],
            devoPics: [],
            settings: { _id: "df" },
            city_culture: [],
            data_loaded: false,
            userInfo: this.props.userInfo,
            compare_active: false,
            compare_city: {},
            compare_city_culture: [],
            compare_input: "",
            cities_list: (this.props.cities_list || []),
            score_desc: [],
            score_desc_main: [],
            city_desc_short: ""
        };
    }
    componentDidMount() {
        axios.get(localhostName.host + "/Data/Culture/Cities/" + this.props.cityName)
            .then(res => {
                let city_culture = (Array.isArray(res.data) ? res.data : []);
                this.setState({ data_loaded: true, city_culture: city_culture });
            })
            .catch(function (error) {
                console.log(error);
            });
        let score_desc = [
            {
                "name": "social_reserved",
                "title": "Social vs. Reserved"
            },
            {
                "name": "riskseeking_riskavoiding",
                "title": "Risk Seeking vs. Risk Avoiding",

            },
            {
                "name": "publictransport_car",
                "title": "Public Transport vs. Car",
            },
            {
                "name": "soft_aggressive",
                "title": "Soft vs. Aggressive",

            },
            {
                "name": "relaxed_fastpaced",
                "title": "Relaxed Lifestyle vs. Fast-Paced Lifestyle",
            },
            {
                "name": "historic_modern_architecture",
                "title": "Historic Architecture vs. Modern Architecture",
            },
            {
                "name": "science_spiritual",
                "title": "Science vs. Spiritual",
            },
            {
                "name": "punctual_unpunctual",
                "title": "Punctual vs. Unpunctual",
            },
            {
                "name": "chaos_order",
                "title": "Chaos vs. Order",
            },
            {
                "name": "loud_quiet",
                "title": "Loud vs. Quiet",
            },
            {
                "name": "eatingout_eatingathome",
                "title": "Eating Out vs. Eating at Home",
            },
            {
                "name": "nightclub_bar",
                "title": "Nightclub vs. Bar",
            },
            {
                "name": "strongrelationship_casualsex",
                "title": "Strong Relationship vs. Casual Sex",
            },
            {
                "name": "strongfamily_normalfamily",
                "title": "Strong Family Bonds vs. Normal Family Bonds",
            },
            {
                "name": "beer_wine",
                "title": "Beer vs. Wine",
            },
            {
                "name": "tea_coffee",
                "title": "Tea vs. Coffee",
            },
            {
                "name": "smilelot_littlesmile",
                "title": "Smile a lot vs. Little Smile",
            },
            {
                "name": "restaurant_streetfood",
                "title": "Restaurant vs. Street Food",
            },
            {
                "name": "committed_casual",
                "title": "Committed Relationship vs. Casual Relationship",
            },
            {
                "name": "greencity_concrete",
                "title": "Green City vs. Concrete",
            },
            {
                "name": "jaywalking_nojaywalking",
                "title": "Jaywalking vs. No Jaywalking",
            },
            {
                "name": "burger_kebab",
                "title": "Burger vs. Kebab (Doner)",
            },
            {
                "name": "highfashion_casualdressing",
                "title": "High Fashion vs. Casual Dressing",
            },
            {
                "name": "dogfriendly_nopets",
                "title": "Dog-friendly vs. No Pets",
            },
            {
                "name": "sleepless_earlybird",
                "title": "Sleepless Nights vs. Early Bird Mornings",
            },
            {
                "name": "traditionalists_progressives",
                "title": "Traditionalists vs. Progressives",
            },
            {
                "name": "multiculturalism_homogeneity",
                "title": "Multiculturalism vs. Homogeneity",
            },
            {
                "name": "collective_individual",
                "title": "Collective Identity vs. Individual Identity",
            },
            {
                "name": "material_intangible",
                "title": "Material Culture vs. Intangible Culture",
            },
            {
                "name": "civilcourage_reserved",
                "title": "Civil Courage vs. Reserved",
            },
            {
                "name": "bigwedding_smallwedding",
                "title": "Big Wedding vs. Small Wedding",
            },
            {
                "name": "livingwork_workinglive",
                "title": "Living to Work vs. Working to Live",
            },
            {
                "name": "politically_apathetic",
                "title": "Politically Active vs. Apathetic Citizens",
            },
            
        ];
        this.setState({ score_desc: score_desc});
        {/*let random_city = this.state.cities_list[Math.floor(Math.random() * this.state.cities_list.length)];
        this.select_compare_city(random_city);*/}
    }
    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    }
    renderCultures(type) {
        let Cultures = this.state.score_desc;
        let city_culture = this.state.city_culture;
        let count = 0;
        return Cultures.map((culture) => {
            let index = city_culture.findIndex(c=> c.name === culture.name);
            let names = culture.title.split(" vs. ");
            let id = Math.random().toString(16).slice(2);
            count += 1;
            let blur = false;
            if((Cultures.length - count) < 6 && !this.props.logged){
                blur = true;
            }
            if(city_culture[index]){
                let desc = "<div class='info_tooltip'><div class='name'>" + culture.title + "</div>" + (city_culture[index] || {}).desc + "</div>";
                return (
                    <div className={"score_single " + (blur ? "blur_content" : "")} data-tooltip-id={id} data-tooltip-html={(city_culture[index] || {}).desc ? desc : ""}>
                        <img src="/icons/info_i.png" className="info_i" />
                        {(culture.icon || "").length > 0 ?
                            <div className="score_name">
                                <img className="icon" src={culture.icon} /><div>{culture.title}</div>
                            </div>
                            : <div className="score_name"><span>{culture.title}</span></div>}
                        <div className="score_result">
                            <div className="outer">
                                <div className="inner" style={{ width: ("100" + "%"), background: "#4CAF50" }}></div>
                                <div className="score_output">{names[city_culture[index].value - 1]}</div>
                            </div>
                        </div>
                        <div className='desc_short'><div >{(city_culture[index] || {}).desc}</div></div>
                        <Tooltip id={id} />
                        <div className="become_member">
                            <div className="text">You have to be a member to see this.</div>
                        </div>
                    </div>
                );
            };
        });
    }
    render() {
        let cities_list = this.state.cities_list;
        cities_list = cities_list.filter(c => c.name.includes(this.state.compare_input));
        return (
            <div className={"city_scores city_cultures " + (!this.state.data_loaded ? "data_loading" : "")} >
                {/*<div className='city_desc_short'>
                    <div>{this.state.city_desc_short}</div>
                </div>*/}
                <div className="tab_sub_topbar">
                    <div className="compare_city">
                        <span>Compare with</span>
                        <div className="compare_city_input">
                            <input placeholder="Select City" id="compare_input" onChange={(e) => this.onChange(e)} />
                            <div className="list_compare_cities">
                                {cities_list.map((c) => {
                                    return (
                                        <div className="compare_city_select">
                                            <div className="name">{c.name}, {c.country}</div>
                                            <div className="select" onClick={() => this.select_compare_city(c)}>compare</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="loading_svg">
                    <LoadingSVG />
                </div>
                <div className="all_scores">
                    <div className="more_scores">
                        <h2>Cultures</h2>
                        {this.renderCultures("more")}
                    </div>
                </div>
            </div>
        )
    }
}    