import React, {Component} from 'react';

export default class MembershipTC extends Component {
    render() {
        return (
            <div className="membership_info">
                <div class="brand_name">Terms and Conditions - Golftus Basic Lifetime Membership</div>
                <div class="head_text">Please read these terms and conditions carefully before purchasing a Golftus Lifetime Membership. By becoming a lifetime member, you agree to be bound by the following terms and conditions:</div>
                <div className="tc">
                    <div className="box">
                        <div className="question">
                            Membership Duration
                        </div>
                        <div className="answer">
                            The Golftus Basic Lifetime Membership is valid for the lifetime of the Golftus website unless otherwise specified.
                        </div>
                    </div>
                    <div className="box">
                        <div className="question">
                            Payment and Pricing
                        </div>
                        <div className="answer">
                        The lifetime membership fee is a one-time payment of $49. All payments must be made in USD. Prices are subject to change without prior notice.
                        </div>
                    </div>
                    <div className="box">
                        <div className="question">
                            Membership Benefits
                        </div>
                        <div className="answer">
                            Basic Lifetime members will have access to all information, including golf course and city information, travel recommendations and exclusive content.
                        </div>
                    </div>
                    <div className="box">
                        <div className="question">
                        Membership Activation
                        </div>
                        <div className="answer">
                        Upon successful payment, the lifetime membership will be activated. Members will receive a confirmation email with instructions on how to access their account.
                        </div>
                    </div>
                    <div className="box">
                        <div className="question">
                        Usage Restrictions
                        </div>
                        <div className="answer">
                        Golftus reserves the right to terminate or suspend a lifetime membership in case of violation of these terms and conditions or any fraudulent activity.
                        </div>
                    </div>
                    <div className="box">
                        <div className="question">
                        User Obligations
                        </div>
                        <div className="answer">
                        You agree to use the Golftus website and its features responsibly and in compliance with applicable laws and regulations.
                        </div>
                    </div>
                    <div className="box">
                        <div className="question">
                        Intellectual Property
                        </div>
                        <div className="answer">
                        All content, trademarks, and intellectual property on the Golftus website are owned by Golftus and protected by applicable laws. Reproduction or distribution of any content without prior permission is prohibited.
                        </div>
                    </div>
                    <div className="box">
                        <div className="question">
                        Modification of Membership
                        </div>
                        <div className="answer">
                        Golftus reserves the right to modify, update, or discontinue any membership features or benefits associated with the Golftus Basic Lifetime Membership at any time without prior notice.
                        </div>
                    </div>
                    <div className="box">
                        <div className="question">
                        Privacy and Data Protection
                        </div>
                        <div className="answer">
                        Golftus respects your privacy and handles personal information in accordance with its Privacy Policy. By becoming a lifetime member, you consent to the collection, use, storage, and protection of your personal information.
                        </div>
                    </div>
                    <div className="box">
                        <div className="question">
                        Disclaimer of Liability
                        </div>
                        <div className="answer">
                        Golftus is not responsible for any inaccuracies, errors, or omissions in the content provided on the website. The use of the Golftus Basic Lifetime Membership is at your own risk.
                        </div>
                    </div>
                    <div className="box">
                        <div className="question">
                        Modification of Terms
                        </div>
                        <div className="answer">
                        Golftus reserves the right to modify these terms and conditions at any time. Any significant changes will be notified to members via email or through a notice on the website.
                        </div>
                    </div>
                </div>
                <div className="foot_text">
                By purchasing a Golftus Basic Lifetime Membership, you acknowledge that you have read, understood, and agreed to abide by these terms and conditions.
                </div>
                <div className="foot_text">
                If you have any questions or concerns regarding the lifetime membership or these terms, please contact us at info@golftus.com.
                </div>
                <div className="foot_text">
                Effective Date: 2023/05/20
                </div>
            </div>            
        )
    }
}    