function arrayRemove(arr, value) {

    return arr.filter(function (ele) {
        return ele != value;
    });

};

function timeLastDeadline(deadline) {
    console.log(deadline);
    let timeDif = +deadline - +Date.now();
    console.log(timeDif);
    var sec = +timeDif / +1000;
    var min = +sec / +60;
    var hours = +min / +60;
    var days = +hours / +24;
    switch (true) {
        case timeDif < -172800001:
            return +Math.round(days) + ' Tage vergangen';
            break;
        case timeDif < 3600001 && timeDif > -172800001:
            return +Math.round(hours) + ' Stunden vergangen';
            break;
        case timeDif < 0 && timeDif > -3600001:
            return +Math.round(min) + ' Minuten vergangen';
            break;
        case timeDif < 60001 && timeDif > 0:
            return '< 1 Minute';
            break;
        case timeDif < 3600001:
            return 'noch ' + Math.round(min) + ' Minuten';
            break;
        case timeDif < 172800001:
            return 'noch ' + Math.round(hours) + ' Stunden';
            break;
        case timeDif > 172800001:
            return 'noch ' + Math.round(days) + ' Tage';
            break;
    }
};

function timeAgo(lastTime) {
    let timeDif = +Date.now() - +lastTime;
    var sec = +timeDif / +1000;
    var min = +sec / +60;
    var hours = +min / +60;
    var days = +hours / +24;
    switch (true) {
        case timeDif < 60001:
            return '< 1 minute';
            break;
        case timeDif < 3600001:
            return 'vor ' + Math.round(min) + ' Minuten';
            break;
        case timeDif < 172800001:
            return 'vor ' + Math.round(hours) + ' Stunden';
            break;
        case timeDif > 172800001:
            return 'vor ' + Math.round(days) + ' Tagen';
            break;
    }
};

function addCommas(n) {
    var str = n.toString();
    if (str.indexOf(',') > -1) {
        return n;
    };
    var parts = str.split('.');
    var intPart = parts[0];
    var decPart = parts.length > 1 ? '.' + parts[1] : '';
    intPart = intPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return intPart + decPart;
}

function setCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
};
function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
};
function delete_cookie(name, path, domain) {
    if (getCookie(name)) {
        document.cookie = name + "=" +
            ((path) ? ";path=" + path : "") +
            ((domain) ? ";domain=" + domain : "") +
            ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
    }
};

function getObjectWithLowestValue(array, propertyName) {
    if (array.length === 0) {
        return null;
    }

    let lowestObject = array[0];
    let lowestValue = lowestObject[propertyName];

    for (let i = 1; i < array.length; i++) {
        const currentValue = array[i][propertyName];
        if (currentValue < lowestValue) {
            lowestValue = currentValue;
            lowestObject = array[i];
        }
    }

    return lowestObject;
}

function distance(lat1, lon1, lat2, lon2, unit) {
    if ((lat1 == lat2) && (lon1 == lon2)) {
        return 0;
    }
    else {
        var radlat1 = Math.PI * lat1 / 180;
        var radlat2 = Math.PI * lat2 / 180;
        var theta = lon1 - lon2;
        var radtheta = Math.PI * theta / 180;
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist);
        dist = dist * 180 / Math.PI;
        dist = dist * 60 * 1.1515;
        if (unit == "K") { dist = dist * 1.609344 }
        if (unit == "N") { dist = dist * 0.8684 }
        return dist;
    }
}

function dynamicSort(property) {
    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
        /* next line works with strings and numbers, 
         * and you may want to customize it to your needs
         */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
};

module.exports = {
    arrayRemove, timeLastDeadline, timeAgo, addCommas, setCookie, getCookie, delete_cookie,distance,getObjectWithLowestValue,dynamicSort
};