import React, { Component } from 'react';
import axios from 'axios';
import localhostName from "../../config.json";
import $ from 'jquery';

export default class ClubMail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.city_name,
            buddies: [],
            user_data: {}
        };
    }
    componentDidMount() {
    }
    renderMails() {
        let data = [
            {
                photo: "/deniro.jpg",
                name: "Dinçer Bekmezci",
                city: "Munich",
                country: "Germany",
                message: "hey, happy wednesday. lets play on friday. for 50 dollars",
                date: ""
            },
            {
                photo: "/deniro.jpg",
                name: "Dinçer Bekmezci",
                city: "Munich",
                country: "Germany",
                message: "play or not play, fucker asshole. lets play on friday. for 50 dollars",
                date: ""
            }
        ];
        return data.map((message)=>{
            return(
                <div className="message_box">
                    <div>
                        <img src={message.photo} />
                    </div>
                    <div className="message_overview">
                        <div className="name">{message.name}</div>
                        <div className="text">{message.message.slice(0,100)}</div>
                    </div>
                    <div className="message_right">

                    </div>
                </div>
            )
        })
    }
    render() {
        return (
            <div className="club_mail_page">
                <div className="container">
                    {this.renderMails()}
                </div>
            </div>
        )
    }
}    