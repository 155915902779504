import React, { Component } from 'react';
import axios from 'axios';
import { Container, Row, Col, Button, DropdownButton, Dropdown, Form } from 'react-bootstrap';
import localhostName from "./config.json";
import BucketPlayedButton from './BucketPlayedButton';
import countriesShortcuts from './Data/countriesShortcuts.json';
import { setCookie, getCookie, delete_cookie } from '../functions/functions.js';
import $ from 'jquery';
const master_path = require("./Data/master_paths.json");
const main_tab = require("./Data/main_tab.json");
const tab_banner = require("./Data/tab_banner.json");
let master_name;
let lang = getCookie("lang");
let firstvisit = (getCookie("firstvisit") || "true");

export default class ItemOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            language: "de",
            selectedCity: {
                name: "Default"
            },
            Scores: [],
            settings: { _id: "df" },
            userInfo: this.props.userInfo,
            selected_artwork: { interpretations: [], artwork_id: "", item: "" },
            check_exist: { Shops: false, },
            overview_tab: (main_tab[window.location.pathname.split("/")[2]] || "Overview"),
            banner: (tab_banner[window.location.pathname.split("/")[2]] || "/artofwar_banner.jpg"),
            user_data: { infos: {} },
            tab_: {},
            item_data: { tabs_ready: [] },
            id: "",
            logged: false,
            master_name: window.location.pathname.split("/")[2],
            item: {},
            learn_status: {},
            learn_tabs: []
        };
        master_name = window.location.pathname.split("/")[2];
        this.showFormButton = this.showFormButton.bind(this);
        this.showFormButtonItem = this.showFormButtonItem.bind(this);
        this.changeTab = this.changeTab.bind(this);
        this.showInterpretations = this.showInterpretations.bind(this);
        this.reload = this.reload.bind(this);
    }
    showUpdateArtwork(target, status, targetObject, artworkObject) {
        this.setState({ ['show' + target]: status });
        if (artworkObject) {
            this.setState({ [targetObject]: artworkObject });
        };
    }
    showInterpretations(status, selected_artwork) {
        this.setState({ ['showInterpretations']: status, selected_artwork: selected_artwork });
    }
    checkLogged() {
        axios.get(localhostName.host + '/api/users/logged', { withCredentials: true })
            .then(res => {
                if (res.data.status) {
                    this.setState({ user: res.data.user, logged: true });
                } else {
                    this.setState({ logged: false, user: {} });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    componentDidMount() {
        let url = new URL(window.location.href);
        let selected_tab = (url.searchParams.get("tab") || this.state.overview_tab);
        let id = url.searchParams.get("id");
        let user_data = this.props.user_data;
        let learn_status;
        if (this.props.logged) {
            learn_status = (user_data.learn_status || {});
        } else {
            learn_status = (JSON.parse(getCookie("learn_status")) || {});
        };
        let item = this.props.items_list.find(i => i.id === id);
        let item_name = (this.state.master_name ? master_path[this.state.master_name] : localhostName.dn);
        let learn_item = (learn_status[item_name] || []);
        let item_index = learn_item.findIndex(c => c.id === id);
        let learn_tabs = (learn_item[item_index] || {}).tabs;
        let tabs = (this.props.item_overview.tabs || []);
        if (!url.searchParams.get("tab") && learn_item && learn_item.length > 0) {

        };
        let all_tabs = this.props.item_overview.tabs.concat([{
            "name": "notepad",
            "title": "Notepad",
            "data_folder": "Notepad",
            "desc": "You can write your personal notes for this technique here. Your notes are private, only you can see it.",
            "icon": "bell.png",
            "type": ""
        }]);
        let tab_ = (all_tabs || []).find(t => t.data_folder === selected_tab);
        this.setState({ overview_tab: selected_tab, id: id, item, tab_, learn_status, learn_tabs, user_data });
        this.learn_status(id, this.state.overview_tab);
        this.renderTab_Content(selected_tab);
        localhostName = this.props.localhostName;
        axios.get(localhostName.host + '/data_master/' + localhostName.dm + '/item_data/' + id)
            .then(res => {
                this.setState({ item_data: res.data });
            })
            .catch(function (error) {
                console.log(error);
            });
        this.setState({ selectedCity: this.props.selected_item });
        $(document).ready(function () {
            var element = $(".info_tabs");
            $(".scroll_right").on("click", function () {
                var currentPosition = element.scrollLeft();
                var newPosition = currentPosition + 100;
                element.animate({
                    scrollLeft: newPosition
                }, 500);
                if ((newPosition + element.width()) >= element.get(0).scrollWidth) {
                    $(".item_scores_info").addClass("hide_right");
                };
                if (newPosition > 0) {
                    $(".item_scores_info").removeClass("hide_left");
                };
            });
            $(".scroll_left").on("click", function () {
                var currentPosition = element.scrollLeft();
                var newPosition = currentPosition - 100;
                element.animate({
                    scrollLeft: newPosition
                }, 500);
                if (newPosition <= 0) {
                    $(".item_scores_info").addClass("hide_left");
                };
                if ((newPosition + element.width()) < element.get(0).scrollWidth) {
                    $(".item_scores_info").removeClass("hide_right");
                };
            });
            $(".info_tabs").scroll(function () {
                var newPosition = element.scrollLeft();
                if ((newPosition + element.width()) >= element.get(0).scrollWidth) {
                    $(".item_scores_info").addClass("hide_right");
                };
                if (newPosition > 0) {
                    $(".item_scores_info").removeClass("hide_left");
                };
                if (newPosition <= 0) {
                    $(".item_scores_info").addClass("hide_left");
                };
                if ((newPosition + element.width()) < element.get(0).scrollWidth) {
                    $(".item_scores_info").removeClass("hide_right");
                };
            });
            window.history.pushState(null, null, window.location.href);
        });
        this.checkLogged();
        axios.post(localhostName.host + '/api/pagetrackers/add', { type: "item", item: id })
            .then(res => {
            })
            .catch((error) => {
            });
        $(".modal_ItemOverview").attr("tabindex", "none");
        if(firstvisit == "true"){
            setTimeout(function(){
                $(".overviewtab_info").removeClass("true");
            },10000);
            setCookie("firstvisit","false",200);
        };
    }
    reload() {
        axios.get(localhostName.host + '/api/users/userdata', { withCredentials: true })
            .then(res => {
                let user_data = res.data.user_data;
                user_data.infos = (user_data.infos || { infos: {} })
                this.setState({ user_data: user_data });
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    showFormButton(targetShow, change) {
        this.setState({ [targetShow]: change });
    }
    showFormButtonItem(targetShow, change, parent) {
        this.setState({ [targetShow]: change, parent: parent });
    }
    changeTab(tab) {
        this.setState({ overview_tab: tab.data_folder, tab_text: tab.desc, tab_: tab });
        window.history.replaceState(null, null, window.location.pathname + "?id=" + this.state.id + "&tab=" + tab.data_folder);
        let visit_tracker = this.state.visit_tracker;
        let types = { items: "item", tabs: "tab", filter: "filter", pages: "page" };
        let type = "tabs";
        let value = tab.title;
        this.renderTab_Content(tab.data_folder);
        var container = document.querySelector('.info_tabs');
        var tabElement = document.getElementById(tab.data_folder);
        container.scrollLeft = tabElement.offsetLeft - (container.offsetWidth - tabElement.offsetWidth) / 2;
        axios.post(localhostName.host + '/api/pagetrackers/add', { type: types[type], [types[type]]: value })
            .then(res => {
            })
            .catch((error) => {
            });
    }
    renderTabs() {
        let tabs_i = (this.props.item_overview.tabs || []);
        let overview_tab = this.state.overview_tab;
        let note_tab = [{
            "name": "notepad",
            "title": "Notepad",
            "data_folder": "Notepad",
            "desc": "You can write your personal notes for this technique here. Your notes are private, only you can see it.",
            "icon": "bell.png",
            "type": ""
        }];
        let tabs = tabs_i.concat(note_tab);
        return tabs.map(tab => {
            let done = this.state.learn_tabs.includes(tab.data_folder);
            return (
                <div id={tab.data_folder} className={((overview_tab == tab.data_folder ? "selected " : "") + "info_tab ") + (done ? "done" : "")} onClick={() => { this.changeTab(tab) }}><div className="image"><img class="icon" src={"/icons/" + tab.icon} /><img class="icon done_image" src={"/icons/checked.png"} /></div>{tab.title}</div>
            )
        })
    }
    renderTab_Content(tab) {
        this.learn_status(this.state.id, tab);
        import(`./tabs/${tab}.js`).then(data => {
            let Tab_Content = data.default;
            this.setState({ Tab_Content })
        });
    }
    learn_status(id, tab) {
        let learn_status = (JSON.parse(getCookie("learn_status")) || {});
        let item_name = (this.state.master_name ? master_path[this.state.master_name] : localhostName.dn);
        if (!learn_status[item_name]) {
            learn_status[item_name] = [];
        };
        let item_arr = (learn_status[item_name] || []);
        let item_index = item_arr.findIndex(c => c.id === id);
        let logged = this.props.logged;
        function send_status() {
            if (logged) {
                let body = {
                    learn_status
                };
                axios.post(localhostName.host + '/api/users/learn_status', body, { withCredentials: true })
                    .then(res => {
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            } else {
                setCookie("learn_status", JSON.stringify(learn_status), 200);
            };
        }
        if (item_index < 0) {
            item_arr.push({
                "id": id,
                "tabs": [tab]
            });
            learn_status[item_name] = item_arr;
            send_status(learn_status);
            this.setState({ learn_tabs: [tab] });
        } else if (!(item_arr[item_index].tabs.includes(tab))) {
            item_arr[item_index].tabs.push(tab);
            learn_status[item_name] = item_arr;
            send_status(learn_status);
            this.setState({ learn_tabs: item_arr[item_index].tabs });
        };
        this.props.updateLearnStatus();

    }
    render() {
        let overview_tab = this.state.overview_tab;
        let tabs = (this.props.item_overview.tabs || []);
        let tab_index = tabs.findIndex(c => c.data_folder === overview_tab);
        let item = this.state.item;
        let tracker = this.props.tracker;
        let items_learned = (this.state.user_data.learned_items || []);
        let items_favourite = (this.state.user_data.favourite_items || []);
        let learned_index = items_learned.findIndex(c => c === item.id);
        let favourite_index = items_favourite.findIndex(c => c === item.id);
        let learned = (learned_index > -1 ? true : false);
        let favourite = (favourite_index > -1 ? true : false);
        let Tab_Content = this.state.Tab_Content;
        let id = this.state.id;
        let text = {
            "EN": {
                completed: "Completed",
                "many_tabs": "Many Tabs",
                "change_tabs": "Change tabs by clicking on them."
            },
            "DE": {
                completed: "Erledigt",
                "many_tabs": "Viele Tabs",
                "change_tabs": "Wechseln Sie Tabs, indem Sie darauf klicken."
            }
        };
        let tabs_r = this.state.learn_tabs.filter(c => c !== "Notepad");
        let tabs_o = (this.props.item_overview.tabs || []);
        let rate = Math.round(((tabs_r || []).length / (tabs_o || []).length).toFixed(2) * 100);
        return (
            <div className="itemOverview">
                <div className="item_scores_modal">
                    <div className="item_scores_image">
                        <img src={this.state.banner} />
                        <img className="overview_close" src="/icons/close_white.png" onClick={() => { $(".modal_ItemOverview .btn-close").trigger("click") }} />
                        <div className="item_location overview_title">
                            <div className="item_name">{item.name}</div>
                            <div className="score_result">
                                <div className="c_t">{text[lang].completed}</div>
                                <div className="main_item">{this.props.itemName}</div>
                                <div className="outer">
                                    <div className="inner" style={{ width: (rate + "%"), background: "rgb(43, 222, 115)" }}></div>
                                    <div className="score_output"><span className="score_number">{rate}%</span></div>
                                </div>
                            </div>
                        </div>
                        <BucketPlayedButton key={this.state.logged} itemName={item.name} id={item.id} logged={this.state.logged} learned={learned} favourite={favourite} reload={this.reload} />
                    </div>
                    <div className={"overviewtab_info " + firstvisit}>
                        <img src="/overviewtab_info.png" />
                        <div className={lang}>
                            <span>{text[lang].many_tabs}</span><span className="close" onClick={()=> $(".overviewtab_info").removeClass("true")}>X</span><br /> {text[lang].change_tabs}
                        </div>
                    </div>
                    <div className="item_scores_info hide_left">
                        <div className="info_tab scroll_b scroll_left"><img class="icon" src="/icons/scroll.png" /></div>
                        <div className="info_tabs" onClick={(e) => tracker("tab", e.target.innerText)}>
                            {this.renderTabs()}
                        </div>
                        <div className="info_tab scroll_b scroll_right"><img class="icon" src="/icons/scroll.png" /></div>
                    </div>
                    {Tab_Content && <Tab_Content key={this.state.overview_tab} id={id} localhostName={this.state.master_name ? (localhostName.dm + "/sub/" + master_path[this.state.master_name]) : localhostName.dm} tab_text={this.state.tab_.desc} title={this.state.tab_.title} logged={this.props.logged} />}
                    <div className="overview_next_row overview_box"><div className="box">{tab_index > 0 ? <div className="back" onClick={() => { this.changeTab(tabs[tab_index - 1]) }}>Back</div> : ""}<div className="next" onClick={() => { this.changeTab(tabs[tab_index + 1]) }}>Next</div></div></div>
                </div>
            </div>
        )
    }
}    