import React, { Component } from 'react';
import axios from 'axios';
import localhostName from "../config.json";
import LoadingSVG from './LoadingSVG';
import MustMember from "./MustMember";
import ModalBoot from '../components/Modal';

export default class CityVideos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            city: this.props.city_name,
            videos: { walk: [],golf:[],sightseeing: [],safety: [] },
            video_type: "walk",
            selected_video: { id: { videoId: "" } },
            data_loaded: false,
        };
        this.showFormButton = this.showFormButton.bind(this);
        this.playVideo = this.playVideo.bind(this);
    }
    componentDidMount() {
        axios.get(localhostName.host + "/Data/Videos/Cities/" + this.props.cityName)
            .then(res => {
                let videos = res.data;
                this.setState({ data_loaded: true, videos: videos });
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    updateContents() {
    }
    renderVideos() {
        let videos = this.state.videos[this.state.video_type];
        let count = 0;
        return videos.map((video) => {
            count += 1;
            return (
                <div className={"youtube_video " + (count > 10 && !this.props.logged ? "blur_content" : "")} onClick={() => this.playVideo(video)}>
                    <img className="thumb" src={video.snippet.thumbnails.medium.url} />
                    <img className="play_button" src="/play2.png" />
                    <div className="title">{video.snippet.title}</div>
                    {count > 10 && !this.props.logged ? 
                    <div className="become_member">
                        <div className="text">You have to be a member to see this video.</div>
                        <MustMember />
                    </div> : ""}
                </div>
            )
        });
    }
    changeType(type) {
        this.setState({ video_type: type });
    }
    showFormButton(targetShow, change) {
        this.setState({ [targetShow]: change });
    }
    playVideo(video) {
        this.setState({ selected_video: video });
        this.showFormButton("playVideo", true);
    }
    render() {
        let selected_video = this.state.selected_video;
        return (
            <div className="city_videos">
                <div className="video_topbar">
                    <div className="filter_buttons">
                        <h3>Filter by Topic</h3>
                        <div className={"f_button " + (this.state.video_type === "walk" ? "selected" : "")} onClick={() => { this.changeType("walk") }}><img class="icon" src="/icons/walk.png" /><div>City Walk</div></div>
                        <div className={"f_button " + (this.state.video_type === "golf" ? "selected" : "")} onClick={() => { this.changeType("golf") }}><img class="icon" src="/icons/golf-course-64.png" /><div>Golf</div></div>
                        <div className={"f_button " + (this.state.video_type === "sightseeing" ? "selected" : "")} onClick={() => { this.changeType("sightseeing") }}><img class="icon" src="/icons/sightseeing.png" /><div>Sightseeing</div></div>
                        <div className={"f_button " + (this.state.video_type === "food" ? "selected" : "")} onClick={() => { this.changeType("food") }}><img class="icon" src="/icons/food.png" /><div>Food</div></div>
                        <div className={"f_button " + (this.state.video_type === "travel" ? "selected" : "")} onClick={() => { this.changeType("travel") }}><img class="icon" src="/icons/tourism.png" /><div>Travel Vlog</div></div>
                        <div className={"f_button " + (this.state.video_type === "safety" ? "selected" : "")} onClick={() => { this.changeType("safety") }}><img class="icon" src="/icons/safety.png" /><div>Safety</div></div>
                    </div>
                </div>
                <div className={"videos_list " + (!this.state.data_loaded ? "data_loading" : "")} key={this.state.videos}>
                    <div className="loading_svg">
                            <LoadingSVG />
                        </div>
                    {this.renderVideos()}
                </div>
                <ModalBoot show={this.state.playVideo} hide={this.showFormButton} content={"playVideo"} className="modal_youtubeVideo">
                    <iframe width="800" height="600"
                        src={"https://www.youtube.com/embed/" + selected_video.id.videoId + "?autoplay=1"} allow='autoplay'>
                    </iframe>
                </ModalBoot >
            </div>
        )
    }
}    