import React, { Component } from 'react';
import axios from 'axios';
import localhostName from "../config.json";
import {DefaultShop} from './Shops';
import LoadingSVG from './LoadingSVG';
import BucketCollectButton from './BucketCollectButton';
import ModalBoot from '../components/Modal';

export default class CityBest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            city: this.props.city_name,
            bests: { best_sights: [], best_photo: [], best_kids: [], best_souvenirs: [] },
            best_type: "best_souvenirs",
            selected_best: { id: { bestId: "" } },
            selected_video: { id: { videoId: "" } },
            data_loaded: false,
        };
        this.showFormButton = this.showFormButton.bind(this);
    }
    componentDidMount() {
        axios.get(localhostName.host + "/Data/Best/Cities/" + this.props.cityName)
            .then(res => {
                let bests = res.data;
                this.setState({ data_loaded: true, bests: bests });
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    playVideo(video) {
        this.setState({ selected_video: video });
        this.showFormButton("playVideo", true);
    }
    renderBests() {
        let bests = this.state.bests[this.state.best_type];
        console.log(this.state.bests);
        if (!bests) {
            return (<span></span>)
        }
        return bests.map((best) => {
            console.log(best);
            let category_tags = best.category_tags;
            if(this.state.best_type === ""){
                category_tags = category_tags.filter(c => c !== "Children");
            }
            return (
                <div className="best_single" >
                    <div className="box">
                        {best.youtube ? 
                        <div className="youtube_video" onClick={() => this.playVideo(best.youtube)}>
                            <img className="thumb" src={best.youtube.snippet.thumbnails.high.url} />
                            <img className="play_button" src="/play2.png" />
                            <div className="title">{best.youtube.snippet.title}</div>
                        </div>
                        :""}
                        <div className="content">
                            <div className="title">{best.name}</div>
                            {best.avg_price ? <div className="avg_price">{best.avg_price}</div> : ""}
                            <div className="desc">{best.short_desc}</div>
                            {best.significance ? <div className="significance"><span>Significance:</span>{best.significance}</div> : ""}
                            {best.tips ? <div className="tips"><span>Tip:</span>{best.tips}</div> : ""}
                        </div>
                        {best.id ? <BucketCollectButton name="sights" id={this.props.best.id} reload={this.props.reload} user_data={this.props.user_data || {}} key={this.props.logged} logged={this.props.logged}  type="sights" done_name="Done" /> : ""}
                    </div>
                </div>
            )
        });
    }
    changeType(type) {
        this.setState({ best_type: type });
    }
    showFormButton(targetShow, change) {
        this.setState({ [targetShow]: change });
    }
    render() {
        let selected_best = this.state.selected_best;
        return (
            <div className="city_bests">
                <div className="best_topbar">
                    <div className="filter_buttons">
                        <div className={"f_button " + (this.state.best_type === "best_souvenirs" ? "selected" : "")} onClick={() => { this.changeType("best_souvenirs") }}><img class="icon" src="/icons/gift-bag.png" /><div>Best Souvenirs</div></div>
                        <div className={"f_button " + (this.state.best_type === "best_sights" ? "selected" : "")} onClick={() => { this.changeType("best_sights") }}><img class="icon" src="/icons/sightseeing.png" /><div>Best Sights</div></div>
                        <div className={"f_button " + (this.state.best_type === "best_photo" ? "selected" : "")} onClick={() => { this.changeType("best_photo") }}><img class="icon" src="/icons/photo-camera.png" /><div>Best Photo Places</div></div>
                        <div className={"f_button " + (this.state.best_type === "best_kids" ? "selected" : "")} onClick={() => { this.changeType("best_kids") }}><img class="icon" src="/icons/boy.png" /><div>Best Places for Kids</div></div>
                    </div>
                </div>
                <div className={"bests_list " + (!this.state.data_loaded ? "data_loading" : "")} key={this.state.bests}>
                    <div className="loading_svg">
                        <LoadingSVG />
                    </div>
                    {this.renderBests()}
                </div>
                <ModalBoot show={this.state.playVideo} hide={this.showFormButton} content={"playVideo"} className="modal_youtubeVideo">
                    <iframe width="800" height="600"
                        src={"https://www.youtube.com/embed/" + this.state.selected_video.id.videoId + "?autoplay=1"} allow='autoplay'>
                    </iframe>
                </ModalBoot >
            </div>
        )
    }
}    