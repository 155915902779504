import React, { Component } from 'react';
import axios from 'axios';
import localhostName from "../config.json";
import { Container, Row, Col, Button, DropdownButton, Dropdown, Form } from 'react-bootstrap';
import { Link } from "react-router-dom";
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
import ModalBoot from '../components/Modal';
import LoginModal from './LoginModal';
import RegisterData from './RegisterData';
import MembershipFAQ from './MembershipFAQ';
import MembershipTC from './MembershipTC';
import $ from 'jquery';
import { timeAgo } from '../functions/functions.js';
import '../Cities.css';

export default class JoinPageMobile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            language: "de",
            actualArtwork: {},
            artworks: [],
            devoPics: [],
            settings: { _id: "df" },
            userInfo: this.props.userInfo,
            selected_artwork: { interpretations: [], artwork_id: "", title: "" },
            register_email: this.getCookie("register_email"),
            step_register: false,
        };
        this.onChange = this.onChange.bind(this);
        this.showFormButton = this.showFormButton.bind(this);
    }
    checkLogged() {
        axios.get(localhostName.host + '/api/users/logged', { withCredentials: true })
            .then(res => {
                if (res.data.status) {
                    this.setState({ user: res.data.user, logged: true });
                    this.setState({ step_register: true });
                    if(res.data.user.basic_payment){
                        window.location.replace("/");
                    };
                } else {
                    this.setState({ logged: false, user: {} });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    onChange(e) {
        this.setState({ [e.target.id]: e.target.value });
    }
    showFormButton(targetShow, change) {
        this.setState({ [targetShow]: change });
    }
    setCookie(name, value, days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }
    getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }
    componentDidMount() {
        this.checkLogged();
    }
    proc_register() {
        let data;
        axios.post(localhostName.host + '/api/joinlist/', data)
            .then(res => {
                console.log(res);
                this.setState({ userlists: res.data });
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    render() {
        return (
            <div className={(this.state.step_register ? "" : "join_page") +  + (this.props.blocked ? " blocked" : "")}>
                <div className="join_content">
                    {!this.state.step_register ? <div className="content_boxes">
                        <div className="box box_left">
                            <div className="benefits">
                                <div className="close_button" onClick={() => { this.changeState("show_filters", false) }}><Link to="/">x</Link></div>
                                {this.props.blocked ? <div className="limit_reach">!You've reached the free usage limit. Sign up to continue your Golftus exploration.</div> : ""}
                                <div className="join_headline">Join Golftus</div>
                                <div className="benefit">
                                    <div className="desc">
                                        <img className="icon" src="/icons/checked.png" /><strong>Your digital golf travel guide. Get unlimited members-only access to an extensive range of cities in 70+ countries</strong> and discover the best places to travel, play golf and many more
                                    </div>
                                    <img src="/member1.gif" />
                                </div>
                                <div className="benefit">
                                    <div className="desc">
                                        <img className="icon" src="/icons/checked.png" /><strong>Join a global community</strong> of golf players traveling around the world
                                    </div>
                                    <img src="/profile_exp.png" />
                                </div>
                                <div className="benefit">
                                    <div className="desc">
                                        <img className="icon" src="/icons/checked.png" /><strong>Additional content beyond golf destinations</strong> Explore and learn around golf like "Golf Inner Game", "Golf Drills", "Most Common Golf Injuries", and more ...
                                    </div>
                                </div>
                                <div className="benefit">
                                    <div className="desc">
                                        <img className="icon" src="/icons/checked.png" /><strong>Add to Bucket List</strong> With Golftus, you can create your personal Bucket List filled with your favorite golf courses and dream travel destinations.
                                    </div>
                                </div>
                                <div className="benefit">
                                    <div className="desc">
                                        <img className="icon" src="/icons/checked.png" /><strong>Support Golftus to grow</strong> With every cent invested in Golftus, we can expand our database, enrich the information we provide, and ensure golfers have access to a wealth of high-quality content. 
                                    </div>
                                </div>
                                <div className="benefit">
                                    <div className="desc">
                                        <img className="icon" src="/icons/checked.png" /><strong>Unlock Filters</strong> Unleash a diverse wide range of filters for selecting golf travel destinations based on your preferences, ensuring you find the perfect spot to play and explore.
                                    </div>
                                </div>
                                <div className="benefit">
                                    <div className="desc">
                                        <img className="icon" src="/icons/checked.png" /><strong>Manage your Profile</strong> Add countries and cities you've played in and track your travels. Collect badges and more.
                                    </div>
                                </div>
                                <div className="benefit">
                                    <div className="desc">
                                        <img className="icon" src="/icons/checked.png" /><strong>See other Members</strong> See other Goltùs Members who travel for Golf.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box box_right">
                            <div className="member_text">Lifetime membership now for just USD $49. <div className="money_back_join">+ 100-Day Money Back Guarantee</div><span className="lifetime_more_info" onClick={()=> this.showFormButton("membership_faq",true)}>(<i className="fa fa-info-circle" aria-hidden="true"></i>more info)</span></div>
                            <div className="next_button">
                                <div id="next_register"  onClick={() => { this.showFormButton("step_register", true) }}>Next</div>
                            </div>
                            {/*<input id="register_email" onChange={(e) => this.onChange(e)} value={this.state.register_email} placeholder="Type your email..." />*/}
                        </div>
                    </div> : ""}
                    {this.state.step_register ? <RegisterData /> : ""}
                </div>
                <ModalBoot show={this.state.showLogin} hide={this.showFormButton} content={"showLogin"} className="modal_Login">
                    <LoginModal checkLogged={this.checkLogged} hide={this.showFormButton} />
                </ModalBoot >
                <ModalBoot show={this.state.membership_faq} hide={this.showFormButton} content={"membership_faq"} className="membership_terms_condition">
                    <MembershipFAQ />
                </ModalBoot >
                <ModalBoot show={this.state.membership_tc} hide={this.showFormButton} content={"membership_tc"} className="membership_faq">
                    <MembershipTC />
                </ModalBoot >
            </div>
        )
    }
}    