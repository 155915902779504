function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a, b) {
        /* next line works with strings and numbers, 
         * and you may want to customize it to your needs
         */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
};

function city_list_filter(state) {
    let Cities = state.cities_list;
    Cities = Cities.filter((c) => c.short_scores.total);
    Cities = Cities.filter((c) => c.name.toLowerCase().includes(state.search_city.toLowerCase()));
    Cities.sort(dynamicSort("name"));
    let countries_continent = {
        UK: "EUR", DE: "EUR", ES: "EUR", PT: "EUR", FR: "EUR", IE: "EUR", IT: "EUR", TR: "EUR", NL: "EUR",
        US: "NA", MX: "NA", CA: "NA", AU: "AS", NZ: "AS", JP: "AS", CR: "NA", DO: "NA", TH: "AS", CO: "SA", KR: "AS", VN: "AS",
        BR: "SA", AR: "SA", CH: "EUR", AT: "EUR", ZA: "AF", JM: "NA", IS: "EUR", SE: "EUR", TW: "AS", NO: "EUR", IN: "AS",
        BS: "NA", KE: "AF", MU: "AF", PHL: "AS", PE: "Peru", MY: "AS", AE: "UAE", CN: "AS", BE: "EUR",
        HU: "EUR", GR: "EUR", GI: "EUR", CZ: "EUR", DK: "EUR", HK: "AS", SG: "AS", ID: "AS", NP: "AS", QA: "ME",
        MT: "EUR", MC: "EUR", KY: "NA", CL: "SA", NA: "AF", GH: "AF"
    };
    if (state.filter_country.length > 0) {
        Cities = Cities.filter((c) => c.country === state.filter_country);
    };
    let month = state.weather_month;
    let av_temp = function (max, min) {
        return ((max + min) / 2);
    };
    if (state.weather_filter === "cold") {
        Cities = Cities.filter((c) => av_temp(c.climate[month].max, c.climate[month].min) < 8);
    };
    if (state.weather_filter === "mild") {
        Cities = Cities.filter((c) => av_temp(c.climate[month].max, c.climate[month].min) >= 8 && av_temp(c.climate[month].max, c.climate[month].min) < 18);
    };
    if (state.weather_filter === "warm") {
        Cities = Cities.filter((c) => av_temp(c.climate[month].max, c.climate[month].min) >= 18);
    };
    if (state.sunbathing) {
        Cities = Cities.filter((c) => av_temp(c.climate[month].max, c.climate[month].min) >= 18);
        Cities = Cities.filter((c) => c.filter["beach"] == "true");
    };
    if (state.not_rainy) {
        Cities = Cities.filter((c) => c.climate[month].rainy_days <= 30);
    };
    if (state.continent_filter.length > 0) {
        Cities = Cities.filter((c) => state.continent_filter.includes(countries_continent[c.country]));
    };
    if (state.pop_filter.length > 0) {
        Cities = Cities.filter((c) => c.filter[state.pop_filter] == "true");
    };
    if (state.gol_cont_filter.length > 0) {
        Cities = Cities.filter((c) => c.filter[state.gol_cont_filter] == "true");
    };
    ["golf_total", "costs", "sightseeing"].map((filt_s) => {
        let filter_val = state.slider_filter[filt_s];
        if (filter_val) {
            Cities = Cities.filter((c) => c.short_scores[filt_s] > Number(filter_val[0]) && (c.short_scores[filt_s] > 5 ? 5 : c.short_scores[filt_s]) <= Number(filter_val[1]));
        };
    });
    ["hotel_night", "3nights_2person","beer_price","cheapest_teetime"].map((filt_s) => {
        let filter_val = state.slider_filter[filt_s];
        if (filter_val) {
            Cities = Cities.filter((c) => c[filt_s] > Number(filter_val[0]) && c[filt_s] <= Number(filter_val[1]));
        };
    });
    state.filter.map((filt) => {
        Cities = Cities.filter((c) => c.filter[filt] == "true");
    });
    Cities.sort((a, b) => {
        return b.short_scores.total - a.short_scores.total;
    });
    if (state.sort_by.length > 0) {
        Cities.sort((a, b) => {
            return b.short_scores[state.sort_by] - a.short_scores[state.sort_by];
        });
    };
    if (state.sort_by === "name") {
        Cities.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
    };
    let index_london = Cities.findIndex(c => c.name === "London");
    function array_move(arr, old_index, new_index) {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr;
    };
    if (index_london > -1 && state.sort_by.length === 0) {
        Cities[index_london].golftus_fav = true;
        Cities = array_move(Cities, index_london, 0);
    };
    return Cities;
};

function list_filter(state) {
    let List = state.list;
    List = List.filter((c) => c.name.toLowerCase().includes(state.search_item.toLowerCase()));
    List.sort(dynamicSort("name"));
    let countries_continent = {
        UK: "EUR", DE: "EUR", ES: "EUR", PT: "EUR", FR: "EUR", IE: "EUR", IT: "EUR", TR: "EUR", NL: "EUR",
        US: "NA", MX: "NA", CA: "NA", AU: "AS", NZ: "AS", JP: "AS", CR: "NA", DO: "NA", TH: "AS", CO: "SA", KR: "AS", VN: "AS",
        BR: "SA", AR: "SA", CH: "EUR", AT: "EUR", ZA: "AF", JM: "NA", IS: "EUR", SE: "EUR", TW: "AS", NO: "EUR", IN: "AS",
        BS: "NA", KE: "AF", MU: "AF", PHL: "AS", PE: "Peru", MY: "AS", AE: "UAE", CN: "AS", BE: "EUR",
        HU: "EUR", GR: "EUR", GI: "EUR", CZ: "EUR", DK: "EUR", HK: "AS", SG: "AS", ID: "AS", NP: "AS", QA: "ME",
        MT: "EUR", MC: "EUR", KY: "NA", CL: "SA", NA: "AF", GH: "AF"
    };
    if (state.filter_country.length > 0) {
        List = List.filter((c) => c.country === state.filter_country);
    };
    let month = state.weather_month;
    let av_temp = function (max, min) {
        return ((max + min) / 2);
    };
    if (state.weather_filter === "cold") {
        List = List.filter((c) => av_temp(c.climate[month].max, c.climate[month].min) < 8);
    };
    if (state.weather_filter === "mild") {
        List = List.filter((c) => av_temp(c.climate[month].max, c.climate[month].min) >= 8 && av_temp(c.climate[month].max, c.climate[month].min) < 18);
    };
    if (state.weather_filter === "warm") {
        List = List.filter((c) => av_temp(c.climate[month].max, c.climate[month].min) >= 18);
    };
    if (state.sunbathing) {
        List = List.filter((c) => av_temp(c.climate[month].max, c.climate[month].min) >= 18);
        List = List.filter((c) => c.filter["beach"] == "true");
    };
    if (state.not_rainy) {
        List = List.filter((c) => c.climate[month].rainy_days <= 30);
    };
    if (state.continent_filter.length > 0) {
        List = List.filter((c) => state.continent_filter.includes(countries_continent[c.country]));
    };
    if (state.pop_filter.length > 0) {
        List = List.filter((c) => c.filter[state.pop_filter] == "true");
    };
    if (state.gol_cont_filter.length > 0) {
        List = List.filter((c) => c.filter[state.gol_cont_filter] == "true");
    };
    ["golf_total", "costs", "sightseeing"].map((filt_s) => {
        let filter_val = state.slider_filter[filt_s];
        if (filter_val) {
            List = List.filter((c) => c.main_scores[filt_s] > Number(filter_val[0]) && (c.main_scores[filt_s] > 5 ? 5 : c.main_scores[filt_s]) <= Number(filter_val[1]));
        };
    });
    ["hotel_night", "3nights_2person","beer_price","cheapest_teetime"].map((filt_s) => {
        let filter_val = state.slider_filter[filt_s];
        if (filter_val) {
            List = List.filter((c) => c[filt_s] > Number(filter_val[0]) && c[filt_s] <= Number(filter_val[1]));
        };
    });
    state.filter.map((filt) => {
        List = List.filter((c) => c.filters[filt] == "true");
    });
    List.sort((a, b) => {
        return (b.main_scores || {}).total - (a.main_scores || {}).total;
    });
    if (state.sort_by.length > 0) {
        List.sort((a, b) => {
            return b.main_scores[state.sort_by] - a.main_scores[state.sort_by];
        });
    };
    if (state.sort_by === "name") {
        List.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
    };
    function array_move(arr, old_index, new_index) {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr;
    };
    return List;
};

export { city_list_filter,list_filter }