import React, { Component } from 'react';
import axios from 'axios';
import { Container, Row, Col, Button, DropdownButton, Dropdown, Form } from 'react-bootstrap';
import localhostName from "../config.json";
import BucketPlayedButton from './BucketPlayedButton';
import CityScores from './CityScores';
import Costs from './Costs';
import Infos from './Infos';
import CityGolf from './CityGolf';
import CityVideos from './CityVideos';
import CitySightseeing from './CitySightseeing';
import CityEvents from './CityEvents';
import CityShops from './CityShops';
import CityMembers from './CityMembers';
import CityReviews from './CityReviews';
import CityFood from './CityFood';
import CityBest from './CityBest';
import CityBadges from './CityBadges';
import CityQuiz from './CityQuiz';
import CityProsCons from './CityProsCons';
import CityCulture from './CityCulture';
import CityScams from './CityScams';
import countriesShortcuts from '../Data/countriesShortcuts.json';
import { setCookie, getCookie, delete_cookie } from '../functions/functions.js';
import $ from 'jquery';
let firstvisit = (getCookie("firstvisit") || "true");

export default class CityOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            language: "de",
            selectedCity: {
                name: "Default"
            },
            artworks: [],
            devoPics: [],
            Scores: [],
            settings: { _id: "df" },
            userInfo: this.props.userInfo,
            selected_artwork: { interpretations: [], artwork_id: "", city: "" },
            check_exist: { Shops: false, },
            overview_tab: "CityScores",
            user_data: { infos: {} },
            logged: false
        };
        this.showFormButton = this.showFormButton.bind(this);
        this.showFormButtonItem = this.showFormButtonItem.bind(this);
        this.changeTab = this.changeTab.bind(this);
        this.showInterpretations = this.showInterpretations.bind(this);
        this.reload = this.reload.bind(this);
    }
    showUpdateArtwork(target, status, targetObject, artworkObject) {
        this.setState({ ['show' + target]: status });
        if (artworkObject) {
            this.setState({ [targetObject]: artworkObject });
        };
    }
    showInterpretations(status, selected_artwork) {
        this.setState({ ['showInterpretations']: status, selected_artwork: selected_artwork });
    }
    checkLogged() {
        axios.get(localhostName.host + '/api/users/logged', { withCredentials: true })
            .then(res => {
                if (res.data.status) {
                    this.setState({ user: res.data.user, logged: true });
                } else {
                    this.setState({ logged: false, user: {} });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    componentDidMount() {
        let url = new URL(window.location.href);
        let selected_tab = url.searchParams.get("tab");
        this.setState({ overview_tab: (selected_tab ? selected_tab : "CityScores") });
        axios.get(localhostName.host + "/Data/Overviewcheck/" + this.props.selected_city.name)
            .then(res => {
                let check_exist = res.data;
                this.setState({ check_exist: check_exist });
            })
            .catch(function (error) {
                console.log(error);
            });
        this.setState({ selectedCity: this.props.selected_city });
        $(document).ready(function () {
            var element = $(".info_tabs");
            $(".scroll_right").on("click", function () {
                var currentPosition = element.scrollLeft();
                var newPosition = currentPosition + 100;
                element.animate({
                    scrollLeft: newPosition
                }, 500);
                if ((newPosition + element.width()) >= element.get(0).scrollWidth) {
                    $(".city_scores_info").addClass("hide_right");
                };
                if (newPosition > 0) {
                    $(".city_scores_info").removeClass("hide_left");
                };
            });
            $(".scroll_left").on("click", function () {
                var currentPosition = element.scrollLeft();
                var newPosition = currentPosition - 100;
                element.animate({
                    scrollLeft: newPosition
                }, 500);
                if (newPosition <= 0) {
                    $(".city_scores_info").addClass("hide_left");
                };
                if ((newPosition + element.width()) < element.get(0).scrollWidth) {
                    $(".city_scores_info").removeClass("hide_right");
                };
            });
            $(".info_tabs").scroll(function () {
                var newPosition = element.scrollLeft();
                if ((newPosition + element.width()) >= element.get(0).scrollWidth) {
                    $(".city_scores_info").addClass("hide_right");
                };
                if (newPosition > 0) {
                    $(".city_scores_info").removeClass("hide_left");
                };
                if (newPosition <= 0) {
                    $(".city_scores_info").addClass("hide_left");
                };
                if ((newPosition + element.width()) < element.get(0).scrollWidth) {
                    $(".city_scores_info").removeClass("hide_right");
                };
            });
            window.history.pushState(null, null, window.location.href);
        });
        this.checkLogged();
        axios.get(localhostName.host + '/api/users/userdata', { withCredentials: true })
            .then(res => {
                let user_data = res.data.user_data;
                user_data.infos = (user_data.infos || { infos: {} })
                this.setState({ user_data: user_data });
            })
            .catch(function (error) {
                console.log(error);
            });
        $(".modal_CityOverview").attr("tabindex","none");
        if(firstvisit == "true"){
            setTimeout(function(){
                $(".overviewtab_info").removeClass("true");
            },10000);
            setCookie("firstvisit","false",200);
        };
    }
    reload() {
        axios.get(localhostName.host + '/api/users/userdata', { withCredentials: true })
            .then(res => {
                let user_data = res.data.user_data;
                user_data.infos = (user_data.infos || { infos: {} })
                this.setState({ user_data: user_data });
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    showFormButton(targetShow, change) {
        this.setState({ [targetShow]: change });
    }
    showFormButtonItem(targetShow, change, parent) {
        this.setState({ [targetShow]: change, parent: parent });
    }
    changeTab(tab) {
        this.setState({ overview_tab: tab });
        window.history.replaceState(null, null, "/?city=" + this.state.selectedCity.name + "&tab=" + tab);
        let visit_tracker = this.state.visit_tracker;
        let types = { cities: "city", tabs: "tab", filter: "filter", pages: "page" };
        let type = "tabs";
        let value = tab;
        axios.post(localhostName.host + '/api/pagetrackers/add', { type: types[type], [types[type]]: value })
            .then(res => {
            })
            .catch((error) => {
            });
    }
    render() {
        let overview_tab = this.state.overview_tab;
        let city = this.state.selectedCity;
        let tracker = this.props.tracker;
        let cities_played = (this.state.user_data.played_cities || []);
        let cities_bucket = (this.state.user_data.bucket_cities || []);
        let played_index = cities_played.findIndex(c => c.name === city.name);
        let bucket_index = cities_bucket.findIndex(c => c.name === city.name);
        let played = (played_index > -1 ? true : false);
        let bucket = (bucket_index > -1 ? true : false);
        return (
            <div className="cityOverview">
                <div className="city_scores_modal">
                    <div className="city_scores_image">
                        <img src={"/cities_banners/" + (city.name || "").replace(/\)/g,"%29").replace(/\(/g,"%28") + ".jpg"} />
                        <img className="overview_close" src="/icons/close_white.png" onClick={()=> {$(".modal_CityOverview .btn-close").trigger("click")}} />
                        <div className="item_location">
                            <div className="city_name">{city.name}</div>
                            <div className="city_state">{countriesShortcuts[city.country]}</div>
                        </div>
                        <BucketPlayedButton key={this.state.logged} cityName={city.name} country={city.country} logged={this.state.logged} played={played} bucket={bucket} reload={this.reload} />
                    </div>
                    <div className={"overviewtab_info " + firstvisit}>
                        <img src="/overviewtab_info.png" />
                        <div >
                            <span>Many Tabs</span><span className="close" onClick={()=> $(".overviewtab_info").removeClass("true")}>X</span><br /> Change tabs by clicking on them.
                        </div>
                    </div>
                    <div className="city_scores_info hide_left">
                        <div className="info_tab scroll_b scroll_left"><img class="icon" src="/icons/scroll.png" /></div>
                        <div className="info_tabs" onClick={(e) => tracker("tab", e.target.innerText)}>
                            <div className={(overview_tab == "CityScores" ? "selected " : "") + "info_tab"} onClick={() => { this.changeTab("CityScores") }}><div className="image"><img class="icon" src="/icons/golf-player.png" /></div>Scores</div>
                            <div className={(overview_tab == "Golf" ? "selected " : "") + "info_tab"} onClick={() => { this.changeTab("Golf") }}><div className="image"><img class="icon" src="/icons/course2.png" /></div>Golf Courses & more</div>
                            <div className={(overview_tab == "Infos" ? "selected " : "") + "info_tab" + (this.state.check_exist["Infos"] ? "" : " hide_tab")} onClick={() => { this.changeTab("Infos") }}><div className="image"><img class="icon" src="/icons/info.png" /></div>Infos</div>
                            <div id="Badges" className={((overview_tab == "Badges" ? "selected " : "") + "info_tab") + (this.state.check_exist["Badges"] ? "" : " hide_tab")} onClick={() => { this.changeTab("Badges") }}><div className="image"><img class="icon" src="/icons/badge.png" /></div>Badges</div>
                            <div className={(overview_tab == "Costs" ? "selected " : "") + "info_tab"} onClick={() => { this.changeTab("Costs") }}><div className="image"><img class="icon" src="/icons/costs.png" /></div>Costs</div>
                            <div id="Culture" className={((overview_tab == "Culture" ? "selected " : "") + "info_tab") + (this.state.check_exist["Culture"] ? "" : " hide_tab")} onClick={() => { this.changeTab("Culture") }}><div className="image"><img class="icon" src="/icons/badge.png" /></div>Culture</div>
                            <div id="Shops" className={((overview_tab == "Food" ? "selected " : "") + "info_tab") + (this.state.check_exist["Food"] ? "" : " hide_tab")} onClick={() => { this.changeTab("Food") }}><div className="image"><img class="icon" src="/icons/shop.png" /></div>Food</div>
                            <div id="Shops" className={((overview_tab == "Shops" ? "selected " : "") + "info_tab") + (this.state.check_exist["Shops"] ? "" : " hide_tab")} onClick={() => { this.changeTab("Shops") }}><div className="image"><img class="icon" src="/icons/shop.png" /></div>Shops, Resorts & more</div>
                            <div className={(overview_tab == "Sightseeing" ? "selected " : "") + "info_tab betaND" + (this.state.check_exist["Sightseeing"] ? "" : " hide_tab")} onClick={() => { this.changeTab("Sightseeing") }}><div className="image"><img class="icon" src="/icons/golf-player.png" /></div>Sightseeing</div>
                            <div id="ProsCons" className={((overview_tab == "ProsCons" ? "selected " : "") + "info_tab") + (this.state.check_exist["ProsCons"] ? "" : " hide_tab")} onClick={() => { this.changeTab("ProsCons") }}><div className="image"><img class="icon" src="/icons/best-seller.png" /></div>Pros & Cons</div>
                            <div id="Events" className={((overview_tab == "Events" ? "selected " : "") + "info_tab") + (this.state.check_exist["Events"] ? "" : " hide_tab")} onClick={() => { this.changeTab("Events") }}><div className="image"><img class="icon" src="/icons/best-seller.png" /></div>Events</div>
                            <div id="Best" className={((overview_tab == "Best" ? "selected " : "") + "info_tab") + (this.state.check_exist["Best"] ? "" : " hide_tab")} onClick={() => { this.changeTab("Best") }}><div className="image"><img class="icon" src="/icons/best-seller.png" /></div>Sights & more</div>
                            <div className={((overview_tab == "Videos" ? "selected " : "") + "info_tab") + (this.state.check_exist["Videos"] ? "" : " hide_tab")} onClick={() => { this.changeTab("Videos") }}><div className="image"><img class="icon" src="/icons/video.png" /></div>Videos</div>
                            <div id="Scams" className={((overview_tab == "Scams" ? "selected " : "") + "info_tab") + (this.state.check_exist["Scams"] ? "" : " hide_tab")} onClick={() => { this.changeTab("Scams") }}><div className="image"><img class="icon" src="/icons/best-seller.png" /></div>Scams</div>
                            <div className={(overview_tab == "Members" ? "selected " : "") + "info_tab"} onClick={() => { this.changeTab("Members") }}><div className="image"><img class="icon" src="/icons/members.png" /></div>Members</div>
                            <div className={(overview_tab == "Reviews" ? "selected " : "") + "info_tab"} onClick={() => { this.changeTab("Reviews") }}><div className="image"><img class="icon" src="/icons/golf-player.png" /></div>Reviews</div>
                            <div className={(overview_tab == "Leisure" ? "selected " : "") + "info_tab betaND"} onClick={() => { this.changeTab("Leisure") }}><img class="icon" src="/icons/golf-player.png" />Leisure</div>
                            <div className={(overview_tab == "Workshops" ? "selected " : "") + "info_tab betaND"} onClick={() => { this.changeTab("Workshops") }}><img class="icon" src="/icons/golf-player.png" />Workshops</div>
                            <div className="info_tab betaND"><img class="icon" src="/icons/golf-player.png" />News</div>
                            <div className="info_tab betaND"><img class="icon" src="/icons/golf-player.png" />Scams & Safety</div>
                            <div className="info_tab betaND">Statistics</div>
                            <div className="info_tab betaND"><img class="icon" src="/icons/golf-player.png" />Chat</div>
                        </div>
                        <div className="info_tab scroll_b scroll_right"><img class="icon" src="/icons/scroll.png" /></div>
                    </div>
                    {overview_tab == "CityScores" ? <CityScores key={city.name} cityName={city.name} city_nearest={this.props.city_nearest} cities_list={this.props.cities_list} /> : ""}
                    {overview_tab == "Golf" ? <CityGolf reload={this.reload} user_data={this.state.user_data || {}} key={this.state.logged} logged={this.state.logged} cityName={city.name} /> : ""}
                    {overview_tab == "Costs" ? <Costs cityName={city.name} city_nearest={this.props.city_nearest} cities_list={this.props.cities_list} /> : ""}
                    {overview_tab == "Culture" ? <CityCulture key={this.state.logged} city_nearest={this.props.city_nearest} cities_list={this.props.cities_list} logged={this.state.logged} cityName={city.name} /> : ""}
                    {overview_tab == "Infos" ? <Infos cityName={city.name} city_nearest={this.props.city_nearest} /> : ""}
                    {overview_tab == "Videos" ? <CityVideos key={this.state.logged} logged={this.state.logged} cityName={city.name} /> : ""}
                    {overview_tab == "Food" ? <CityFood cityName={city.name} reload={this.reload} user_data={this.state.user_data || {}} key={this.state.logged} logged={this.state.logged} /> : ""}
                    {overview_tab == "Sightseeing" ? <CitySightseeing cityName={city.name} /> : ""}
                    {overview_tab == "Events" ? <CityEvents cityName={city.name} reload={this.reload} user_data={this.state.user_data || {}} key={this.state.logged} logged={this.state.logged} /> : ""}
                    {overview_tab == "Shops" ? <CityShops cityName={city.name} reload={this.reload} user_data={this.state.user_data || {}} key={this.state.logged} logged={this.state.logged} /> : ""}
                    {overview_tab == "Best" ? <CityBest cityName={city.name} reload={this.reload} user_data={this.state.user_data || {}} key={this.state.logged} logged={this.state.logged} /> : ""}
                    {overview_tab == "Badges" ? <CityBadges key={this.state.logged} logged={this.state.logged} cityName={city.name} /> : ""}
                    {overview_tab == "ProsCons" ? <CityProsCons cityName={city.name} /> : ""}
                    {overview_tab == "Scams" ? <CityScams cityName={city.name} /> : ""}
                    {overview_tab == "Members" ? <CityMembers key={this.state.logged} logged={this.state.logged} cityName={city.name} /> : ""}
                    {overview_tab == "Reviews" ? <CityReviews key={this.state.logged} logged={this.state.logged} cityName={city.name} /> : ""}
                    {overview_tab == "Quiz" ? <CityQuiz cityName={city.name} /> : ""}
                </div>
            </div>
        )
    }
}    