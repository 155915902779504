import React, { Component } from 'react';
import localhostName from "../config.json";
import PageHeaderDesktop from './PageHeaderDesktop';
import PageHeaderMobile from './PageHeaderMobile';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
import axios from 'axios';

export default class RecoverPass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            language: "de",
            actualArtwork: {},
            clicked: false,
            email: "",
            token: "",
            new_password: "",
            new_password_repeat: "",
            step: "recover",
            newpass_error: "",
            pass_updated: false,
            login_failed: false,
            email_not_found: false
        };
        this.setState = this.setState.bind(this);
    }
    componentDidMount() {
        let url = new URL(window.location.href);
        let email = url.searchParams.get("email");
        let token = url.searchParams.get("tok");
        this.setState({ email, token });
    }
    onChange = e => {
        if ([e.target.id] === "step") {
            this.setState({ email_not_found: false, login_failed: false });
        };
        this.setState({ [e.target.id]: e.target.value });
    }
    login(e) {
        e.preventDefault();
        let user = {
            email: this.state.email,
            password: this.state.password
        };
        var self = this;
        if (user.email.length > 0 && user.password.length > 0) {
            axios.post(localhostName.host + '/api/users/login', user, { withCredentials: true })
                .then(res => {
                    if (res.data._id) {
                        window.location.replace("/");
                    };
                })
                .catch(function (error) {
                    if (error.response.data === "Unauthorized") {
                        self.setState({ login_failed: true });
                    };
                });
        };
    }
    createpass(e) {
        e.preventDefault();
        let user = {
            email: this.state.email,
            token: this.state.token,
            password: this.state.new_password
        };
        var self = this;
        self.setState({ newpass_error: "" });
        if (!(user.email.length > 0)) {
            self.setState({ newpass_error: "!No email entered." });
            return;
        };
        if (!(user.token.length > 0)) {
            self.setState({ newpass_error: "!No token entered." });
            return;
        }
        if (!(user.password.length > 0)) {
            self.setState({ newpass_error: "!No new password entered." });
            return;
        };
        if (!(user.password.length !== this.state.new_password_repeat)) {
            self.setState({ newpass_error: "!No new password entered." });
            return;
        };
        axios.post(localhostName.host + '/api/users/checktoken', user)
            .then(res => {
                if(res.data === "Password updated"){
                    self.setState({ step: "login",pass_updated: true });
                }
            })
            .catch((error) => {
                if (error.response.data === "token wrong") {
                    self.setState({ newpass_error: "Token not found or expired." });
                };
                if (error.response.data === "email not found") {
                    self.setState({ newpass_error: "Email not found or false." });
                };
            });
    }
    render() {
        return (
            <div id="create_ne_pass_page">
                {isMobile ?
                    <PageHeaderMobile key={this.props.logged} logged={this.props.logged} user={this.props.user} />
                    :
                    <PageHeaderDesktop key={this.props.logged} logged={this.props.logged} user={this.props.user} />
                }
                <div className="new_pass_content">
                    <div id="logreg-forms">
                        {this.state.step === "recover" ?
                            <form className="form-signin">
                                <div className="must_logged"></div>
                                <h1 > Create new Password</h1>
                                <div className="log_input email">
                                    <input className="form-control" type="email" _ref="email" placeholder="E-Mail" id="email" onChange={(e) => this.onChange(e)} value={this.state.email} required autofocus />
                                </div>
                                <div className="log_input email">
                                    <input className="form-control" type="text" _ref="token" placeholder="Token" id="token" onChange={(e) => this.onChange(e)} value={this.state.token} required />
                                </div>
                                <div className="log_input pass">
                                    <input className="form-control" type="password" _ref="new_password" placeholder="New Password" id="new_password" onChange={(e) => this.onChange(e)} value={this.state.new_password} required />
                                </div>
                                <div className="log_input pass">
                                    <input className="form-control" type="password" _ref="new_password_repeat" placeholder="Repeat New Password" id="new_password_repeat" onChange={(e) => this.onChange(e)} value={this.state.new_password_repeat} required />
                                </div>
                                <button className="btn btn-success btn-block" type="submit" onClick={(e) => this.createpass(e)} ><i className="fas fa-sign-in-alt"></i> Create new password</button>
                                {this.state.newpass_error.length > 0 ? <p className="login_error">{this.state.newpass_error}</p> : ""}
                                <hr />
                                <p className="dont_have">You remember your password?</p>
                                <a href="/?loginmodal=open" className="sub_button"><button className="btn btn-primary btn-block" type="button" id="btn-signup"><i className="fas fa-sign-in-alt"></i> Login</button></a>
                            </form>
                            :
                            ""
                        }
                        {this.state.step === "login" ?
                            <form className="form-signin">
                                <div className="must_logged"></div>
                                <h1 > Login</h1>
                                <div className="pass_updated">{this.state.pass_updated ? "Your password has been updated. Please login with your new password." : ""}</div>
                                <div className="log_input email">
                                    <input className="form-control" type="email" _ref="email" placeholder="E-Mail" id="email" onChange={(e) => this.onChange(e)} value={this.state.email} required autofocus />
                                </div>
                                <div className="log_input pass">
                                    <input className="form-control" type="password" _ref="password" placeholder="Password" id="password" onChange={(e) => this.onChange(e)} value={this.state.password} required />
                                </div>
                                <button className="btn btn-success btn-block" type="submit" onClick={(e) => this.login(e)} ><i className="fas fa-sign-in-alt"></i> Login</button>
                                {this.state.login_failed ? <p className="login_error">!Login failed. Wrong email or password.</p> : ""}
                                <div className="forgot_back" onClick={() => this.onChange({ target: { id: "step", value: "password_forgot" } })}><i class="fa fa-key" aria-hidden="true"></i>Forgot password?</div>
                                <hr />
                                <p className="dont_have">Don't have an account!</p>
                                <a href="/join"><button className="btn btn-primary btn-block" type="button" id="btn-signup"><i className="fas fa-user-plus"></i> Register New Account</button></a>
                            </form>
                            :
                            ""
                        }
                    </div>
                </div>
            </div>
        )
    }
}    