import React, {Component} from 'react';
import axios from 'axios';
import localhostName from "../config.json";
import $ from 'jquery';

export default class CitySightseeing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            city: this.props.city_name,
            videos: {},
            video_type: "walk",
            filter: "top_sights",
            sights: {top_sights: []},
            clubs: []
        };
    }
    componentDidMount() {
        axios.get(localhostName.host + "/Data/Sightseeing/Cities/" + this.props.cityName)
            .then(res => {
                let sights = res.data;
                this.setState({ sights: sights });
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    updateContents(){  
    }
    renderSights(){
        let sights = this.state.sights[this.state.filter];
        return sights.slice(0,30).map((sight)=>{
            return(
                <div className="sight">
                    <div className="box">
                        <img src={sight.img}></img>
                        <div className="text_box">
                            <div className="title">{sight.name}</div>
                            <div className="desc">{sight.desc}</div>
                        </div>
                    </div>
                </div>
            )
        });
    }
    render() {
        return (
            <div className="city_videos city_sights">
                <div className="video_topbar">
                    <div className="filter_buttons">
                        <h3>Filter </h3>
                        <div className={"f_button " + (this.state.filter === "top_sights" ? "selected" : "")}  onClick={()=>{console.log(this.state.videos)}}>Top Sights</div>
                        <div className={"f_button " + (this.state.filter === "arts" ? "selected" : "")}>Arts & Culture</div>
                        <div className="f_button">Museums</div>
                        <div className="f_button">History</div>
                        <div className="f_button">Outdoor Activities</div>
                    </div>
                </div>
                <div className="sights_list" >
                    {this.renderSights()}
                </div>
            </div>            
        )
    }
}    