import React, {Component} from 'react';
import axios from 'axios';
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import localhostName from "../config.json";

export default class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {loggedIn: false};
    }
    componentDidMount(){
        this.setState({loggedIn: this.props.loggedIn});
    }
    logout() {
        axios.get(localhostName.host + '/api/users/logout',{withCredentials: true})
        .then( () => {
            this.props.updateUser({
                loggedIn: false,
                username: null
            });
            this.props.history.replace('/login');
        })
        .catch(error => {
            console.log('Logout error')
        });
    }
    render() {
        const loggedIn = this.props.loggedIn;
        return (
            <nav className="navbar navbar-profile">
                <a className="nav_a" href="#">
                    <img src="/icons/home.png" />
                    <div>Golftus</div>
                </a>
                <a className="nav_a" href="#">
                    <img src="/icons/cityscape.png" />
                    <div>Cities</div>
                </a>
                <a className="nav_a" href="#">
                    <img src="/icons/mail.png" />
                    <div>ClubMail</div>
                </a>
                <a className="nav_a" href="#">
                    <img src="/icons/collection.png" />
                    <div>Collection</div>
                </a>
                <a className="nav_a" href="#">
                    <img src="/icons/menu.png" />
                    <div>Menu</div>
                </a>
            </nav>
        )
    }
}