import React, { Component } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import localhostName from "../config.json";
import $ from 'jquery';
import BucketCollectButton from './BucketCollectButton';
import CityOverview from './CityOverview';
let type_icons = { Fashion: { name: "Fashion", icon: "clothes-rack.png" },BeautyClinic: { name: "Aesthetics & Surgery", icon: "botox.png" }, Hotel: { name: "Hotel", icon: "hotel.png" }, Jewelry: { name: "Jewelry", icon: "jewelry.png" },Golf: { name: "Golf", icon: "golf-shop.png" },Restaurant: { name: "Restaurant", icon: "food.png" },Wellness: { name: "Wellness", icon: "massage.png" },Art: { name: "Art", icon: "many_art_galleries.png" },Wine: { name: "Wine", icon: "wine.png" } };

export const BeautyClinicShop = class BeautyClinicShop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            language: "de",
            actualArtwork: {},
            clicked: false,
        };
    }
    render() {
        let shop = this.props.shop;
        let rating = shop.rating;
        let rating_star = 0;
        let rating_star_half = false;
        if(rating > 3.2 && rating <= 3.7){rating_star = 3; rating_star_half = true;};
        if(rating > 3.7 && rating <= 4.2){rating_star = 4;};
        if(rating > 4.2 && rating <= 4.7){rating_star = 4; rating_star_half = true;};
        if(rating > 4.7){rating_star = 5;};
        function return_stars() {
            let stars = [];
            for(let i = 0; i < rating_star; i++){stars.push(<img src='/icons/rating_star.png' />)};
            return stars;
        };
        return (
            <div className="shop BeautyClinic" id={shop.name} onMouseEnter={() => { this.props.shopHover(shop) }} onMouseLeave={() => { this.props.shopHover({}) }}>
                    <div className="box">
                        <div className="type_icon"><img className="image_icon" src={"/icons/" + (type_icons[shop.type] || {}).icon} /><div className="icon_text">{(type_icons[shop.type] || {}).name}</div></div>
                        <div className="name">{shop.name}</div>
                        {shop.rating ? <div className="rating"><span>{shop.rating}</span>{return_stars()}{rating_star_half ? <img src='/icons/rating_star_half.png' /> : ''}</div> : ""}
                        <div className="address">{shop.street + ", " + shop.post_code + " " + shop.city}</div>
                        <div className="desc_short">{shop.desc_short}</div>
                        <div className="videos_services">
                        <div className="course_videos" onClick={()=> {this.props.selectShop(shop);this.props.showFormButton("showServices",true);}}>
                            <img class="play_button" src="/icons/services.png" />Services
                        </div>
                        {shop.instagram ? 
                        <div className="course_videos" onClick={()=> {this.props.selectShop(shop);this.props.showFormButton("showPhotos",true);}}>
                            <img class="play_button" src="/icons/picture.png" />Photos({shop.instagram.length})
                        </div>
                        : ""}
                        {shop.youtube ? 
                        <div className="course_videos" onClick={()=> {this.props.selectShop(shop);this.props.showFormButton("showVideos",true);}}>
                            <img class="play_button" src="/play2.png" />Videos({shop.youtube.length})
                        </div>
                        : ""}
                        </div>
                        <div className="website_social_media">
                            {shop.url.length > 0 ? <div className="website"><a tag="Website" target="_blank" href={shop.url}><img src='https://upload.wikimedia.org/wikipedia/commons/thumb/c/c4/Globe_icon.svg/2000px-Globe_icon.svg.png'></img></a></div> : ""}
                            {(shop.social_media.facebook || "").length > 0 ? <div className="facebook"><a target="_blank" href={shop.social_media.facebook}><img src='https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/Facebook_icon_2013.svg/640px-Facebook_icon_2013.svg.png'></img></a></div> : ""}
                            {(shop.social_media.instagram || "").length > 0 ? <div className="instagram"><a target="_blank" href={shop.social_media.instagram}><img src="/icons/instagram.png" /></a></div> : ""}
                            {(shop.social_media.twitter || "").length > 0 ? <div className="twitter"><a target="_blank" href={shop.social_media.twitter}><img src="/icons/twitter.png" /></a></div> : ""}
                            {shop.id ? <BucketCollectButton name="shops" id={this.props.shop.id} reload={this.props.reload} user_data={this.props.user_data || {}} key={this.props.logged} logged={this.props.logged} item={{id:shop.id}} type="shop_more" done_name="Done" /> : ""}
                        </div>
                    </div>
                </div>
        )
    }
}

export const DefaultShop = class DefaultShop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            language: "de",
            actualArtwork: {},
            clicked: false,
        };
    }
    render() {
        let shop = this.props.shop;
        let rating = shop.review_google;
        let rating_star = 0;
        let rating_star_half = false;
        if(rating > 3.2 && rating <= 3.7){rating_star = 3; rating_star_half = true;};
        if(rating > 3.7 && rating <= 4.2){rating_star = 4;};
        if(rating > 4.2 && rating <= 4.7){rating_star = 4; rating_star_half = true;};
        if(rating > 4.7){rating_star = 5;};
        function return_stars() {
            let stars = [];
            for(let i = 0; i < rating_star; i++){stars.push(<img src='/icons/rating_star.png' />)};
            return stars;
        };
        return (
            <div className="shop BeautyClinic" id={shop.name} onMouseEnter={() => { this.props.shopHover(shop) }} onMouseLeave={() => { this.props.shopHover({}) }}>
                    <div className="box">
                        <div className="type_icon"><img className="image_icon" src={"/icons/" + (type_icons[shop.type] || {}).icon} /><div className="icon_text">{(type_icons[shop.type] || {}).name}</div></div>
                        <div className="name">{shop.name}</div>
                        {rating ? <div className="rating"><span>{rating}</span>{return_stars()}{rating_star_half ? <img src='/icons/rating_star_half.png' /> : ''}</div> : ""}
                        <div className="address">{shop.street + ", " + shop.post_code + " " + shop.city}</div>
                        <div className="desc_short">{shop.desc_short}</div>
                        <div className="videos_services">
                        {shop.youtube ? 
                        <div className="course_videos" onClick={()=> {this.props.selectShop(shop);this.props.showFormButton("showVideos",true);}}>
                            <img class="play_button" src="/play2.png" />Videos({shop.youtube.length})
                        </div>
                        : ""}
                        </div>
                        <div className="website_social_media">
                            {shop.url.length > 0 ? <div className="website"><a tag="Website" target="_blank" href={shop.url}><img src='https://upload.wikimedia.org/wikipedia/commons/thumb/c/c4/Globe_icon.svg/2000px-Globe_icon.svg.png'></img></a></div> : ""}
                            {(shop.social_media.facebook || "").length > 0 ? <div className="facebook"><a target="_blank" href={shop.social_media.facebook}><img src='https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/Facebook_icon_2013.svg/640px-Facebook_icon_2013.svg.png'></img></a></div> : ""}
                            {(shop.social_media.instagram || "").length > 0 ? <div className="instagram"><a target="_blank" href={shop.social_media.instagram}><img src="/icons/instagram.png" /></a></div> : ""}
                            {(shop.social_media.twitter || "").length > 0 ? <div className="twitter"><a target="_blank" href={shop.social_media.twitter}><img src="/icons/twitter.png" /></a></div> : ""}
                            {shop.id ? <BucketCollectButton name="shops" id={this.props.shop.id} reload={this.props.reload} user_data={this.props.user_data || {}} key={this.props.logged} logged={this.props.logged} item={{id:shop.id}} type="shop_more" done_name="Done" /> : ""}
                        </div>
                    </div>
                </div>
        )
    }
}