import React, {Component} from 'react';
import { Link } from "react-router-dom";
import $ from 'jquery';

export default class CityReviews extends Component {
    constructor(props) {
        super(props);
        this.state = {
            city: this.props.city_name,
            videos: {},
            video_type: "walk",
            filter: "top_sights",
            sights: {top_sights: []},
            clubs: []
        };
    }
    componentDidMount() {
    }
    updateContents(){  
    }
    render() {
        return (
            <div className="city_members">
                <div className="head">City Reviews for {this.props.cityName} by Golftus Members</div>
                <div className="members_list">
                    {!this.props.logged ? <div className="member">
                        <img src="/city_reviews.png"></img>
                        <div className='text'>
                            <div>You have to be a member to see the reviews.</div>
                            <div className="member_ship">
                                <div className="member_text">Lifetime membership now for just $49 USD. <span onClick={()=> $('#MembershipFAQ_modal').addClass('show')}>+learn more</span></div>
                                <Link to="/join" state={{register_email: "this.state.register_email"}}><button>Proceed to registration</button></Link>
                                <div className="already_login">Already a member? <span>Log in</span></div>
                            </div>
                        </div>
                    </div> : <div className="member">There are no Golftus member reviews for {this.props.cityName} yet.</div>}
                </div>
            </div>            
        )
    }
}    