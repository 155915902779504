import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import localhostName from "../config.json";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import ModalBoot from '../components/Modal';
import LoginModal from './LoginModal';
import MembershipFAQ from './MembershipFAQ';
import MembershipTC from './MembershipTC';
import { setCookie, getCookie, delete_cookie } from '../functions/functions.js';
import $ from 'jquery';

export default class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            emailerror: false,
            registerStage: "name",
            username: '',
            password: '',
            step: "Data",
            error_text: "",
            email: "",
            firstname: "",
            lastname: "",
            currency: "USD",
            coupon: "",
            price: 49,
            price_original: 49,
            vat: 0.2,
            terms_condition: false,
            visit_tracker: {
                cities: [],
                tabs: [],
                filter: [],
                pages: []
            },
            basic_payment: null
        }
        this.onSubmitNext = this.onSubmitNext.bind(this);
        this.onSubmitNextEmail = this.onSubmitNextEmail.bind(this);
        this.showFormButton = this.showFormButton.bind(this);
    }
    showFormButton(targetShow, change) {
        this.setState({ [targetShow]: change });
    }
    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
        if( e.target.id === "coupon" && e.target.value.length > 7 &&  e.target.value.slice(0,3) == "GC-" ){
            this.setState({ price: 49.5, coupon_check: true });
        };
    }
    checkLogged() {
        axios.get(localhostName.host + '/api/users/logged', { withCredentials: true })
            .then(res => {
                if (res.data.status) {
                    this.setState({ user: res.data.user, logged: true });
                    let price = ( res.data.user.coupon && Number(res.data.user.coupon) > 0 ? (Number(res.data.user.coupon) * 49) : 49);
                    this.setState({ step: "Payment", email: res.data.user.email, firstname: res.data.user.firstname, lastname: res.data.user.lastname, coupon_check: res.data.user.coupon, price });
                } else {
                    this.setState({ logged: false, user: {} });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    componentDidMount() {
        $("body").removeClass("startBackColor");
        this.checkLogged();
        let url = new URL(window.location.href);
        let basic_payment = url.searchParams.get("basic_payment");
        let visit_tracker = getCookie("visit_tracker");
        if (visit_tracker) {
            this.setState({ visit_tracker: JSON.parse(visit_tracker) });
        };
        this.visit_tracker();
    }
    setCookie(name, value, days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }
    getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }
    onSubmitNext(e, nextStage) {
        e.preventDefault();
        this.setState({ step: nextStage });
    }
    submitData(e) {
        e.preventDefault();
        let new_user = {
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            coupon: (this.state.coupon_check ? 0.5 : null),
            coupon_code: (this.state.coupon ? this.state.coupon : null),
            email: this.state.email,
            password: this.state.password,
        };
        let check = true;
        let state = this.state;
        let error_text = "";
        if(!state.terms_condition){
            check = false;
            error_text = "You didn't accept the Terms and Conditions"
        };
        if (state.password !== state.password_repeat) {
            check = false;
            error_text = "Password repeat is wrong."
        };
        if (!(state.password.length > 0)) {
            check = false;
            error_text = "Password is missing."
        };
        if (state.email !== state.email_repeat) {
            check = false;
            error_text = "E-Mail repeat is wrong."
        };
        if (!(state.email.length > 0)) {
            check = false;
            error_text = "E-Mail is missing."
        };
        if (!(state.lastname.length > 0)) {
            check = false;
            error_text = "Lastname is missing."
        };
        if (!(state.firstname.length > 0)) {
            check = false;
            error_text = "Firstname is missing."
        };
        if (check) {
            axios.post(localhostName.host + '/api/users/signup', new_user)
                .then(res => {
                    if (res.data != false) {
                        if (res.data.user_exist) {
                            error_text = "This E-Mail is already in use.";
                            this.setState({ error_text });
                        } else {
                            axios.post(localhostName.host + '/api/users/login', new_user, { withCredentials: true })
                                .then(res => {
                                    this.setCookie("user_reg", JSON.stringify(new_user));
                                    this.setState({ step: "Payment" });
                                    this.visit_tracker("Payment");
                                })
                                .catch(function (error) {
                                    console.log(error);
                                });
                        };
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        };
        this.setState({ error_text });
    }
    onSubmitNextEmail(e, nextStage) {
        e.preventDefault();
        let checkemail = this.state.userlists.find(x => x.email === this.state.email);
        axios.get(localhostName.host + '/api/users//checkusers/' + this.state.email)
            .then(res => {
                if (res.data.length > 0) {
                    this.setState({ emailmessage: "Diese Email wird schon verwendet.", emailerror: true });
                } else {
                    if (checkemail == undefined) {
                        this.setState({ emailmessage: "Diese Email ist leider nicht freigegeben.", emailerror: true });
                    } else {
                        this.setState({ registerStage: nextStage });
                    };
                };
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    stageBack(beforeStage) {
        this.setState({ registerStage: beforeStage });
    }
    visit_tracker(v_type) {
        let visit_tracker = this.state.visit_tracker;
        let types = { cities: "city", tabs: "tab", filter: "filter", pages: "page" };
        let type = "pages";
        let value = "";
        if(v_type === "Payment"){
            value = "JoinPagePayment";
            if (!(visit_tracker[type].includes(value))) {
                visit_tracker[type].push(value);
                this.setState({ visit_tracker: visit_tracker });
                setCookie("visit_tracker", JSON.stringify(visit_tracker), 365);
                axios.post(localhostName.host + '/api/pagetrackers/add', { type: types[type], [types[type]]: value })
                    .then(res => {
                    })
                    .catch((error) => {
                    });
            };
        };
    }
    render() {
        let invoice_id = Math.random().toString(16).slice(2);
        let price = this.state.price;
        let coupon_check = this.state.coupon_check;
        return (
            <Container className="register">
                <div>
                    <Row className="register_box">
                        <div className="brand_name" onClick={()=> this.showFormButton("membership_faq",true)}>Golftus Lifetime Basic Membership</div>
                        <div className={this.state.step == "Payment" ? "payment_form" : "signUp"}>
                            {this.state.step !== "Closed" ? "" :
                                <div>
                                    <h1>Unfortunately there are too many registration the last 24 hours. Sign up your E-Mail to be informed when you can register.</h1>
                                    <Form.Group className="input_form" >
                                        <Form.Label>E-Mail*</Form.Label>
                                        <Form.Control type="email" _ref="email" placeholder="E-Mail" id="email" onChange={(e) => this.onChange(e)} value={this.state.email} required />
                                    </Form.Group>
                                    <Button type="submit" className="signSubmit">Get informed when a slot is free</Button>
                                </div>
                            }
                            {this.state.step == "Data" ? <Form onSubmit={(e) => this.onSubmitNext(e, "email")}><Row>
                                <Form.Group className="input_form" >
                                    <Form.Label>Firstname*</Form.Label>
                                    <Form.Control type="text" _ref="firstna" placeholder="Firstname" id="firstname" onChange={(e) => this.onChange(e)} value={this.state.firstname} required />
                                </Form.Group>
                                <Form.Group className="input_form" >
                                    <Form.Label>Lastname*</Form.Label>
                                    <Form.Control type="text" _ref="lastname" placeholder="Lastname" id="lastname" onChange={(e) => this.onChange(e)} value={this.state.lastname} required />
                                </Form.Group>
                                <Form.Group className="input_form" >
                                    <Form.Label>E-Mail*</Form.Label>
                                    <Form.Control type="email" _ref="email" placeholder="E-Mail" id="email" onChange={(e) => this.onChange(e)} value={this.state.email} required />
                                </Form.Group>
                                <Form.Group className="input_form" >
                                    <Form.Label>E-Mail Repeat*</Form.Label>
                                    <Form.Control type="email" _ref="email_repeat" placeholder="E-Mail Repeat" id="email_repeat" onChange={(e) => this.onChange(e)} value={this.state.email_repeat} required />
                                </Form.Group>
                                <Form.Group className="input_form" >
                                    <Form.Label>Password*</Form.Label>
                                    <Form.Control type="password" minlength="8"  _ref="password" placeholder="Password" id="password" onChange={(e) => this.onChange(e)} value={this.state.password} required />
                                </Form.Group>
                                <Form.Group className="input_form" >
                                    <Form.Label>Password Repeat*</Form.Label>
                                    <Form.Control type="password" _ref="password_repeat" placeholder="Password Repeat" id="password_repeat" onChange={(e) => this.onChange(e)} value={this.state.password_repeat} required />
                                </Form.Group>
                                <Form.Group className="input_form" >
                                    <Form.Label>Coupon</Form.Label>
                                    {coupon_check ? <div className="coupon_check">Great, the coupon code is is valid</div> : ""}
                                    <Form.Control type="text" _ref="coupon" placeholder="Coupon Code" id="coupon" onChange={(e) => this.onChange(e)} value={this.state.coupon} />
                                </Form.Group>
                                <Form.Group className="input_form membership_lifetime">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
                                    <label className="form-check-label" for="flexCheckChecked" onClick={()=> this.showFormButton("membership_faq",true)}>
                                        Lifetime Basic Membership {this.state.coupon_check ? <s>${this.state.price_original} USD </s> : ""} ${price} USD<span className="lifetime_more_info">(<i className="fa fa-info-circle" aria-hidden="true"></i>)</span>
                                    </label>
                                </Form.Group>
                                <Form.Group className="input_form membership_lifetime">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked={this.state.terms_condition} onChange={()=> this.showFormButton("terms_condition",!this.state.terms_condition)} />
                                    <label className="form-check-label terms_cond_label" for="flexCheckChecked">
                                        I've read and accept the <span className="terms_link" onClick={()=> this.showFormButton("membership_tc",true)} >terms & conditions *</span>
                                    </label>
                                </Form.Group>
                                {this.state.error_text.length > 0 ? <p className="error_text">!{this.state.error_text}</p> : ""}
                                {this.state.emailerror ? <p className="formerror">! {this.state.emailmessage}</p> : ""}
                                <Button type="submit" className="signSubmit" onClick={(e) => this.submitData(e)} >Next</Button>
                                <div className="already_login">Already a member? <Link to={"/login"}><span onClick={() => { this.showFormButton("showLogin", true);}}>Log in</span></Link></div>
                            </Row></Form> : ""}
                            {this.state.step == "Payment" ?
                                <div className="payment" key={this.state.step}>
                                    <style>{".box.box_left{display: none} .box.box_right{width: 100%!important} .member_text{display: none}"}</style>
                                    <div className="payment_left">
                                        {this.state.basic_payment ? <div className="payment_not_completed">!You have not yet completed the payment for your membership.</div> : ""}
                                        <div className="user_information">
                                            <div className="title" >Member Information</div>
                                            <div className="info" >
                                                <div>E-Mail</div>
                                                <div>{this.state.email}</div>
                                            </div>
                                            <div className="info" >
                                                <div>Firstname</div>
                                                <div>{this.state.firstname}</div>
                                            </div>
                                            <div className="info" >
                                                <div>Lastname</div>
                                                <div>{this.state.lastname}</div>
                                            </div>
                                        </div>
                                        <div className="title" >Payment</div>
                                        <div>All transactions are secure and encrypted.</div>
                                        <div className="payment_paypal">
                                            <PayPalScriptProvider options={{ "client-id": "AcA4epZFEIH-ShSQGJe5P4BYET7ZnWamGXOvznu3ziuB415-4UZS5eaXO-Hg34L0dRWh04XomoGqqVod" }}>
                                                <PayPalButtons
                                                    disabled={false}
                                                    fundingSource={undefined}
                                                    createOrder={(data, actions) => {
                                                        return actions.order.create({
                                                            purchase_units: [
                                                                {
                                                                    amount: {
                                                                        value: (Number(price) + Number((price * this.state.vat).toFixed(2))),
                                                                        currency_code: 'USD',
                                                                        breakdown: {
                                                                            item_total: { value: (Number(price) + Number((price * this.state.vat).toFixed(2))), currency_code: 'USD' }
                                                                        }
                                                                    },
                                                                    description: "Golftus Lifetime Basic Membership",
                                                                    custom_id: "basic_" + this.state.email,
                                                                    invoice_id: invoice_id,
                                                                    items: [{
                                                                        name: "Golftus Lifetime Basic Membership",
                                                                        unit_amount: { value: price, currency_code: 'USD' },
                                                                        quantity: 1
                                                                    },
                                                                    {
                                                                        name: "VAT ",
                                                                        unit_amount: { value: (price * this.state.vat).toFixed(2), currency_code: 'USD' },
                                                                        quantity: 1
                                                                    }]
                                                                },
                                                            ],
                                                        });
                                                    }}
                                                    onApprove={(data, actions) => {
                                                        return actions.order.capture().then((details) => {
                                                            function delete_cookie(name, path, domain) {
                                                                if (this.getCookie(name)) {
                                                                    document.cookie = name + "=" +
                                                                        ((path) ? ";path=" + path : "") +
                                                                        ((domain) ? ";domain=" + domain : "") +
                                                                        ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
                                                                }
                                                            };
                                                            const responsePromise = axios.post(localhostName.host + '/api/users/basicpayment', { email: this.state.email, basic_payment: true }, { withCredentials: true })
                                                                .then(res => {
                                                                    window.location.href = '/?success=basic';
                                                                })
                                                                .catch(function (error) {
                                                                    console.log(error);
                                                                });
                                                            const responsePromise3 = fetch(localhostName.host + '/api/orders/finished/', {
                                                                method: 'post',
                                                                headers: {
                                                                    'content-type': 'application/json'
                                                                },
                                                                body: JSON.stringify({ email: this.state.email, firstname: this.state.firstname, lastname: this.state.lastname, amount: { value: 49, currency_code: 'USD' } })
                                                            });
                                                            responsePromise.then(function (responseFromServer) {
                                                                if (responseFromServer.status === 200) {
                                                                    window.location.href = '/?success=basic';
                                                                } else {
                                                                    alert('smth went wrong');
                                                                }

                                                            });
                                                        });
                                                    }}
                                                />
                                            </PayPalScriptProvider>
                                        </div>
                                    </div>
                                    <div className="payment_right">
                                        <div className="overview">
                                            <div className="head">Order Summary</div>
                                            <div className="text">
                                                <div>1x Lifetime Basic Membership</div>
                                                <div>{this.state.email}</div>
                                            </div>
                                            <div className="price">
                                                <div>Price:</div>
                                                <div>${this.state.price_original} USD</div>
                                            </div>
                                            {coupon_check ?
                                            <div className="price">
                                                <div>Coupon:</div>
                                                <div>-$49.5 USD</div>
                                            </div> : ""}
                                            <div className="price">
                                                <div>Vat:</div>
                                                <div>${(price * this.state.vat).toFixed(2)} USD</div>
                                            </div>
                                            <div className="price">
                                                <div>Total:</div>
                                                <div>${(Number(price) + Number((price * this.state.vat).toFixed(2)))} USD</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : ""}
                        </div>
                    </Row>
                </div>
                <ModalBoot show={this.state.showLogin} hide={this.showFormButton} content={"showLogin"} className="modal_Login">
                    <LoginModal checkLogged={this.checkLogged} hide={this.showFormButton} />
                </ModalBoot >
                <ModalBoot show={this.state.membership_faq} hide={this.showFormButton} content={"membership_faq"} className="membership_faq">
                    <MembershipFAQ />
                </ModalBoot >
                <ModalBoot show={this.state.membership_tc} hide={this.showFormButton} content={"membership_tc"} className="membership_faq">
                    <MembershipTC />
                </ModalBoot >
            </Container>
        )
    }
}