import React, { Component } from 'react';
import $ from 'jquery';

export default class MembershipFAQ extends Component {
    constructor(props) {
        super(props);
        this.state = {
            google_analytics_check: true,
        };
    }
    changeCheck(name,value){
        this.setState({[name]: value});
    }
    componentDidMount() {
        function setCookie(name, value, days) {
            var expires = "";
            if (days) {
                var date = new Date();
                date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
                expires = "; expires=" + date.toUTCString();
            }
            document.cookie = name + "=" + (value || "") + expires + "; path=/";
        }
        function getCookie(name) {
            var nameEQ = name + "=";
            var ca = document.cookie.split(';');
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == ' ') c = c.substring(1, c.length);
                if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
            }
            return null;
        }
        function eraseCookie(name) {
            document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        }
        if (getCookie("cookies_accepted") == null) {
            $('.cookie_banner').addClass("show");
        } else if (getCookie("cookies_accepted") == "true") {
            if (getCookie("cookies_no_google_analytics") == null) {
            };
        };
        $('.cookies_button button.accept_all').click(function () {
            setCookie("cookies_accepted", "true", 365);
            $('.cookie_banner').removeClass("show");
            $(".google_analytics").load("/Google_Analytics.html", function () {
            });
        });
        $('.tool_setting').click(function () {
            $('.cookies_accept').addClass("hide");
            $('.cookies_tool').addClass("show");
        });
        $('.back_cookies').click(function () {
            $('.cookies_accept').removeClass("hide");
            $('.cookies_tool').removeClass("show");
        });
        $('.tool_accept_cookies').click(function () {
            if ($(".google_analytics_check").is(':checked')) {
                $(".google_analytics").load("/Google_Analytics.html", function () {
                });
                eraseCookie("cookies_no_google_analytics")
            } else {
                setCookie("cookies_no_google_analytics", "true", 365);
            };
            $('.cookie_banner').removeClass("show");
            setCookie("cookies_accepted", "true", 365);
        });
        $('.tool_expand').click(function () {
            $(this).parent().parent().parent().find(".panel-body").addClass("show");
            $('.tool_expand').addClass("hide");
            $('.tool_minus').addClass("show");
        });
        $('.tool_minus').click(function () {
            $(this).parent().parent().parent().find(".panel-body").removeClass("show");
            $('.tool_minus').removeClass("show");
            $('.tool_expand').removeClass("hide");
        });

    }
    render() {
        return (
            <div className="cookie_banner">
                <style>{".show{display: block;} .hide{display: none;}"}</style>
                <div className="cookies_accept">
                    <div className="container">
                        <div className="row">
                            <div className="headline">Cookies</div>
                            <div className="col-md-12 cookies_text">
                                <p>We use cookies to ensure the proper functioning of a website, for
                                    measuring reach, and to improve user-friendliness. For information about
                                    cookies and how to disable them, please refer to our <a class="datenschutz_link"
                                        href="/privacy">Privacy Policy</a>.</p>
                            </div>
                            <div className="col-md-12 cookies_button">
                                <button class="accept_all">Accept All</button>
                                <button class="tool_setting">Tool Settings</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cookies_tool">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 cookies_text">
                                <p>
                                    <strong>Your Privacy</strong><br /><br />
                                    When you visit a website, it has the ability to collect or store data
                                    through your browser. This can involve the use of cookies or similar technologies.
                                    Information about you, your device settings, or your device itself can be collected.
                                    This data is typically used to ensure that the website functions as expected.
                                    While you as an individual are usually not directly identified, the collected information
                                    can offer a personalized user experience.
                                    <br /><br />
                                    We respect your right to privacy and offer you the opportunity to reject certain types
                                    of technologies. By clicking on the different categories, you will receive
                                    further information and can change the default settings.
                                    <br /><br />
                                    Please note that blocking certain technologies may result in limited use
                                    of the website and the services we offer.
                                    <br /><br />
                                    For more information about cookies, please refer to our <a class="datenschutz_link"
                                        href="/datenschutz">Privacy Policy</a>.
                                </p>
                                <div className="col-md-12 cookies_button tool_accept_all">
                                    <button class="accept_all">Accept All</button>
                                </div>
                            </div>
                            <div className="tools">
                                <div className="col-md-12">
                                    <div className="panel panel-primary">
                                        <div className="panel-heading">
                                            <h3 class="panel-title"><span class="tool_expand">+</span><span
                                                class="tool_minus">-</span>Google Analytics <input
                                                    class="form-check-input google_analytics_check" type="checkbox" value=""
                                                    id="flexCheckDisabled" onChange={()=>this.changeCheck("google_analytics_check",!this.state.google_analytics_check)} checked={this.state.google_analytics_check} /></h3>
                                        </div>
                                        <div className="panel-body">
                                            <p>Google Analytics</p>
                                            <p>For Google Analytics, we utilize the service provided by Google Ireland Limited (Gordon House, Barrow Street, Dublin 4, Ireland).
                                                This service uses cookies and similar technologies. The collected data is used for analyzing user behavior on the website,
                                                as well as for targeted recommendations and interest-based advertising.</p>
                                            <p>By using Google Analytics, the following data may be collected: your web request, IP address, browser type, browser language,
                                                date and time of your request, one or more cookies that may uniquely identify your browser, and your interaction with the website.</p>
                                            <p>The processing of your personal data for Google Analytics is based on your consent in accordance with Article 6(1)(a) of the GDPR
                                                and Article 25 of the TTDSG Paragraph 1.</p>
                                            <p>The data is processed by Google in the European Union and worldwide, and stored for a period of up to 2 years. However, it is possible
                                                to selectively delete the data at any time.</p>
                                            <p>The data is shared with Google and its worldwide affiliates, and may be transferred to third countries worldwide.</p>
                                            <p>For more information about the data processor's privacy policy, please visit <a href="https://policies.google.com/?gl=en&amp;hl=en"
                                                target="_blank">https://policies.google.com/?gl=en&amp;hl=en</a>.</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 cookies_button tool_accept_all tool_settings_button">
                                <button class="back_cookies">Back</button>
                                <button class="tool_accept_cookies">Confirm Selection</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}    