import React, { Component } from 'react';
import axios from 'axios';
import localhostName from "../config.json";
import LoadingSVG from './LoadingSVG';
import ModalBoot from '../components/Modal';
import MustMember from "./MustMember";
import BucketCollectButton from './BucketCollectButton';

export default class CityEvent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            city: this.props.city_name,
            events: [],
            event_type: "annual",
            selected_event: { id: { eventId: "" } },
            selected_video: { id: { videoId: "" } },
            data_loaded: false,
        };
        this.showFormButton = this.showFormButton.bind(this);
    }
    componentDidMount() {
        axios.get(localhostName.host + "/Data/Events/Cities/" + this.props.cityName)
            .then(res => {
                let events = res.data;
                this.setState({ data_loaded: true, events: events });
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    playVideo(video) {
        this.setState({ selected_video: video });
        this.showFormButton("playVideo", true);
    }
    renderEvents() {
        let events = this.state.events;
        console.log(this.state.events);
        let count = 0;
        if (!events) {
            return (<span></span>)
        }
        return events.map((event) => {
            count += 1;
            let blur = false;
            if(count > 5 && !this.props.logged){
                blur = true;
            }
            return (
                <div className="event_single" >
                    <div className={"box " + (blur ? "blur" : "")}>
                        {event.youtube ?
                            <div className="youtube_video" onClick={() => this.playVideo(event.youtube)}>
                                <img className="thumb" src={"https://i.ytimg.com/vi/" + event.youtube.id + "/hqdefault.jpg"} />
                                <img className="play_button" src="/play2.png" />
                                <div className="title">{event.youtube.name}</div>
                            </div>
                            : ""}
                        <div>
                            <div className="title">{event.name}</div>
                            <div className="date">{event.date}</div>
                            <div className="cat">{event.category}</div>
                            <div className="desc">{event.desc}</div>
                        </div>
                        {event.id ? <BucketCollectButton name="events" id={event.id} reload={this.props.reload} user_data={this.props.user_data || {}} key={this.props.logged} logged={this.props.logged} item={{ id: event.id }} type="event_more" done_name="Done" /> : ""}
                    </div>
                    {blur ? 
                    <div className="become_member">
                        <div className="text">You have to be a member to see this event.</div>
                        <MustMember />
                    </div> : ""}
                </div>
            )
        });
    }
    changeType(type) {
        this.setState({ event_type: type });
    }
    showFormButton(targetShow, change) {
        this.setState({ [targetShow]: change });
    }
    render() {
        let selected_event = this.state.selected_event;
        return (
            <div className="city_events">
                <div className="event_topbar betaND">
                    <div className="filter_buttons">
                        <div className={"f_button " + (this.state.event_type === "event_souvenirs" ? "selected" : "")} onClick={() => { this.changeType("event_souvenirs") }}><img class="icon" src="/icons/gift-bag.png" /><div>Event Souvenirs</div></div>
                        <div className={"f_button " + (this.state.event_type === "event_photo" ? "selected" : "")} onClick={() => { this.changeType("event_photo") }}><img class="icon" src="/icons/photo-camera.png" /><div>Event Photo Places</div></div>
                        <div className={"f_button " + (this.state.event_type === "event_sights" ? "selected" : "")} onClick={() => { this.changeType("event_sights") }}><img class="icon" src="/icons/sightseeing.png" /><div>Event Sights</div></div>
                        <div className={"f_button " + (this.state.event_type === "event_kids" ? "selected" : "")} onClick={() => { this.changeType("event_kids") }}><img class="icon" src="/icons/boy.png" /><div>Event Places for Kids</div></div>
                    </div>
                </div>
                <h2>Anual Events</h2>
                <div className={"events_list " + (!this.state.data_loaded ? "data_loading" : "")} key={this.state.events}>
                    <div className="loading_svg">
                        <LoadingSVG />
                    </div>
                    {this.renderEvents()}
                </div>
                <ModalBoot show={this.state.playVideo} hide={this.showFormButton} content={"playVideo"} className="modal_youtubeVideo">
                    <iframe width="800" height="600"
                        src={"https://www.youtube.com/embed/" + this.state.selected_video.id + "?autoplay=1"} allow='autoplay'>
                    </iframe>
                </ModalBoot >
            </div>
        )
    }
}    