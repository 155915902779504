import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Routes, BrowserRouter, Navigate } from "react-router-dom";
import axios from 'axios';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap-grid.min.css";
import localhostName from "./config.json";


import RegisterNon from './components/RegisterNon';
import JoinPage from './components_swing/JoinPage';
import JoinPageMobile from './components_swing/JoinPageMobile';
import SwingCities from './components_swing/SwingCities';
import SwingCitiesMobile from './components_swing/SwingCitiesMobile';
import SwingCitiesFilter from './components_swing/SwingCitiesFilter';
import SwingCitiesFilterOffline from './components_swing/SwingCitiesFilterOffline';
import AdvertisePage from './components_swing/AdvertisePage';
import AdvertisePageCheckout from './components_swing/AdvertisePageCheckout';
import Terms from './components_swing/Terms';
import Privacy from './components_swing/Privacy';
import Contact from './components_swing/Contact';
import MembershipPage from './components_swing/MembershipPage';
import AboutUs from './components_swing/AboutUs';
import ProfilePage from './components_swing/ProfilePage';
import DailyReport from './components_swing/DailyReport';
import RegisterTest from './components_swing/RegisterDataTest';
import Cookies from './components_swing/Cookies';
import OrderFinished from './components_swing/OrderFinished';
import RecoverPass from './components_swing/RecoverPass';
import MembershipFAQ from './components_swing/MembershipFAQ';
import PagetrackerPage from './components_swing/PagetrackerPage';
import SingleModal from './components_swing/SingleModal';
import LoginModal from './components_swing/LoginModal';
import MasterList from './components_swing/Masters/ListPage';
import MasterListMobile from './components_swing/Masters/ListPageMobile';
import CheckoutTest from './components_swing/Checkout/CheckoutTest';
import $ from 'jquery';
import { setCookie, getCookie, delete_cookie, distance, getObjectWithLowestValue } from './functions/functions.js';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import "./Art.css";
import "./App.css";
import "./components_swing/ad_page.css";
import './Items.css';
import './Cities.css';
import './Items_Mobile.css';
import './Cities_Mobile.css';
import './Extra.css';
import './safari.css';
setCookie("lang", "EN", 365);


export default class AppDesktop extends Component {
  constructor() {
    super();
    this.state = {
      fullname: null,
      loggedIn: null,
      users: [{}],
      user: { username: "", user_id: "", fullname: "" },
      register_email: "",
      ip_country: "",
      surf_points: 0,
      customer_data: {},
      cities_list: [],
      block: false,
      logged: false,
      basic_payment: false,
      city_nearest: null,
      visit_tracker: {
        cities: [],
        tabs: [],
        filter: [],
        pages: []
      },
    };
    this.block = this.block.bind(this);
  }
  changeurl() {
    console.log("app updated");
  }
  updateUser(userObject) {
    this.setState(userObject)
  }
  checkLogged() {
    let tracker_stop = getCookie("tracker_stop");
    let tracker_stop_daily = getCookie("tracker_stop_daily");
    let block = this.block;
    axios.get(localhostName.host + '/api/users/logged', { withCredentials: true })
      .then(res => {
        if (res.data.status) {
          console.log(res.data);
          this.setState({ user: res.data.user, logged: true, basic_payment: res.data.user.basic_payment });
          if (!res.data.user.basic_payment && window.location.pathname !== "/join") {
            window.location.replace("/join?c=complete_payment");
          }
        } else {
          this.setState({ logged: false, user: {} });
          if (tracker_stop === "true" && tracker_stop_daily === "true") {
            block();
          };
        }
      })
      .catch(function (error) {
        console.log(error);
        if (tracker_stop === "true" && tracker_stop_daily === "true") {
          block();
        };
      });
  }
  block() {
    this.setState({ block: true });
  }
  componentDidMount() {
    this.checkLogged();
    axios.get(localhostName.host + "/Data/citiesover")
      .then(res => {
        this.setState({ cities_list: res.data });
      })
      .catch(function (error) {
        console.log(error);
      });
    console.log(document.referrer);
    axios.post(localhostName.host + '/api/pagetrackers/addvisit', { referrer: window.location.href,referrer_origin: document.referrer, type: "visit" })
      .then(res => {
      })
      .catch((error) => {
      });
    let hash = window.location.hash;
    if (hash == "#betaversion") {
      $('head').append('<link rel="stylesheet" href="/beta_version.css" type="text/css" />');
    };
    axios.get(localhostName.host + "/badges_filled.json")
      .then(res => {
        window.badges = res.data;
      })
      .catch(function (error) {
        console.log(error);
      });
    let visit_tracker = getCookie("visit_tracker");
    if (visit_tracker) {
      this.setState({ visit_tracker: JSON.parse(visit_tracker) });
    };
    axios.get('https://geolocation-db.com/json/')
      .then(res => {
        let user_geo = res.data;
        this.setState({ user_geo: res.data });
        axios.get('/CitiesGeo.json')
          .then(res => {
            let cities = (res.data || []);
            window.cities_geo = cities;
            let lat1 = user_geo.latitude;
            let lon1 = user_geo.longitude;
            cities.map((c, index) => {
              let lat2 = c.geolocation.geo.split(",")[0];
              let lon2 = c.geolocation.geo.split(",")[1];
              let distance_city = distance(lat1, lon1, lat2, lon2, "K");
              cities[index].distance_home = distance_city;
            });
            let city_nearest = getObjectWithLowestValue(cities, "distance_home");
            console.log(city_nearest);
            this.setState({ city_nearest: city_nearest });
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  visit_tracker() {
    let visit_tracker = this.state.visit_tracker;
    let types = { cities: "city", tabs: "tab", filter: "filter", pages: "page" };
    let type = "pages";
    let value = window.location.pathname;
    if (!(visit_tracker[type].includes(value))) {
      visit_tracker[type].push(value);
      this.setState({ visit_tracker: visit_tracker });
      setCookie("visit_tracker", JSON.stringify(visit_tracker), 365);
    };
  }
  render() {
    console.log("Block " + this.state.block);
    return (
      <div className="App">
        <Router userInfo={this.state.user} >
          <Routes>
            <Route path="/join" element={isMobile ? <JoinPageMobile blocked={this.state.block} /> : <JoinPage blocked={this.state.block} />} />
            <Route exact path="/master/:name" key={this.state.block} element={isMobile ? <MasterListMobile block={this.block} blocked={this.state.block} basic_payment={this.state.basic_payment} /> : <MasterList block={this.block} blocked={this.state.block} basic_payment={this.state.basic_payment} />}></Route>
            <Route path="/cities" key={this.state.block} element={isMobile ? <SwingCitiesMobile block={this.block} blocked={this.state.block} basic_payment={this.state.basic_payment} /> : <SwingCities block={this.block} blocked={this.state.block} basic_payment={this.state.basic_payment} />}></Route>
            <Route path="/" key={this.state.block} element={isMobile ? <SwingCitiesMobile block={this.block} blocked={this.state.block} basic_payment={this.state.basic_payment} /> : <SwingCities block={this.block} blocked={this.state.block} basic_payment={this.state.basic_payment} />}></Route>
            <Route path="/filter" element={(this.state.surf_points < 6 ? <SwingCitiesFilter /> : <JoinPage />)}></Route>
            <Route path="/filteroffline" element={(this.state.surf_points < 6 ? <SwingCitiesFilterOffline /> : <JoinPage />)}></Route>
            <Route path="/advertise" element={<AdvertisePage key={this.state.ip_country} ip_country={this.state.ip_country} />}></Route>
            <Route path="/advertise-checkout" element={<AdvertisePageCheckout cities_list={this.state.cities_list} key={this.state.ip_country} ip_country={this.state.ip_country} />}></Route>
            <Route path="/register" element={<RegisterNon />}></Route>
            <Route path="/registertest" element={<RegisterTest />}></Route>
            <Route path="/membership" key={this.state.logged} element={<MembershipPage logged={this.state.logged} city_nearest={this.state.city_nearest} user={this.state.user} />}></Route>
            <Route path="/aboutus" key={this.state.logged} element={<AboutUs logged={this.state.logged} city_nearest={this.state.city_nearest} user={this.state.user} />}></Route>
            <Route path="/profile" element={<ProfilePage city_nearest={this.state.city_nearest} />}></Route>
            <Route path="/contact" element={<Contact logged={this.state.logged} city_nearest={this.state.city_nearest} user={this.state.user} />}></Route>
            <Route path="/passwordreset" element={<RecoverPass />}></Route>
            <Route path="/terms-and-conditions" element={<Terms logged={this.state.logged} city_nearest={this.state.city_nearest} user={this.state.user} />}></Route>
            <Route path="/privacy-policy" element={<Privacy logged={this.state.logged} city_nearest={this.state.city_nearest} user={this.state.user} />}></Route>
            <Route path="/order" element={<OrderFinished />}></Route>
            <Route path="/uhgfx78t" element={<PagetrackerPage />}></Route>
            <Route path="/listingtest" element={<CheckoutTest />}></Route>
            <Route path="/dailyreport567826hugft57" element={<DailyReport />}></Route>
          </Routes>
          <Cookies />
        </Router>
        <SingleModal id={"MembershipFAQ_modal"} child_id="MembershipFAQ" >
          <MembershipFAQ />
        </SingleModal >
        <SingleModal id={"Login_modal"} child_id="LoginModal" className="modal_Login">
          <LoginModal checkLogged={this.checkLogged} hide={this.showFormButton} />
        </SingleModal >
      </div>
    );
  }
}


