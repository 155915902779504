import React, {Component} from 'react';

export default class MembershipFAQ extends Component {
    render() {
        return (
            <div className="membership_info">
                <div class="brand_name">Golftus Lifetime Basic Membership</div>
                <div className="faq">
                    <div className="box">
                        <div className="question">
                            What is Golftus Lifetime Membership?
                        </div>
                        <div className="answer">
                            Golftus Lifetime Membership is a membership plan that grants you unlimited access to all data on the Golftus website for a lifetime.
                        </div>
                    </div>
                    <div className="box">
                        <div className="question">
                            How much does Golftus Lifetime Membership cost?
                        </div>
                        <div className="answer">
                            The Golftus Lifetime Membership is available for a one-time payment of $49. Unlike other websites that charge monthly fees ranging from $20 to $30, we offer a remarkable deal—only a single payment for a lifetime of benefits. We believe in providing exceptional value at an incredibly fair price. 
                        </div>
                    </div>
                    <div className="box">
                        <div className="question">
                            What benefits do I get with Golftus Lifetime Membership?
                        </div>
                        <div className="answer">
                            With Golftus Lifetime Membership, you get exclusive access to detailed information about golf courses and cities worldwide, advanced search filters, personalized recommendations, and the ability to create and manage your golf travel.
                        </div>
                    </div>
                    <div className="box">
                        <div className="question">
                            What is the Golftus 100-Day Money Back Guarantee, and how can I request a refund if I'm not satisfied with my purchase within that period?
                        </div>
                        <div className="answer">
                            The Golftus 100-Day Money Back Guarantee allows you to receive a full refund if you're not completely satisfied with your membership within the first 100 days of purchase. To request a refund, simply contact our support team via email or our website's contact form. We strive to process refunds promptly and without any hassles, ensuring your satisfaction with our services.
                        </div>
                    </div>
                    <div className="box">
                        <div className="question">
                            What payment methods are accepted for Golftus membership?
                        </div>
                        <div className="answer">
                        We accept both PayPal and Credit Card payments. Our goal is to provide a seamless and hassle-free payment experience for our valued members.
                        </div>
                    </div>
                    <div className="box">
                        <div className="question">
                            Can I cancel my Golftus Lifetime Membership?
                        </div>
                        <div className="answer">
                            Golftus Lifetime Membership is non-refundable, as it provides lifelong access to the website's features and data.
                        </div>
                    </div>
                    <div className="box">
                        <div className="question">
                        Can I transfer my Golftus Lifetime Membership to someone else?
                        </div>
                        <div className="answer">
                        No, the Golftus Lifetime Membership is non-transferable and is only valid for the individual who purchased it.
                        </div>
                    </div>
                    <div className="box">
                        <div className="question">
                        Is Golftus Lifetime Membership valid for all future updates?
                        </div>
                        <div className="answer">
                        Yes, Golftus Lifetime Membership guarantees access to all future updates and enhancements to the website, ensuring you stay up-to-date with the latest golf travel information.
                        </div>
                    </div>
                    <div className="box">
                        <div className="question">
                        Can I access Golftus on multiple devices with a Lifetime Membership?
                        </div>
                        <div className="answer">
                        Yes, Golftus Lifetime Membership allows you to access the website and its features from multiple devices, including smartphones, tablets, and computers.
                        </div>
                    </div>
                    <div className="box">
                        <div className="question">
                        How can I contact Golftus support regarding my Lifetime Membership?
                        </div>
                        <div className="answer">
                        For any inquiries or assistance related to your Golftus Lifetime Membership, you can reach out to our support team via email or the contact form on our website.
                        </div>
                    </div>
                </div>
            </div>            
        )
    }
}    