import React, { Component } from 'react';
import axios from 'axios';
import localhostName from "../config.json";
import ModalBoot from '../components/Modal';
import MustMember from "./MustMember";
import $ from 'jquery';
$(".city_food img").on('error', function () {
    $(this).hide();
});

export default class CityFood extends Component {
    constructor(props) {
        super(props);
        this.state = {
            city: this.props.city_name,
            videos: [],
            video_type: "walk",
            selected_video: { id: { videoId: "" } },
            clubs: []
        };
        this.showFormButton = this.showFormButton.bind(this);
        this.playVideo = this.playVideo.bind(this);
    }
    componentDidMount() {
        axios.get(localhostName.host + "/Data/Food/Cities/" + this.props.cityName)
            .then(res => {
                let videos = res.data;
                this.setState({ videos: videos });
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    renderVideos() {
        let videos = this.state.videos;
        videos.sort(function (a, b) { return b.freq - a.freq });
        let count = 0;
        return videos.map((video) => {
            count += 1;
            if (count < 10 || this.props.logged) {
                return (
                    <div className="youtube_video" >
                        <img className="thumb" src={"/food/" + video.image} onError={(e) => console.log($(e.target).attr("src","/food/coming_soon.jpg"))} />
                        <div className="title">{video.name}</div>
                        <div className="avg_price">{video.avg_price}</div>
                        <div className="frequency"><span>Frequency</span> {video.freq}/5</div>
                        <div className="desc">{video.short_desc}</div>
                    </div>
                )
            }else{
                return (
                    <div className="youtube_video" onError={() => console.log("jekko")}>
                        <div className="content blur">
                            <img className="thumb" src={"/food/" + video.image} onError={(e) => console.log($(e.target).attr("src","/food/coming_soon.jpg"))} />
                            <div className="title">{video.name}</div>
                            <div className="avg_price">{video.avg_price}</div>
                            <div className="frequency"><span>Frequency</span> {video.freq}/5</div>
                            <div className="desc">{video.short_desc}</div>
                        </div>
                        <div className="become_member">
                            <div className="text">You have to be a member to see this dish.</div>
                            <MustMember />
                        </div>
                    </div>
                )
            }
        });
    }
    changeType(type) {
        this.setState({ video_type: type });
    }
    showFormButton(targetShow, change) {
        this.setState({ [targetShow]: change });
    }
    playVideo(video) {
        this.setState({ selected_video: video });
        this.showFormButton("playVideo", true);
    }
    render() {
        let selected_video = this.state.selected_video;
        return (
            <div className="city_videos city_food">
                <div className="video_topbar">
                    <div className="filter_buttons">
                        <h3>Most common (bought) Dishes in {this.props.cityName}</h3>
                        {/*<div className={"f_button " + (this.state.video_type === "walk" ? "selected" : "")}  onClick={()=>{this.changeType("walk")}}><img class="icon" src="/icons/walk.png" /><div>Most sold</div></div>
                        <div className={"f_button " + (this.state.video_type === "golf" ? "selected" : "")} onClick={()=>{this.changeType("golf")}}><img class="icon" src="/icons/golf-course-64.png" /><div>Most popular</div></div>
                        <div className={"f_button " + (this.state.video_type === "sightseeing" ? "selected" : "")} onClick={()=>{this.changeType("sightseeing")}}><img class="icon" src="/icons/sightseeing.png" /><div>Cheapest</div></div>
                        <div className={"f_button " + (this.state.video_type === "food" ? "selected" : "")} onClick={()=>{this.changeType("food")}}><img class="icon" src="/icons/food.png" /><div>Unique</div></div>*/}
                    </div>
                </div>
                <div className="videos_list" key={this.state.videos}>
                    {this.renderVideos()}
                </div>
                <ModalBoot show={this.state.playVideo} hide={this.showFormButton} content={"playVideo"} className="modal_youtubeVideo">
                    <iframe width="800" height="600"
                        src={"https://www.youtube.com/embed/" + selected_video.id.videoId + "?autoplay=1"} allow='autoplay'>
                    </iframe>
                </ModalBoot >
            </div>
        )
    }
}    