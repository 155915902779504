import React, { Component } from 'react';
import axios from 'axios';
import { Container, Row, Col, Button, DropdownButton, Dropdown, Form } from 'react-bootstrap';
import { Link } from "react-router-dom";
import ModalBoot from '../components/Modal';
import { dynamicSort } from '../functions/sort.js';
import localhostName from "../config.json";
import BucketButtonModal from './BucketButtonModal';
import PageHeaderDesktop from './PageHeaderDesktop';
import PageHeaderMobile from './PageHeaderMobile';
import CityOverview from './CityOverview';
import JoinPage from './JoinPage';
import LoadingSVG from './LoadingSVG';
import LoginModal from './LoginModal';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import RangeSlider from './UI/RangeSlider';
import $ from 'jquery';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";


export default class AboutUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            language: "de",
            actualArtwork: {},
            cities_list: [],
            cities_filter: [],
            devoPics: [],
            settings: { _id: "df" },
            userInfo: this.props.userInfo,
            selected_city: {},
            show_filters: false,
            weather_filter: "",
            weather_month: (new Date).getMonth() + 1,
            continent_filter: [],
            pop_filter: "",
            gol_cont_filter: "",
            surf_points: 0,
            search_city: "",
            sort_by: "",
            filter_country: "",
            not_rainy: false,
            sunbathing: false,
            data_loaded: false,
            filter: [],
            filter_values: {},
            slider_filter: {}
        };
        this.showFormButton = this.showFormButton.bind(this);
        this.onChange = this.onChange.bind(this);
    }
    onChange(e) {
        this.setState({ [e.target.id]: e.target.value });
    }
    setCookie(name, value, days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }
    getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }
    componentDidMount() {
        
    }
    showFormButton(targetShow, change) {
    }
    nav_menu() {
        this.setState({ nav_menu: !this.state.nav_menu });
    }
    render() {
        let filter = this.state.filter;
        let check_filter = function (name) {
            let filter_index = filter.findIndex((filter) => filter.name == name);
            return ((filter[filter_index] || { value: false }).value ? "selected" : "not_selected");
        };
        let filter_value = function (name) {
            let filter_index = filter.findIndex((filter) => filter.name == name);
            return (filter[filter_index] || { value: false }).value;
        };
        let countries = {
            UK: "United Kingdom", DE: "Germany", ES: "Spain", PT: "Portugal", FR: "France", IE: "Ireland", IT: "Italy", TR: "Turkey", NL: "Netherlands",
            US: "USA", MX: "Mexico", CA: "Canada", AU: "Australia", NZ: "New Zealand", JP: "Japan", CR: "Costa Rica", DO: "Dominican Republic", TH: "Thailand", CO: "Colombia", KR: "South Korea", VN: "Vietnam", BR: "Brazil", AR: "Argentina", CH: "Switzerland", AT: "Austria", ZA: "South Africa", JM: "Jamaica", IS: "Iceland", SE: "Sweden", TW: "Taiwan", NO: "Norway", IN: "New Delhi", BS: "Bahamas", KE: "Kenya", MU: "Mauritius", PHL: "Philippines", PE: "Peru", MY: "Malaysia", AE: "United Arab Emirates", CN: "China", BE: "Belgium", HU: "Hungary", GR: "Greece", GI: "Gibraltar", CZ: "Czech Republic", DK: "Denmark", HK: "Hong Kong", SG: "Singapore", ID: "Indonesia", NP: "Nepal", QA: "Qatar", MT: "Malta", MC: "Monaco", KY: "Cayman Islands", CL: "Chile", NA: "Namibia", GH: "Ghana"
        };
        let countries_names = {};
        Object.keys(countries).map((key, index) => {
            countries_names[countries[key]] = key;
        });
        countries_names = Object.keys(countries_names).sort().reduce(
            (obj, key) => {
                obj[key] = countries_names[key];
                return obj;
            },
            {}
        );
        return (
            <div className={"membership_page aboutus_page cities_page " + (isMobile ? "mobile" : "")}>
                {isMobile ?
                    <PageHeaderMobile key={this.props.logged} logged={this.props.logged} user={this.props.user} />
                    :
                    <PageHeaderDesktop key={this.props.logged} logged={this.props.logged} user={this.props.user} />
                }
                <div className="page_content container">
                    <h1>About us</h1>
                    <div>
                        <div className="aboutus_box">
                            <div className="founders">
                                <div className="box">
                                    <img src="/dincer.png" />
                                    <div className="name">Dinçer Bekmezci</div>
                                    <div className="info_sites">
                                        <a>LinkedIn</a>
                                        <a>Website</a>
                                        <a>Blog</a>
                                    </div>
                                </div>
                                <div className="box">
                                    <img src="/asmir.png" />
                                    <div className="name">Asmir Adrovic</div>
                                    <div className="info_sites">
                                        <a>LinkedIn</a>
                                    </div>
                                </div>
                            </div>
                            <div className="aboutus_text">
                                <div>Welcome to Golftus, the brainchild of two passionate individuals from Germany, now based in the vibrant city of London. Our unique perspective on the world of golf stems from our background in physics, where numbers and data fuel our love for analytical thinking.</div>
                                <div>Our journey began with the understanding that golf is so much more than a game played on manicured greens. It's an opportunity to delve into history, immerse in different cultures, and explore breathtaking destinations.</div>
                                <div>Driven by our passion, we set out to develop a comprehensive platform that goes beyond the traditional boundaries of golf course information. Golftus is designed to be a one-stop destination where golfers can embark on a holistic exploration of golf travel destinations and immerse themselves in the vibrant tapestry of each city.</div>
                                <div>We believe that golfers deserve access to all the information they need to make informed decisions about their golf travel experiences. Golftus serves as your trusted companion, providing a wealth of information about the best golf courses, hidden gems, local attractions, and everything in between. Our commitment to accuracy and attention to detail ensures that you have all the tools necessary to plan your golf adventures with confidence.</div>
                                <div>At Golftus, our mission is to enhance your golfing journey by providing a seamless blend of information, inspiration, and connections. We're here to empower you to discover new destinations, forge unforgettable experiences, and build lasting memories on and off the golf course.</div>
                                <div>Join us on this exhilarating golfing adventure. Explore Golftus and unlock a world of golf travel possibilities!</div>
                            </div>
                        </div>
                        <div className="bottom_register header_register_box">
                            <div className="box">
                                <div className="member_text">Lifetime membership now for just $49. <span onClick={()=> {$('#MembershipFAQ_modal').addClass('show')}}>+learn more</span></div>
                                {/*<input type="email" id="register_email" onChange={(e) => {this.onChange(e);this.setCookie("register_email",e.target.value,1)}} value={this.state.register_email} placeholder="Type your email..." />*/}
                                <Link to="/join" state={{ register_email: "this.state.register_email" }}><button>Proceed to registration</button></Link>
                                <div className="already_login" >Already a member? <span onClick={() => $('#Login_modal').addClass('show')}>Log in</span></div>
                            </div>
                        </div>
                    </div>
                </div>
                <BucketButtonModal />
            </div>
        )

    }
}    