import React, { Component } from 'react';
import axios from 'axios';
import { Container, Row, Col, Button, DropdownButton, Dropdown, Form } from 'react-bootstrap';
import { Link } from "react-router-dom";
import localhostName from "../config.json";
import LoadingSVG from './LoadingSVG';
import { MapContainer, TileLayer, useMap, Marker, Popup, Circle } from 'react-leaflet';
import BucketPlayedButtonCourse from './BucketPlayedButtonCourse';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import RangeSlider from './UI/RangeSlider';
import ModalBoot from '../components/Modal';
import $ from 'jquery';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
function deleteStringFromArray(arr, str) {
    const index = arr.indexOf(str);
    if (index > -1) {
        arr.splice(index, 1);
    }
}
let types = {
    "Semi-Private": "Semi-Private",
    "Resort": "Public",
    "Private/Resort": "Private",
    "Private": "Private",
    "Public": "Public",
    "Municipal": "Public",
    "Public/Resort": "Public",
    "Public/Military": "Military",
    "Semi-Private/Resort": "Semi-Private",
    "Military": "Military",
    "Public/Municipal": "Public",
    "Semi-Private/Community": "Semi-Private",
    "Private/Community": "Private",
    "Residential Development": "Semi-Private",
    "Private/Military": "Military",
    "Semi-Private/Military": "Military",
    "Semi-Private/Municipal": "Semi-Private"
}
export default class GolfCourses extends Component {
    constructor(props) {
        super(props);
        this.state = {
            courses: [],
            search_course: "",
            citiesgeo: "",
            filter: {
                distance: 100,
                ranking: ""
            },
            year_min: 1850,
            year_max: 2023,
            data_loaded: false,
            list_map_select: false,
            courses_number: 0,
            youtube: [],
            selected_video: {},
            selected_course: { y_videos: [] },
            city_architects: [],
            architect_filter: [],
            types_filter: []
        };
        this.showFormButton = this.showFormButton.bind(this);
    }
    componentDidMount() {
        axios.get(localhostName.host + "/Data/Courses/Cities/" + this.props.cityName)
            .then(res => {
                let courses = (Array.isArray(res.data) ? res.data : []);
                courses.sort((a, b) => ((a.pp_data || { year_built: 0 }).year_built === 0 || !(a.pp_data || { year_built: 0 }).year_built ? 3000 : a.pp_data.year_built) - ((b.pp_data || { year_built: 0 }).year_built === 0 || !(b.pp_data || { year_built: 0 }).year_built ? 3000 : b.pp_data.year_built));
                this.setState({ data_loaded: true, courses: courses, courses_number: courses.length });
            })
            .catch(function (error) {
                console.log(error);
            });
        axios.get(localhostName.host + "/Data/Citygeo/" + this.props.cityName)
            .then(res => {
                let citiesgeo = res.data;
                this.setState({ citiesgeo: citiesgeo });
            })
            .catch(function (error) {
                console.log(error);
            });
        axios.get("/AllCityArchitects.json")
            .then(res => {
                this.setState({ city_architects: (res.data[this.props.cityName] || []) });
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    showFormButton(targetShow, change) {
        this.setState({ [targetShow]: change });
    }
    architect_filter(name) {
        let architect_filter = this.state.architect_filter;
        if (architect_filter.includes(name)) {
            deleteStringFromArray(architect_filter, name)
        } else {
            architect_filter.push(name);
        };
        this.setState({ architect_filter });
    }
    types_filter(name) {
        let types_filter = this.state.types_filter;
        if (types_filter.includes(name)) {
            deleteStringFromArray(types_filter, name)
        } else {
            types_filter.push(name);
        };
        this.setState({ types_filter });
    }
    search_course(e) {
        this.setState({ search_course: e.target.value })
    }
    renderCourses() {
        let courses = this.state.courses;
        let filter = this.state.filter;
        let search_course = this.state.search_course;
        if (search_course.length > 0) {
            courses = courses.filter((c) =>
                c.name.toLowerCase().includes(search_course.toLowerCase()) ||
                c.address.toLowerCase().includes(search_course.toLowerCase()) ||
                c.short_desc.toLowerCase().includes(search_course.toLowerCase())

            );
        };
        courses = courses.filter((c) => Number(c.citydistance) < (filter.distance + 5));
        if (filter.ranking.length > 0) {
            courses = courses.filter((c) => c.rankings.findIndex((r) => r.name === filter.ranking) > -1);
        };
        ["holes", "par", "course_length_yards", "year_built"].map((f) => {
            if (filter[f]) {
                courses = courses.filter((c) => c.pp_data[f] > filter[f][0] && c.pp_data[f] <= filter[f][1])
            };
        });
        {/*["clubs_rental"].map((f) => {
            if (filter[f]) {
                courses = courses.filter((c) => c.pp_data[f] > filter[f][0] && c.pp_data[f] <= filter[f][1])
            };
        });*/}
        if (filter["high_ratings"]) {
            courses = courses.filter((c) => Number(c.rating) > 4.5);
        };
        let courses_played = (this.props.user_data.played_courses || []);
        let courses_bucket = (this.props.user_data.bucket_courses || []);
        if (this.state.architect_filter.length > 0) {
            courses = courses.filter(c => {
                return (c.pp_data || { architect: [] }).architect.some(c2 => {
                    return this.state.architect_filter.includes(c2.name);
                });
            });
        };
        if (this.state.types_filter.length > 0) {
            courses = courses.filter(c => this.state.types_filter.includes(types[c.course_type]));
        };
        function array_move(arr, old_index, new_index) {
            if (new_index >= arr.length) {
                var k = new_index - arr.length + 1;
                while (k--) {
                    arr.push(undefined);
                }
            }
            arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
            return arr;
        };
        if(this.props.course_id && this.props.course_id.length > 0){
            let index_c = courses.findIndex(c => c.id === this.props.course_id);
            if (index_c > -1) {
                courses = array_move(courses, index_c, 0);
            };
        };
        setTimeout(function () {
            $(".course_number").html($(".scroll_box .course").length);
        }, 1000);
        return courses.map((course) => {
            let pp_data = (course.pp_data || { ratings: {} });
            let ranking_name = { country: course.country, region: "Region", world: "World", continent: "Continent" };
            let played = courses_played.includes(course.id);
            let bucket = courses_bucket.includes(course.id);
            let rating = course.rating;
            let rating_star = 0;
            let rating_star_half = false;
            if (rating > 3.2 && rating <= 3.7) { rating_star = 3; rating_star_half = true; };
            if (rating > 3.7 && rating <= 4.2) { rating_star = 4; };
            if (rating > 4.2 && rating <= 4.7) { rating_star = 4; rating_star_half = true; };
            if (rating > 4.7) { rating_star = 5; };
            function return_stars() {
                let stars = [];
                for (let i = 0; i < rating_star; i++) { stars.push(<img src='/icons/rating_star.png' />) };
                return stars;
            };
            return (
                <div className="course" id={course.name} onMouseEnter={() => { this.courseHover(course) }} onMouseLeave={() => { this.courseHover({}) }}>
                    <div className="box">
                        <div className="name">{course.name}</div>
                        {((course.pp_data || {}).year_built || "").length > 0 || (course.pp_data || {}).year_built > 0 ? <div className="year_built"><span>Year Built {(course.pp_data || {}).year_built} {(course.course_type || "").length > 0 ? <span className="course_type"> | <span>{course.course_type}</span></span> : ""}</span></div> : ""}
                        {course.rating ? <div className="rating"><span>{course.rating}</span>{return_stars()}{rating_star_half ? <img src='/icons/rating_star_half.png' /> : ''}</div> : ""}
                        {course.rankings ? <div className="ranking">Golftus Rankings {(course.rankings || []).reverse().map((r) => {
                            return (
                                <span>{ranking_name[r.name] + ": " + r.rank}</span>
                            )
                        })}</div> : ""}
                        <div className="distance_city_centre"><img src="/distance.png" />{course.citydistance.toFixed(2) + " km [" + (Number(course.citydistance) / 1.61).toFixed(2) + " miles] to " + this.props.cityName + " City Centre"}</div>
                        <div className="address"><img className="icon" src="/icons/placeholder.png" />{course.address}</div>
                        {(pp_data || {}).architect ? <div className="course_data">
                            <div className="architect_names"><span className="h"><img className="icon" src="/icons/architect.png" />Architect:</span> {pp_data.architect.map((d, index) => <span>{d.name} {index < pp_data.architect.length - 1 ? ", " : ""}</span>)}</div>
                        </div> : ""}
                        <div className="course_data">
                            {pp_data.holes ? <div className="data_c">Holes <div>{pp_data.holes}</div></div> : ""}
                            {pp_data.par ? <div className="data_c">Par <div>{pp_data.par}</div></div> : ""}
                            {pp_data.course_length_yards ? <div className="data_c">Length <div>{pp_data.course_length_yards}</div></div> : ""}
                            {pp_data.course_slope ? <div className="data_c">Slope <div>{pp_data.course_slope}</div></div> : ""}
                            {pp_data.course_rating ? <div className="data_c">Rating <div>{pp_data.course_rating}</div></div> : ""}
                        </div>
                        {course.y_videos ?
                            <div className="course_videos" onClick={() => { this.selectCourse(course); this.showFormButton("showVideos", true); }}>
                                <img class="play_button" src="/play2.png" />Videos({course.y_videos.length})
                            </div>
                            : ""}
                        {course.tus_desc ?
                            <div className="tus_desc">
                                {course.tus_desc}
                            </div>
                            : ""}
                        <div className="website_social_media">
                            {(course.website || "").length > 0 ? <div className="website"><a target="_blank" href={course.website}><img src='/icons/worldwideweb.png'></img></a></div> : ""}
                            {(course.facebook || "").length > 0 ? <div className="facebook"><a target="_blank" href={course.facebook}><img src='https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/Facebook_icon_2013.svg/640px-Facebook_icon_2013.svg.png'></img></a></div> : ""}
                            <BucketPlayedButtonCourse reload={this.props.reload} course_id={course.id} logged={this.props.logged} played={played} bucket={bucket} cityName={this.props.cityName} />
                        </div>
                    </div>
                </div>
            );
        });
    }
    renderCoursesMap() {
        let courses = this.state.courses;
        let filter = this.state.filter;
        let search_course = this.state.search_course;
        if (search_course.length > 0) {
            courses = courses.filter((c) =>
                c.name.toLowerCase().includes(search_course.toLowerCase()) ||
                c.address.toLowerCase().includes(search_course.toLowerCase()) ||
                c.short_desc.toLowerCase().includes(search_course.toLowerCase())

            );
        };
        courses = courses.filter((c) => Number(c.citydistance) < (filter.distance + 5));
        if (filter.ranking.length > 0) {
            courses = courses.filter((c) => c.rankings.findIndex((r) => r.name === filter.ranking) > -1);
        };
        ["holes", "par", "course_length_yards", "year_built"].map((f) => {
            if (filter[f]) {
                courses = courses.filter((c) => c.pp_data[f] > filter[f][0] && c.pp_data[f] <= filter[f][1])
            };
        });
        {/*["clubs_rental"].map((f) => {
            if (filter[f]) {
                courses = courses.filter((c) => c.pp_data[f] > filter[f][0] && c.pp_data[f] <= filter[f][1])
            };
        });*/}
        if (filter["high_ratings"]) {
            courses = courses.filter((c) => Number(c.rating) > 4.5);
        };
        let courses_played = (this.props.user_data.played_courses || []);
        let courses_bucket = (this.props.user_data.bucket_courses || []);
        if (this.state.architect_filter.length > 0) {
            courses = courses.filter(c => {
                return (c.pp_data || { architect: [] }).architect.some(c2 => {
                    return this.state.architect_filter.includes(c2.name);
                });
            });
        };
        if (this.state.types_filter.length > 0) {
            courses = courses.filter(c => this.state.types_filter.includes(types[c.course_type]));
        };
        return courses.map((course) => {
            let pos_city = course.geolocation.geo.split(",");
            let pos_city_num = [Number(pos_city[0]), Number(pos_city[1])];
            let icon = new L.Icon.Default;
            icon.options.className = 'leaflet-marker-icon course';
            return (
                <Marker position={pos_city_num} alt={course.url} icon={icon} eventHandlers={{
                    click: (e) => {
                        this.marker(e, course);
                    },
                }}>
                    <Popup>
                        {course.name}
                    </Popup>
                </Marker>
            );
        });
    }
    ranking_Numbers(rank) {
        let courses = this.state.courses;
        let filter = this.state.filter;
        courses = courses.filter((c) => Number(c.citydistance) < (filter.distance + 5));
        courses = courses.filter((c) => (c.rankings || []).findIndex((r) => r.name === rank) > -1);
        return (courses.length);
    }
    marker(e) {
    }
    courseHover(course) {
        $(".leaflet-marker-icon").each(function () {
            $(this).removeClass("hover");
        });
        $("img.leaflet-marker-icon[alt='" + course.url + "']").addClass("hover");
    }
    setFilter(filt, value) {
        let filter = this.state.filter;
        if (filter[filt] === value && filt !== "distance") {
            filter[filt] = ""
        } else {
            filter[filt] = value;
        };
        this.setState({ filter: filter });
    }
    changeYear(name, value) {
        if (name === "year_min") {
            this.setState({ year_min: value });
            this.setFilter("year_built", [value, this.state.year_max]);
        };
        if (name === "year_max") {
            this.setState({ year_max: value });
            this.setFilter("year_built", [this.state.year_min, value]);
        };
    }
    changeMapSelect(value) {
        this.setState({ list_map_select: value });
    }
    selectCourse(course) {
        this.setState({ selected_course: course });
    }
    playVideo(video) {
        this.setState({ selected_video: video });
        this.showFormButton("playVideo", true);
    }
    render() {
        let courses = this.state.courses;
        let pos_city = this.state.citiesgeo.split(",");
        let pos_city_num = [Number(pos_city[0]), Number(pos_city[1])];
        return (
            <div className="city_golf_courses">
                <div className="golf_filter mobile_close">
                    <div className="filter_buttons">
                        <h3>Filter </h3>
                        <div className="filter_settings">
                            <div className="filter_box filter_misc">
                                <div className="filter_tit">Course Detail</div>
                                <div id="select_architect" >
                                    <div>Architect <i className="fa fa-caret-down" aria-hidden="true"></i></div>
                                    <div className="architects_list">
                                        <div className="list">
                                            {this.state.city_architects.map(architect => {
                                                return (
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked={this.state.architect_filter.includes(architect)} onChange={() => this.architect_filter(architect)}></input>
                                                        <label className="form-check-label" for="flexCheckChecked">
                                                            {architect}
                                                        </label>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                                {/*<div className="year_built_a">
                                    <div className="label">Year Built</div>
                                    <input type="number" min={1850} max={2023} value={this.state.year_min} onChange={(e) => this.changeYear("year_min", e.target.value)} /><span>-</span>
                                    <input type="number" min={1850} max={2023} value={this.state.year_max} onChange={(e) => this.changeYear("year_max", e.target.value)} />
                                </div>*/}
                                <RangeSlider onChange={(e) => this.setFilter("year_built", e)} min={1850} max={2023} step={1} gap={10} label="Year Built" name="year_built" width="170px" />
                                <RangeSlider onChange={(e) => this.setFilter("course_length_yards", e)} min={0} max={12000} step={100} gap={30} label="Length in Yards" name="course_length_yards" width="170px" />
                                {/*<RangeSlider onChange={(e)=> this.setFilter("holes",e)} min={0} max={80} step={1} gap={1} label="Holes" name="holes" />*/}
                                <RangeSlider onChange={(e) => this.setFilter("par", e)} min={0} max={120} step={1} gap={1} label="Par" name="par" width="170px" />
                                <RangeSlider onChange={(e) => this.setFilter("tee_costs", e)} min={0} max={1200} step={1} gap={1} label="Tee Price $ (avg.)" name="tee_costs" width="180px" />
                                <div className={"overall_rating f_button " + (this.state.filter.high_ratings === true ? "selected" : "")} onClick={() => { this.setFilter("high_ratings", true) }}><img className="image_icon" src="/icons/stern.png" />High Overall Ratings {"> 4.5"}</div>
                                {/*<div className={"f_button " + (this.state.filter.public === true ? "selected" : "")} onClick={() => { this.setFilter("public", true) }} ><img className="image_icon" src="/icons/double-door.png" />Only Public Courses</div>*/}
                                <div className={"f_button " + (this.state.filter.club_rental === true ? "selected" : "")} onClick={() => { this.setFilter("club_rental", true) }} ><img className="image_icon" src="/icons/golf-clubs.png" />Club Rental available</div>
                                <div className="course_types_filter">
                                    <div className="filter_tit">Course Type</div>
                                    <div>
                                        <div class="form-check">
                                            <input className="form-check-input" type="checkbox" value="" checked={this.state.types_filter.includes("Public")} onChange={() => this.types_filter("Public")} id="flexCheckChecked" />
                                            <label className="form-check-label" for="flexCheckChecked">
                                                Public
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input className="form-check-input" type="checkbox" value="" checked={this.state.types_filter.includes("Private")} onChange={() => this.types_filter("Private")} id="flexCheckChecked" />
                                            <label className="form-check-label" for="flexCheckChecked">
                                                Private
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input className="form-check-input" type="checkbox" value="" checked={this.state.types_filter.includes("Semi-Private")} onChange={() => this.types_filter("Semi-Private")} id="flexCheckChecked" />
                                            <label className="form-check-label" for="flexCheckChecked">
                                                Semi-Private
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input className="form-check-input" type="checkbox" value="" checked={this.state.types_filter.includes("Military")} onChange={() => this.types_filter("Military")} id="flexCheckChecked" />
                                            <label className="form-check-label" for="flexCheckChecked">
                                                Military
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="filter_box course_distance">
                                <div className="filter_tit">Distance to City Centre</div>
                                <div className={"f_button " + (this.state.filter.distance === 25 ? "selected" : "")} onClick={() => { this.setFilter("distance", 25) }}>{"< 25km"}</div>
                                <div className={"f_button " + (this.state.filter.distance === 50 ? "selected" : "")} onClick={() => { this.setFilter("distance", 50) }}>{"< 50km"}</div>
                                <div className={"f_button " + (this.state.filter.distance === 100 ? "selected" : "")} onClick={() => { this.setFilter("distance", 100) }}>{"< 100km"}</div>
                                <div className="filter_box top_rankings">
                                    <div className="filter_tit">Top Golf Course Rankings</div>
                                    <div className={"f_button " + (this.state.filter.ranking === "world" ? "selected" : "")} onClick={() => { this.setFilter("ranking", "world") }}><img class="icon" src="/icons/earth2.png" />Golftus World Top 50 ({this.ranking_Numbers("world")})</div>
                                    <div className={"f_button " + (this.state.filter.ranking === "continent" ? "selected" : "")} onClick={() => { this.setFilter("ranking", "continent") }}><img class="icon" src="/icons/europe2.png" />Golftus Continent Top 50 ({this.ranking_Numbers("continent")})</div>
                                    <div className={"f_button " + (this.state.filter.ranking === "country" ? "selected" : "")} onClick={() => { this.setFilter("ranking", "country") }}><img class="icon" src="/icons/UK.png" />Golftus Country Top 50 ({this.ranking_Numbers("country")})</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="more_filters_button" onClick={() => { $(".golf_filter").removeClass("mobile_close") }}>Show more Filters</div>
                </div>
                <div className={"courses_list " + (!this.state.data_loaded ? "data_loading" : "")}>
                    <h2> Golf Courses {courses.length > 0 ? <span>(<img className="image_icon" src="/icons/golf.png" /><span className="course_number"></span>)</span> : ""}</h2>
                    <div className="filter_box">
                        <div className="filter_search">
                            <input placeholder="Search Course (by Name, Address, Architect)" />
                            <div className="input_plus betaND">+</div>
                        </div>
                    </div>
                    {courses.length < 1 ? <div className='no_courses_text'>!There is no club listed for this city</div> : ""}
                    <div className="list_map_select">
                        <div className={"select_t " + (!this.state.list_map_select ? "selected" : "")} onClick={() => this.changeMapSelect(false)}><img src="/icons/list_a.png" /></div>
                        <div className={"select_t " + (this.state.list_map_select ? "selected" : "")} onClick={() => this.changeMapSelect(true)}><img src="/icons/map.png" /></div>
                    </div>
                    {this.state.list_map_select ? <div className="map mobile_map">
                        {this.state.citiesgeo.length > 0 ?
                            <MapContainer center={pos_city_num} zoom={8} scrollWheelZoom={false}>
                                <TileLayer
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                <Marker position={pos_city_num} alt="center" eventHandlers={{
                                    click: (e) => {
                                        this.marker(e);
                                    },
                                }}>
                                    <Popup>
                                        {this.props.cityName}
                                    </Popup>
                                </Marker>
                                {this.renderCoursesMap()}
                            </MapContainer> : ""}
                    </div> : ""}
                    <div className="scroll_box" >
                        <div className="loading_svg">
                            <LoadingSVG />
                        </div>
                        {this.renderCourses()}
                    </div>
                </div>
                {!isMobile ? <div className="map">
                    {this.state.citiesgeo.length > 0 ?
                        <MapContainer center={pos_city_num} zoom={8} scrollWheelZoom={false} >
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <Marker position={pos_city_num} alt="center" eventHandlers={{
                                click: (e) => {
                                    this.marker(e);
                                },
                            }}>
                                <Popup>
                                    {this.props.cityName}
                                </Popup>
                            </Marker>
                            {this.renderCoursesMap()}
                        </MapContainer> : ""}
                </div> : ""}
                <ModalBoot show={this.state.showVideos} hide={this.showFormButton} content={"showVideos"} className="courseVideosModal">
                    <div key={this.state.selected_course}>
                        <h2>{this.state.selected_course.name}</h2>
                        <div className="course_videos_list">
                            {this.state.selected_course.y_videos.map(y => {
                                return (
                                    <div className="youtube_single" >
                                        <div className="box">
                                            <div className="youtube_video" onClick={() => this.playVideo(y)}>
                                                <img className="thumb" src={"https://i.ytimg.com/vi/" + y.y_id + "/hqdefault.jpg"} />
                                                <img className="play_button" src="/play2.png" />
                                            </div>
                                            <div className="title">{y.title}</div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <ModalBoot show={this.state.playVideo} hide={this.showFormButton} content={"playVideo"} className="modal_youtubeVideo">
                        <iframe className="iframe_youtube" width={isMobile ? "100%" : "800"} height={isMobile ? "auto" : "800"}
                            src={"https://www.youtube.com/embed/" + this.state.selected_video.y_id + "?autoplay=1"} allow="autoplay; encrypted-media allowfullscreen">
                        </iframe>
                    </ModalBoot >
                </ModalBoot >
            </div>
        )
    }
}    