import React, { Component } from 'react';
import axios from 'axios';
import { Container, Row, Col, Button, DropdownButton, Dropdown, Form } from 'react-bootstrap';
import { Link } from "react-router-dom";
import ModalBoot from '../components/Modal';
import localhostName from "../config.json";
import BucketButtonModal from './BucketButtonModal';
import CityOverview from './CityOverview';
import LoadingSVG from './LoadingSVG';
import LoginModal from './LoginModal';
import MembershipFAQ from './MembershipFAQ';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-tooltip/dist/react-tooltip.css';
import { city_list_filter } from './List/functions.js';
import Filter from './List/Filter';
import { setCookie, getCookie, delete_cookie } from '../functions/functions.js';
import $ from 'jquery';
import Article from './Article';
import masters from "./Data/masters.json";
import website_info from "./Data/website.json";


export default class SwingCities extends Component {
    constructor(props) {
        super(props);
        this.state = {
            language: "de",
            cities_list: [],
            cities_filter: [],
            userInfo: this.props.userInfo,
            selected_city: {},
            show_filters: true,
            weather_filter: "",
            weather_month: (new Date).getMonth() + 1,
            continent_filter: [],
            pop_filter: "",
            gol_cont_filter: "",
            search_city: "",
            sort_by: "",
            filter_country: "",
            not_rainy: false,
            sunbathing: false,
            data_loaded: false,
            filter: [],
            filter_values: {},
            slider_filter: {},
            logged: false,
            tracker: {
                cities: [],
                tabs: [],
                filter: [],
                items: []
            },
            tracker_daily: {
                cities: [],
                tabs: [],
                filter: [],
                items: []
            },
            visit_tracker: {
                cities: [],
                tabs: [],
                filter: [],
                pages: [],
                items: []
            },
            tracker_stop: false,
            tracker_stop_daily: false,
            block: false,
            user: {}
        };
        this.showFormButton = this.showFormButton.bind(this);
        this.showFormButtonItem = this.showFormButtonItem.bind(this);
        this.selectCity = this.selectCity.bind(this);
        this.changeState = this.changeState.bind(this);
        this.onChange = this.onChange.bind(this);
        this.tracker = this.tracker.bind(this);
        this.setState = this.setState.bind(this);
    }
    onChange(e) {
        this.setState({ [e.target.id]: e.target.value });
    }
    checkLogged() {
        let tracker_stop = this.state.tracker_stop;
        let tracker_stop_daily = this.state.tracker_stop_daily;
        let block = this.props.block;
        axios.get(localhostName.host + '/api/users/logged', { withCredentials: true })
            .then(res => {
                if (res.data.status) {
                    this.setState({ user: res.data.user, logged: true });
                } else {
                    this.setState({ logged: false, user: {} });
                    if (tracker_stop && tracker_stop_daily) {
                        block()
                    };
                }
            })
            .catch(function (error) {
                console.log(error);
                if (tracker_stop && tracker_stop_daily) {
                    block()
                };
            });
    }
    notLoggedModal(type, text) {
        if (type === "filter") {
            text = "<span>!</span>You have to be logged in to use this filter."
        };
        if (this.state.logged) {
        } else {
            $(".BucketButtonModal").addClass("show");
            $(".must_logged").html(text);
        };
    }
    tracker(type, value) {
        let tracker = (this.state.tracker || {
            cities: [],
            tabs: [],
            filter: [],
            items: []
        });
        let tracker_daily = this.state.tracker_daily;
        if (type === "city" && !(tracker.cities.includes(value))) {
            tracker.cities.push(value);
            tracker_daily.cities.push(value);
        };
        if (type === "tab" && !(tracker.tabs.includes(value))) {
            tracker.tabs.push(value);
            tracker_daily.tabs.push(value);
        };
        if (type === "filter" && !(tracker.filter.includes(value))) {
            tracker.filter.push(value);
            tracker_daily.filter.push(value);
        };
        this.setState({ tracker, tracker_daily });
        if (tracker.cities.length > 8 && tracker.tabs.length > 3 && tracker.filter.length > 8 && !this.state.logged) {
            this.setState({ tracker_stop: true });
            setCookie("tracker_stop", true, 365);
            this.checkLogged();
        };
        if (tracker.cities.length > 14 &&  tracker.tabs.length > 3) {
            this.setState({ tracker_stop: true });
            setCookie("tracker_stop", true, 365);
            this.checkLogged();
        };
        if (tracker.items.length > 10 &&  tracker.tabs.length > 3) {
            this.setState({ tracker_stop: true });
            setCookie("tracker_stop", true, 365);
            this.checkLogged();
        };
        if (tracker.cities.length > 30) {
            this.setState({ tracker_stop: true });
            setCookie("tracker_stop", true, 365);
            this.checkLogged();
        };
        if (tracker.items.length > 20) {
            this.setState({ tracker_stop: true });
            setCookie("tracker_stop", true, 365);
            this.checkLogged();
        };
        if (tracker.cities.length > 7 && tracker.items.length > 7 && tracker.tabs.length > 3) {
            this.setState({ tracker_stop: true });
            setCookie("tracker_stop", true, 365);
            this.checkLogged();
        };
        if (tracker.cities.length > 20 && tracker.filter.length > 8 && !this.state.logged) {
            this.setState({ tracker_stop: true });
            setCookie("tracker_stop", true, 365);
            this.checkLogged();
        };
        if ((tracker_daily.cities.length > 1 || tracker_daily.cities.length > 1) && tracker.tabs.length > 1 && !this.state.logged) {
            this.setState({ tracker_stop_daily: true });
            setCookie("tracker_stop_daily", true, 1);
            this.checkLogged();
        };
        setCookie("tracker", JSON.stringify(tracker), 365);
        setCookie("tracker_daily", JSON.stringify(tracker_daily), 1);
    }
    componentDidMount() {
        let url = new URL(window.location.href);
        let selected_city = url.searchParams.get("city");
        let selected_tab = url.searchParams.get("tab");
        let selected_course_id = url.searchParams.get("c");
        let pay_suc = url.searchParams.get("success");
        if (pay_suc === "basic") {
            this.showFormButton("showPaySuc", true);
        };
        let tracker = getCookie("tracker");
        let tracker_stop = getCookie("tracker_stop");
        let tracker_stop_daily = getCookie("tracker_stop_daily");
        let visit_tracker = getCookie("visit_tracker");
        this.setState({ tracker_stop, tracker_stop_daily, tracker: JSON.parse(tracker) });
        if (visit_tracker) {
            this.setState({ visit_tracker: JSON.parse(visit_tracker) });
        };
        $(document).click(function (event) {
            var $target = $(event.target);
            if (!$target.closest('.navbar_box').length) {
                if (!$target.closest('.header_navbar .more').length) {
                    $('.navbar_box').removeClass("show_navbar");
                } else {
                    if ($('.navbar_box.show_navbar').length > 0) {
                        $('.navbar_box').removeClass("show_navbar");
                    } else {
                        $('.navbar_box').addClass("show_navbar");
                    };
                }
            }
        });
        $(window).scroll(function () {
            if ($(window).scrollTop() > ($(".viewTrap").offset().top)) {
                $(".page_content").addClass("viewActive");
            } else if ($(window).scrollTop() < ($(".viewTrap").offset().top)) {
                $(".page_content").removeClass("viewActive");
            };
        });
        axios.get(localhostName.host + '/startdata')
            .then(res => {
                let cities_list = JSON.parse(res.data).cities_list;
                cities_list = cities_list.sort((a, b) => {
                    const nameA = a.name.toLowerCase();
                    const nameB = b.name.toLowerCase();

                    if (nameA < nameB) return -1;
                    if (nameA > nameB) return 1;
                    return 0;
                });
                this.setState({ data_loaded: true, cities_list: cities_list });
                if (selected_city && selected_city.length > 2) {
                    let s_city = cities_list.filter((c) => c.name == selected_city)[0];
                    this.selectCity(s_city);
                    this.showFormButton("showCityOverview", true, s_city.name, selected_tab, selected_course_id);
                };
            })
            .catch(function (error) {
                console.log(error);
            });
        this.checkLogged();
        this.handlePopState = () => {
            window.location.href = '/';
        };
        window.addEventListener('popstate', this.handlePopState);
    }
    updateContents() {
        axios.get(localhostName.host + '/api/cities_list')
            .then(res => {
                if (res.data.length > 0) {
                    this.setState({ cities_list: res.data });
                };
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    showFormButton(targetShow, change, city, tab, c) {
        if (this.state.logged && !this.props.basic_payment) {
            window.location.replace("/join?basic_payment=not-completed");
            return;
        };
        if (targetShow === "showCityOverview") {
            if (!this.props.blocked || this.props.basic_payment) {
                this.setState({ [targetShow]: change });
            } else {
                window.location.replace("/join");
                return;
            };
        } else {
            this.setState({ [targetShow]: change });
        };
        if (targetShow === "showCityOverview" && change === false) {
            window.history.replaceState(null, null, window.location.pathname);
        };
        if (targetShow === "showCityOverview" && change === true) {
            window.history.replaceState(null, null, "/?city=" + city + (tab ? ("&tab=" + tab) : "") + (c ? ("&c=" + c) : ""));
        };
    }
    showFormButtonItem(targetShow, change, parent) {
        this.setState({ [targetShow]: change, parent: parent });
    }
    selectCity(city) {
        this.setState({ selected_city: city });
    }
    changeState(state, value) {
        this.setState({ [state]: value });
        axios.get(localhostName.host + '/api/users/logged', { withCredentials: true })
            .then(res => {
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    sort_by(value) {
        this.setState({ sort_by: value });
    }
    search_city(e) {
        this.setState({ search_city: e.target.value })
    }
    visit_tracker(type, value) {
        let visit_tracker = this.state.visit_tracker;
        let types = { cities: "city", tabs: "tab", filter: "filter", pages: "page" };
        if (!(visit_tracker[type].includes(value))) {
            visit_tracker[type].push(value);
            this.setState({ visit_tracker: visit_tracker });
            setCookie("visit_tracker", JSON.stringify(visit_tracker), 365);
            axios.post(localhostName.host + '/api/pagetrackers/add', { type: types[type], [types[type]]: value })
                .then(res => {
                })
                .catch((error) => {
                });
        };
    }
    renderCities() {
        let Cities = (city_list_filter(this.state) || []);
        let score_rate = function (value) {
            let score_rate = (value / 5 * 100);
            return score_rate;
        };
        let counter = 0;
        let countries = {
            UK: "United Kingdom", DE: "Germany", ES: "Spain", PT: "Portugal", FR: "France", IE: "Ireland", IT: "Italy", TR: "Turkey", NL: "Netherlands",
            US: "USA", MX: "Mexico", CA: "Canada", AU: "Australia", NZ: "New Zealand", JP: "Japan", CR: "Costa Rica", DO: "Dominican Republic", TH: "Thailand", CO: "Colombia", KR: "South Korea", VN: "Vietnam", BR: "Brazil", AR: "Argentina", CH: "Switzerland", AT: "Austria", ZA: "South Africa", JM: "Jamaica", IS: "Iceland", SE: "Sweden", TW: "Taiwan", NO: "Norway", IN: "New Delhi", BS: "Bahamas", KE: "Kenya", MU: "Mauritius", PHL: "Philippines", PE: "Peru", MY: "Malaysia", AE: "UAE", CN: "China", BE: "Belgium", HU: "Hungary", GR: "Greece", GI: "Gibraltar", CZ: "Czech Republic", DK: "Denmark", HK: "Hong Kong", SG: "Singapore", ID: "Indonesia", NP: "Nepal", QA: "Qatar", MT: "Malta", MC: "Monaco", KY: "Cayman Islands", CL: "Chile", NA: "Namibia", GH: "Ghana"
        };
        let score_color = function (value) {
            let score_rate = (value / 5 * 100);
            if (score_rate > 85) {
                return "#2bde73";
            } else if (score_rate > 65) {
                return "#2bde73";
            } else if (score_rate > 45) {
                return "#ffc924";
            } else if (score_rate > 30) {
                return "#ff4742";
            }
            else {
                return "#ff4742";
            }
        };
        if (Cities.length < 1) {
            return (
                <h1 className="no_found">No Cities found.</h1>
            )
        };
        return Cities.map((city) => {
            counter += 1;
            return (
                <div className="city_box" >
                    <div className="city_box_card" data-city={city.name} onClick={() => { this.showFormButton("showCityOverview", true, city.name); this.selectCity(city); this.tracker("city", city.name); this.visit_tracker("cities", city.name); }} >
                        <div className="overlay">
                            <LazyLoadImage
                                threshold={400}
                                src={'./cities_thumbs/' + (typeof city.name === 'string' ? (city.name).replace(/ /g, "%20").replace(/\)/g,"%29").replace(/\(/g,"%28") : "Default") + '.jpg'} />
                        </div>
                        <div className="overlay"></div>
                        <div className="top_row">
                            <div className="rank">{counter}</div>
                            <div className="total_score"><i className="fa fa-cubes"></i> {city.short_scores.total > 5 ? 5.00 : city.short_scores.total}</div>
                        </div>
                        <div className="city_location">
                            <div className="city_name">{city.name}</div>
                            <div className="city_state">{(city.country_sub || "").length > 0 ? city.country_sub : countries[city.country]}</div>
                        </div>
                        <div className="city_card_bottom">
                            {/*<div className="city_population">
                                1,5 Mio.
                            </div>*/}
                            <div className="monthly_costs">
                                {"$" + city["3nights_2person"]} <br /> <span>3 Nights 2 Persons</span>
                            </div>
                        </div>
                        <div className="city_scores">
                            <div className="city_scores_inner">
                                <div className="city_scores_bars">
                                    <div className="single_score">
                                        <p className="score_desc"><img className="image_icon" src="/icons/total.png" />Total</p>
                                        <div className="score_bar">
                                            <div className="score_bar_scale" style={{ width: String(score_rate(city.short_scores.total) + "%"), background: score_color(city.short_scores.total) }}>{city.short_scores.total > 5 ? 5.00 : city.short_scores.total}</div>
                                        </div>
                                    </div>
                                    <div className="single_score">
                                        <p className="score_desc"><img className="image_icon" src="/icons/golf-field.png" />Golf</p>
                                        <div className="score_bar">
                                            <div className="score_bar_scale" style={{ width: String(score_rate(city.short_scores.golf_total) + "%"), background: score_color(city.short_scores.golf_total) }}>{city.short_scores.golf_total > 5 ? 5.00 : city.short_scores.golf_total}</div>
                                        </div>
                                    </div>
                                    <div className="single_score">
                                        <p className="score_desc"><img className="image_icon" src="/icons/historic_architecture.png" />Sightseeing</p>
                                        <div className="score_bar">
                                            <div className="score_bar_scale" style={{ width: String(score_rate(city.short_scores.sightseeing) + "%"), background: score_color(city.short_scores.sightseeing) }}>{city.short_scores.sightseeing > 5 ? 5.00 : city.short_scores.sightseeing}</div>
                                        </div>
                                    </div>
                                    <div className="single_score">
                                        <p className="score_desc"><img className="image_icon" src="/icons/expenses.png" />Cost</p>
                                        <div className="score_bar">
                                            <div className="score_bar_scale" style={{ width: String(score_rate(city.short_scores.costs) + "%"), background: score_color(city.short_scores.costs) }}>{city.short_scores.costs > 5 ? 5.00 : city.short_scores.costs}</div>
                                        </div>
                                    </div>
                                    <div className="single_score betaND">
                                        <p className="score_desc">🍸 Fun</p>
                                        <div className="score_bar">
                                            <div className="score_bar_scale" style={{ width: String(score_rate(city, "nightlife") + "%"), background: score_color(city, "nightlife") }}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
    }
    render() {
        return (
            <div className="cities_page desktop">
                <div className="cities_page_header">
                    <video width="400" className="header_video" autoPlay muted loop>
                        <source src="/intro.mp4" type="video/mp4" />
                    </video>
                    <svg viewBox="0 0 1440 120" className="wave" ><path d="M1440,21.2101911 L1440,120 L0,120 L0,21.2101911 C120,35.0700637 240,42 360,42 C480,42 600,35.0700637 720,21.2101911 C808.32779,12.416393 874.573633,6.87702029 918.737528,4.59207306 C972.491685,1.8109458 1026.24584,0.420382166 1080,0.420382166 C1200,0.420382166 1320,7.35031847 1440,21.2101911 Z"></path></svg>
                    <div className="header_navbar">
                        <Link to="/"><div className="logo_name">Golftus</div></Link>
                        <div className="links_navbar">
                            <Link to="/"><div className="nav_tab">Cities</div></Link>
                            <Link to="/membership"><div className="nav_tab">Membership</div></Link>
                            <Link to="/faq"><div className="nav_tab betaND">FAQ</div></Link>
                            <a class="dropdown">
                                <div className="nav_tab">
                                    {website_info.more_nav}
                                </div>
                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                    <div className="techniques">
                                        <div className="techniques_nav">Improve your game</div>
                                        {(website_info.technique_pages || []).map(p => {
                                            return (<div class="dropdown-item" onClick={() => { window.location.href = p.url; }} ><img className="nav_icon" src={"/icons/" + p.icon} />{p.name}</div>)
                                        })}
                                    </div>
                                    <div>
                                        <Link class="dropdown-item" to="/aboutus"><img className="nav_icon" src="/icons/group.png" />About us</Link>
                                        <Link class="dropdown-item" to="/contact"><img className="nav_icon" src="/icons/letter.png" />Contact</Link>
                                        <Link class="dropdown-item" to="/terms-and-conditions"><img className="nav_icon" src="/icons/terms-and-conditions.png" />Ts&Cs</Link>
                                        <Link class="dropdown-item" to="/privacy-policy"><img className="nav_icon" src="/icons/privacy-policy.png" />Privacy & Cookies</Link>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="nav_tab login">
                            {this.state.logged ?
                                <div className="user_log"><Link to={"/profile#user"}><img className="" src="/icons/userA.png" /><span>{this.state.user.firstname} {this.state.user.lastname.charAt(0)}.</span></Link></div>
                                :
                                <button onClick={() => this.showFormButton("showLogin", true)}>Login</button>
                            }
                        </div>
                    </div>
                    <div className="header_content">
                        <div className="header_title">
                            <div className="title_top">Discover the Best Travel Cities for Golf <img class="golf_logo" src="/golf_logo.png" /></div>
                            <div className="title_sub">Join a global community of golf players traveling around the world</div>
                        </div>
                        {this.state.logged ?
                            ""
                            :
                            <div className="header_register_box">
                                <div className="box">
                                    <div className="register_image">
                                        <img className="cover" src="/golf-front.jpg" />
                                        {/*<img className="play_button" src="https://www.freeiconspng.com/thumbs/play-button-icon-png/play-button-icon-png-0.png" />*/}
                                    </div>
                                    <div className="member_text"><span className="become_golftuser">Become a Golftuser</span><br />Lifetime membership now for just $49. <span onClick={() => this.showFormButton("membership_faq", true)}>+learn more</span></div>
                                    {/*<input type="email" id="register_email" onChange={(e) => {this.onChange(e);this.setCookie("register_email",e.target.value,1)}} value={this.state.register_email} placeholder="Type your email..." />*/}
                                    <Link to="/join" state={{ register_email: "this.state.register_email" }}><button>Proceed to registration</button></Link>
                                    <div className="already_login">Already a member? <span onClick={() => this.showFormButton("showLogin", true)}>Log in</span></div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <Article />
                <div className="page_content">
                    <div className="viewTrap"></div>
                    {this.state.show_filters ?
                        <Filter setState={this.setState} state={this.state} notLoggedModal={this.notLoggedModal} tracker={this.tracker} visit_tracker={this.visit_tracker} />
                        : ""}
                    <Container className={"container_cities" + (!this.state.show_filters ? " none_filters" : "")} >
                        <div className="content_topbar">
                            <div className="filter_button">
                                {!this.state.show_filters ? <button className="show_filter" onClick={() => { this.changeState("show_filters", true) }}>Filters</button> : ""}
                                {this.state.show_filters ? <button className="hide_filter" onClick={() => { this.changeState("show_filters", false) }}>Close Filters</button> : ""}
                            </div>
                            <div className="filter_search">
                                <input placeholder="Search City" onChange={(e) => { this.search_city(e) }} value={this.state.search_city} />
                                <div className="input_plus betaND">+</div>
                            </div>
                            <div className="sort_by">
                                <select value={this.state.sort_by} onChange={(e) => this.sort_by(e.target.value)}>
                                    <optgroup>
                                        <option>Sort</option>
                                        <option value="total">Scores</option>
                                        <option value="name">Names</option>
                                        <option value="golf_total">Golf</option>
                                        <option value="sightseeing">Sightseeing</option>
                                        <option value="costs">Cost</option>
                                    </optgroup>
                                    <optgroup label="Golf" className="betaND">
                                        <option>Number Golf Courses</option>
                                        <option>Total Golf Course Fields Area</option>
                                        <option>Golf Events</option>
                                    </optgroup>
                                    <optgroup label="Cost" className="betaND">
                                        <option>Cheap Hotels</option>
                                        <option>Cheap Airbnbs</option>
                                        <option>Cheap Nightlife</option>
                                        <option>Cheap Restaurants</option>
                                    </optgroup>
                                </select>
                            </div>
                        </div>
                        <div className="">
                            {!this.state.logged ? <div className="more_info_text">Click on the city to uncover more information about the city</div> : ""}
                            <div className={"cities_sorted " + (!this.state.data_loaded ? "data_loading" : "")}>
                                <div className="loading_svg">
                                    <LoadingSVG />
                                </div>
                                {this.renderCities()}
                            </div>
                        </div>
                        <ModalBoot show={this.state.showCityOverview} hide={this.showFormButton} content={"showCityOverview"} className="modal_CityOverview">
                            <CityOverview selected_city={this.state.selected_city} city_nearest={this.props.city_nearest} tracker={this.tracker} cities_list={this.state.cities_list} />
                        </ModalBoot >
                        <ModalBoot show={this.state.showPaySuc} hide={this.showFormButton} content={"showPaySuc"} className="modal_Login modal_success">
                            <h1>Congratulations!</h1>
                            <div>Your payment has been confirmed, and you are now a Basic Golftus Member for life. This entitles you to full and unrestricted access to all city data, 24 hours a day, 7 days a week.</div>
                            <button class="btn btn-success btn-block float-right" onClick={() => {
                                this.showFormButton("showPaySuc", false);
                                window.history.pushState({}, document.title, "/");
                            }}> Thanks</button>
                        </ModalBoot >
                        <ModalBoot show={this.state.membership_faq} hide={this.showFormButton} content={"membership_faq"} className="membership_terms_condition">
                            <MembershipFAQ />
                        </ModalBoot >
                    </Container>
                </div>
                <ModalBoot show={this.state.showLogin} hide={this.showFormButton} content={"showLogin"} className="modal_Login">
                    <LoginModal checkLogged={this.checkLogged} hide={this.showFormButton} />
                </ModalBoot >
                <BucketButtonModal checkLogged={this.checkLogged} hide={this.showFormButton} />
            </div>
        )

    }
}    