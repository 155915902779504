import React, { Component } from 'react';
import axios from 'axios';
import localhostName from "../config.json";
import { Link } from "react-router-dom";
import $ from 'jquery';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { setCookie, getCookie, delete_cookie } from '../functions/functions.js';
let categories = [{ "name": "Golf Resort & Hotel", "value": "Hotel" }, { "name": "Golf Store", "value": "Golf" }, { "name": "Aesthetic Surgery", "value": "Aesthetics" }, { "name": "Dental", "value": "Dental" }, { "name": "Experience", "value": "Experience" }, { "name": "Coaching & Consulting", "value": "Coaching" }, { "name": "Workshop", "value": "Workshop" }, { "name": "Wellness & Massage", "value": "Wellness" }, { "name": "Estate Agent", "value": "EstateAgent" }, { "name": "Bar/Pub & Cafes", "value": "Bar" }, { "name": "Restaurant", "value": "Restaurant" }, { "name": "Fashion", "value": "Fashion" }, { "name": "Antiques", "value": "Antiques" }, { "name": "Art Gallery", "value": "Art" }, { "name": "Jewelry", "value": "Jewelry" }, { "name": "Wine & Delicacies", "value": "Wine" }, { "name": "Entertainment", "value": "Entertainment" }];


export default class AdvertisePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            citiesdata: [],
            show_contact: false,
            country: (this.props.ip_country || ""),
            cat: "",
            city: "",
            checkout: false,
            checkout_step: "filling_data",
            price_cat: "",
            ad_tracker: {
                cities: [],
                tabs: [],
                filter: [],
                pages: []
            },
        };
    }
    componentDidMount() {
        let url = new URL(window.location.href);
        let page_city = url.searchParams.get("city");
        let page_cat = url.searchParams.get("cat");
        this.setState({ page_city });
        if (page_city === "London") {
            this.setState({ country: "UK", city: "London" });
        };
        if (page_cat) {
            this.setState({ cat: page_cat });
        };
        axios.get(localhostName.host + "/Data/citiesover/")
            .then(res => {
                let citiesdata = (Array.isArray(res.data) ? res.data : []);
                this.setState({ citiesdata });
            })
            .catch(function (error) {
                console.log(error);
            });
        $('.startvid_box').click(function () {
            $(".startvid_box").addClass("show");
            $('#startvid').get(0).play();
        });
        let ad_tracker = getCookie("ad_tracker");
        if (ad_tracker) {
            this.setState({ ad_tracker: JSON.parse(ad_tracker) });
        };
        this.ad_tracker();
        window.gtag('event', 'page_view', {
            page_title: 'Advertise Page',
            page_location: '/advertise'
        });
        {/*window.gtag('event', 'conversion', {
            'send_to': 'G-Y6X530FJ9R',
            'event_category': 'Button Click',
            'event_label': 'listing_form_email',
            'value': 1.0,  // Set the value of the conversion event
            'currency': 'USD'  // Set the currency of the value
          });*/}
    }
    change_Form(name, value) {
        this.setState({ [name]: value });
    }
    ad_tracker() {
        let ad_tracker = this.state.ad_tracker;
        let types = { cities: "city", tabs: "tab", filter: "filter", pages: "page" };
        let type = "pages";
        let value = window.location.pathname;
        if (!(ad_tracker[type].includes(value))) {
            ad_tracker[type].push(value);
            this.setState({ ad_tracker: ad_tracker });
            setCookie("ad_tracker", JSON.stringify(ad_tracker), 365);
            axios.post(localhostName.host + '/api/adtrackers/add', { type: types[type], [types[type]]: value })
                .then(res => {
                })
                .catch((error) => {
                });
        };
    }
    sendContact(e) {
        e.preventDefault();
        let newContact = {
            email: this.state.email,
            company: this.state.company,
            name: this.state.name,
            message: this.state.desc
        };
        axios.post(localhostName.host + '/api/contacts/advertise_add', newContact)
            .then(res => {
                $(".overview").html("<h2>Thank you for your message.</h2><div>We will answer within 24 hours.</div>");
            });
    }
    render() {
        let countries = {
            UK: "United Kingdom", DE: "Germany", ES: "Spain", PT: "Portugal", FR: "France", IE: "Ireland", IT: "Italy", TR: "Turkey", NL: "Netherlands",
            US: "USA", MX: "Mexico", CA: "Canada", AU: "Australia", NZ: "New Zealand", JP: "Japan", CR: "Costa Rica", DO: "Dominican Republic", TH: "Thailand", CO: "Colombia", KR: "South Korea", VN: "Vietnam", BR: "Brazil", AR: "Argentina", CH: "Switzerland", AT: "Austria", ZA: "South Africa", JM: "Jamaica", IS: "Iceland", SE: "Sweden", TW: "Taiwan", NO: "Norway", IN: "New Delhi", BS: "Bahamas", KE: "Kenya", MU: "Mauritius", PHL: "Philippines", PE: "Peru", MY: "Malaysia", AE: "United Arab Emirates", CN: "China", BE: "Belgium", HU: "Hungary", GR: "Greece", GI: "Gibraltar", CZ: "Czech Republic", DK: "Denmark", HK: "Hong Kong", SG: "Singapore", ID: "Indonesia", NP: "Nepal", QA: "Qatar", MT: "Malta", MC: "Monaco", KY: "Cayman Islands", CL: "Chile", NA: "Namibia", GH: "Ghana"
        };
        let countries_names = {};
        Object.keys(countries).map((key, index) => {
            countries_names[countries[key]] = key;
        });
        countries_names = Object.keys(countries_names).sort().reduce(
            (obj, key) => {
                obj[key] = countries_names[key];
                return obj;
            },
            {}
        );
        let cities = this.state.citiesdata.filter((c) => c.country === this.state.country);
        let city_numbers = {
            "London": "200K"
        };
        let price_cat = (this.state.price_cat.length > 0 ? this.state.price_cat : this.state.cat);
        return (
            <div className="ad_page">
                <div className="page_header">
                    <div className="back_overlay"><img src="https://golftus.com/back_london.jpg" /></div>
                    <div className="navbar container">
                        <div className="nav_logo">
                            <div><strong>Golftus</strong></div>
                            <div className="adv">Advertising</div>
                        </div>
                    </div>
                    <div className="con container">
                        {this.state.city === "London" && this.state.cat === "Aesthetics" ? <div className="limited_offer_banner">
                            <span><img src="/icons/price-tag.png" />Only in London - Limited Offer: Lifelong Listing for $299 instead of $799</span>
                        </div> : ''}
                        <div className="box ad_form">
                            <div className="form_con">
                                <div className="box text">
                                    <div className="sub">Claim Your Golftus Listing{this.state.city ? (" for " + this.state.city) : ""}</div>
                                    <div className="sub_headline">Secure a Lifetime Listing for your business/shop/clinic. Pay once and remain listed for a lifetime.</div>
                                    {/*{!(this.state.cat === "BeautyClinic") ? <div>Get your Lifetime Listing starting from $499.</div> : <div>Limited Offer: Get your lifetime listing for just $499!</div>}*/}
                                </div>
                                <div className="form_in">
                                    <label for="cars">Country*</label>
                                    <select onChange={(e) => this.change_Form("country", e.target.value)} value={this.state.country}>
                                        <option value="" selected="true" disabled="disabled">Choose Country</option>
                                        <option value="AU">Australia</option>
                                        <option value="CA">Canada</option>
                                        <option value="DE">Germany</option>
                                        <option value="UK">United Kingdom</option>
                                        <option value="US">United States</option>
                                        <option value="">---</option>
                                        {Object.keys(countries_names).map((key, index) => {
                                            return (
                                                <option value={countries_names[key]}>{key}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div className="form_in">
                                    <label for="cars">City*</label>
                                    <select onChange={(e) => this.change_Form("city", e.target.value)} value={this.state.city}>
                                        <option selected="true" >Choose City</option>
                                        {cities.sort((a, b) => a.name.localeCompare(b.name)).map((city) => {
                                            return (
                                                <option value={city.name}>{city.name}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div className="form_in_full" onChange={(e) => this.change_Form("cat", e.target.value)}>
                                    <div className="label_c">Category*</div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="Hotel" />
                                        <label className="form-check-label" for="flexRadioDefault2">
                                            Golf Resort & Hotel
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value="Golf" />
                                        <label className="form-check-label" for="flexRadioDefault1">
                                            Golf Store
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value="Aesthetics" checked={this.state.cat === "Aesthetics" ? true : ""} />
                                        <label className="form-check-label" for="flexRadioDefault1">
                                            Aesthetic Surgery
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="Dental" />
                                        <label className="form-check-label" for="flexRadioDefault2">
                                            Dental
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value="Experience" />
                                        <label className="form-check-label" for="flexRadioDefault1">
                                            Experience
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value="Coaching" />
                                        <label className="form-check-label" for="flexRadioDefault1">
                                            Coaching & Consulting
                                        </label>
                                    </div>
                                    <div className="form-check betaND">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value="Workshop" />
                                        <label className="form-check-label" for="flexRadioDefault1">
                                            Workshop
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value="Wellness" />
                                        <label className="form-check-label" for="flexRadioDefault1">
                                            Wellness & Massage
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="EstateAgent" />
                                        <label className="form-check-label" for="flexRadioDefault2">
                                            Estate Agent
                                        </label>
                                    </div>
                                    <div className="form-check betaND">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="Bar" />
                                        <label className="form-check-label" for="flexRadioDefault2">
                                            Bar/Pub & Cafes
                                        </label>
                                    </div>
                                    <div className="form-check betaND">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="Restaurant" />
                                        <label className="form-check-label" for="flexRadioDefault2">
                                            Restaurant
                                        </label>
                                    </div>
                                    <div className="form-check betaND">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="Fashion" />
                                        <label className="form-check-label" for="flexRadioDefault2">
                                            Fashion
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="Antiques" />
                                        <label className="form-check-label" for="flexRadioDefault2">
                                            Antiques
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="Art" />
                                        <label className="form-check-label" for="flexRadioDefault2">
                                            Art Gallery
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="Jewelry" />
                                        <label className="form-check-label" for="flexRadioDefault2">
                                            Jewelry
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="Wine" />
                                        <label className="form-check-label" for="flexRadioDefault2">
                                            Wine & Delicacies
                                        </label>
                                    </div>
                                    <div className="form-check betaND">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="Entertainment" />
                                        <label className="form-check-label" for="flexRadioDefault2">
                                            Entertainment
                                        </label>
                                    </div>
                                </div>
                                <div className="form_in_full form_price">
                                    <div>Price for Lifetime Listing</div>
                                    <div className="label_c">{this.state.city.length > 0 ? (this.state.city === "London" ? <span><img className="offer_l" src='/icons/price-tag.png' />Limited Offer for London: Lifelong Listing for $299 instead of $799</span> : "$799") : "Please select City and Category to see price."}</div>
                                </div>
                                <div className="form_in_full high_rating">
                                    <div className="label_c">{"Only shops with a high rating (Google > 4.0) are allowed"}</div>
                                    <div className="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                                        <label class="form-check-label" for="flexCheckChecked">
                                            I assure that my shop has a high rating.
                                        </label>
                                    </div>
                                </div>
                                <div className="form_in_full">
                                    <Link className="button_n" to={"/advertise-checkout?city=" + this.state.city + "&country=" + this.state.country + "&cat=" + this.state.cat + ""}><div >Next</div></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page_con access_audience">
                    <div className="cont_box container">
                        <div className="y_line"></div>
                        <div className="headline">
                            Access a global audience of golf enthusiasts who visit your city.
                        </div>
                        <div className="infos">
                            <div className="box">
                                <div className="line"></div>
                                <h4>68M</h4>
                                <div className="text">golfers worldwide</div>
                            </div>
                            {this.state.city === "London" ?
                                <div className="box">
                                    <div className="line"></div>
                                    <h4>{city_numbers[this.state.city]}</h4>
                                    <div className="text">golfers in {this.state.city}</div>
                                </div> : ""}
                            <div className="box">
                                <div className="line"></div>
                                <h4>28M</h4>
                                <div className="text">golfers who travel for golfing</div>
                            </div>
                            <div className="box">
                                <div className="line"></div>
                                <h4>High</h4>
                                <div className="text">income of golf players</div>
                            </div>
                            <div className="box">
                                <div className="line"></div>
                                <h4>78,6%</h4>
                                <div className="text">of golfers are interested in high quality products</div>
                            </div>
                            <div className="box">
                                <div className="line"></div>
                                <h4>$84B</h4>
                                <div className="text">2021 golf industry size</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page_con video_box vid_o">
                    <div className="container">
                        <h1 className="video_headline">What is Golftus.com?</h1>
                        <div className="video_sub">The premium web application for golfers to find the best golf travel destinations.</div>
                        <div className="startvid_box">
                            <video id="startvid" width="600" height="400" controls poster="/advertise_video.jpg">
                                <source src="/GolftusVid.mov" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                            <img src="/advertise_video.jpg" />
                        </div>
                    </div>
                </div>
                <div className="tab_overview">
                    <div className="cont_box container">
                        <div className="top_box">
                            <div className="listing_headline">
                                <h2>How your listing will look like</h2>
                                <div className="desc">Visit <a target="_blank" href="/#London?tab=shopmore">golftus.com/?city=London&tab=Shops</a> to see the listings live</div>
                            </div>
                            <div className="boxes">
                                <div className="info_box">
                                    <div className="subtitle">Overview</div>
                                    <div className="title">Easy to find on Map</div>
                                    <div className="desc">Our map feature makes it easy for customers to quickly locate your shop and plan their visit.</div>
                                </div>
                                <div className="info_box">
                                    <div className="subtitle">Info</div>
                                    <div className="title">Compact information</div>
                                    <div className="desc">Golftus is user-friendly and allows customers to easily find and access all the necessary information they need at a glance.</div>
                                </div>
                                <div className="info_box">
                                    <div className="subtitle">Specials</div>
                                    <div className="title">Specials for Golftus Members</div>
                                    <div className="desc">Attract more customers by offering exclusive deals and perks to Golftus members.</div>
                                </div>
                            </div>
                        </div>
                        <div className="bot_box">
                            <img src="/shops_over.jpg" />
                        </div>
                    </div>
                </div>
                <div className="tab_overview pricing">
                    <div className="container">
                        <h1 className="video_headline">Pricing</h1>
                        <div className="box">
                            <div className="form_in">
                                <select onChange={(e) => this.change_Form("price_cat", e.target.value)} value={price_cat}>
                                    <option >Select an Category</option>
                                    {categories.map((cat) => {
                                        return (
                                            <option value={cat.value}>{cat.name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            {price_cat.length > 0 ? <h1 class="card-title pricing-card-title">${(price_cat === "Aesthetics" ? "799" : "799")} <span class="text-muted">lifetime</span></h1> : ""}
                            {price_cat.length > 0 ? <h1 class="card-title pricing-card-title limited_offer"><img src="/icons/price-tag.png" />Limited Offer: Lifetime Listing in <bold>London</bold> for just <bold>$299</bold> instead of $799.</h1> : ''}
                            {price_cat.length > 0 ? <div>You only pay once and your {price_cat === "Aesthetics" ? "clinic" : "store/shop/business"} is listed for a lifetime</div> : ""}
                        </div>
                    </div>
                </div>
                {/*<div className="page_con lifetime_box">
                    <div className="container">
                        <h1 className="video_headline">Lifetime Listing Member</h1>
                        <div>You only pay once and your store/shop/business becomes a lifetime listing member of the global golf community.</div>
                    </div>
                </div>*/}
                <div className="page_con lifetime_box money_back">
                    <div className="container">
                        <h1 className="video_headline">Golftus Money Back</h1>
                        <div>At Golftus, we are confident that our platform will help your business thrive. That's why we offer a money-back guarantee if you don't see any positive results within 2 years of listing with us. We offer a full money-back guarantee. </div>
                    </div>
                </div>
                <div id="contact" className="contact">
                    <div className="cont_box container">
                        <h2>Do you have any questions?</h2>
                        {!this.state.show_contact ? <button className="contact_button" onClick={() => this.change_Form("show_contact", true)}>Contact us</button> : ""}
                        {this.state.show_contact ? <div>
                            <div>
                                <div class="form-group">
                                    <label >E-Mail*</label>
                                    <input class="form-control" placeholder="E-Mail" value={this.state.email} onChange={(e) => this.changeForm("email", e)}></input>
                                </div>
                                <div class="form-group">
                                    <label >Company (optional)</label>
                                    <input class="form-control" placeholder="Company Name" value={this.state.company} onChange={(e) => this.changeForm("company", e)}></input>
                                </div>
                                <div class="form-group">
                                    <label >Name (optional)</label>
                                    <input class="form-control" placeholder="Name" value={this.state.name} onChange={(e) => this.changeForm("name", e)}></input>
                                </div>
                                <div class="form-group">
                                    <label>Your Message</label>
                                    <textarea class="form-control" placeholder="Your message" value={this.state.desc} onChange={(e) => this.changeForm("desc", e)}></textarea>
                                </div>
                                <button type="submit" class="btn btn-primary" onClick={(e) => this.sendContact(e)}>Send</button>
                            </div>
                        </div> : ""}
                    </div>
                </div>
                <footer>
                    <div className="container">
                        <ul>
                            {/*<li class="nav-item">
                            <a class="nav-link" href="/datenschutz">FAQ</Link>
                        </li>*/}
                            <li className="nav-item">
                                <Link className="nav-link" to="#contact" onClick={() => this.change_Form("show_contact", true)}>Contact</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/terms-and-conditions">Ts&Cs</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/privacy-policy">Privacy & Cookies</Link>
                            </li>
                        </ul>
                        <ul className="payment_list">
                            <li className="payment_item">
                                <svg viewBox="0 0 38 24" xmlns="http://www.w3.org/2000/svg" width="38" height="24" role="img" aria-labelledby="pi-paypal"><title id="pi-paypal">PayPal</title><path opacity=".07" d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z"></path><path fill="#fff" d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32"></path><path fill="#003087" d="M23.9 8.3c.2-1 0-1.7-.6-2.3-.6-.7-1.7-1-3.1-1h-4.1c-.3 0-.5.2-.6.5L14 15.6c0 .2.1.4.3.4H17l.4-3.4 1.8-2.2 4.7-2.1z"></path><path fill="#3086C8" d="M23.9 8.3l-.2.2c-.5 2.8-2.2 3.8-4.6 3.8H18c-.3 0-.5.2-.6.5l-.6 3.9-.2 1c0 .2.1.4.3.4H19c.3 0 .5-.2.5-.4v-.1l.4-2.4v-.1c0-.2.3-.4.5-.4h.3c2.1 0 3.7-.8 4.1-3.2.2-1 .1-1.8-.4-2.4-.1-.5-.3-.7-.5-.8z"></path><path fill="#012169" d="M23.3 8.1c-.1-.1-.2-.1-.3-.1-.1 0-.2 0-.3-.1-.3-.1-.7-.1-1.1-.1h-3c-.1 0-.2 0-.2.1-.2.1-.3.2-.3.4l-.7 4.4v.1c0-.3.3-.5.6-.5h1.3c2.5 0 4.1-1 4.6-3.8v-.2c-.1-.1-.3-.2-.5-.2h-.1z"></path></svg>
                            </li>
                            <li className="payment_item">
                                <svg viewBox="0 0 38 24" xmlns="http://www.w3.org/2000/svg" width="38" height="24" role="img" aria-labelledby="pi-maestro"><title id="pi-maestro">Maestro</title><path opacity=".07" d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z"></path><path fill="#fff" d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32"></path><circle fill="#EB001B" cx="15" cy="12" r="7"></circle><circle fill="#00A2E5" cx="23" cy="12" r="7"></circle><path fill="#7375CF" d="M22 12c0-2.4-1.2-4.5-3-5.7-1.8 1.3-3 3.4-3 5.7s1.2 4.5 3 5.7c1.8-1.2 3-3.3 3-5.7z"></path></svg>
                            </li>
                            <li className="payment_item">
                                <svg viewBox="0 0 38 24" xmlns="http://www.w3.org/2000/svg" role="img" width="38" height="24" aria-labelledby="pi-master"><title id="pi-master">Mastercard</title><path opacity=".07" d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z"></path><path fill="#fff" d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32"></path><circle fill="#EB001B" cx="15" cy="12" r="7"></circle><circle fill="#F79E1B" cx="23" cy="12" r="7"></circle><path fill="#FF5F00" d="M22 12c0-2.4-1.2-4.5-3-5.7-1.8 1.3-3 3.4-3 5.7s1.2 4.5 3 5.7c1.8-1.2 3-3.3 3-5.7z"></path></svg>
                            </li>
                            <li className="payment_item">
                                <svg viewBox="0 0 38 24" xmlns="http://www.w3.org/2000/svg" role="img" width="38" height="24" aria-labelledby="pi-visa"><title id="pi-visa">Visa</title><path opacity=".07" d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z"></path><path fill="#fff" d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32"></path><path d="M28.3 10.1H28c-.4 1-.7 1.5-1 3h1.9c-.3-1.5-.3-2.2-.6-3zm2.9 5.9h-1.7c-.1 0-.1 0-.2-.1l-.2-.9-.1-.2h-2.4c-.1 0-.2 0-.2.2l-.3.9c0 .1-.1.1-.1.1h-2.1l.2-.5L27 8.7c0-.5.3-.7.8-.7h1.5c.1 0 .2 0 .2.2l1.4 6.5c.1.4.2.7.2 1.1.1.1.1.1.1.2zm-13.4-.3l.4-1.8c.1 0 .2.1.2.1.7.3 1.4.5 2.1.4.2 0 .5-.1.7-.2.5-.2.5-.7.1-1.1-.2-.2-.5-.3-.8-.5-.4-.2-.8-.4-1.1-.7-1.2-1-.8-2.4-.1-3.1.6-.4.9-.8 1.7-.8 1.2 0 2.5 0 3.1.2h.1c-.1.6-.2 1.1-.4 1.7-.5-.2-1-.4-1.5-.4-.3 0-.6 0-.9.1-.2 0-.3.1-.4.2-.2.2-.2.5 0 .7l.5.4c.4.2.8.4 1.1.6.5.3 1 .8 1.1 1.4.2.9-.1 1.7-.9 2.3-.5.4-.7.6-1.4.6-1.4 0-2.5.1-3.4-.2-.1.2-.1.2-.2.1zm-3.5.3c.1-.7.1-.7.2-1 .5-2.2 1-4.5 1.4-6.7.1-.2.1-.3.3-.3H18c-.2 1.2-.4 2.1-.7 3.2-.3 1.5-.6 3-1 4.5 0 .2-.1.2-.3.2M5 8.2c0-.1.2-.2.3-.2h3.4c.5 0 .9.3 1 .8l.9 4.4c0 .1 0 .1.1.2 0-.1.1-.1.1-.1l2.1-5.1c-.1-.1 0-.2.1-.2h2.1c0 .1 0 .1-.1.2l-3.1 7.3c-.1.2-.1.3-.2.4-.1.1-.3 0-.5 0H9.7c-.1 0-.2 0-.2-.2L7.9 9.5c-.2-.2-.5-.5-.9-.6-.6-.3-1.7-.5-1.9-.5L5 8.2z" fill="#142688"></path></svg>
                            </li>
                        </ul>
                        <p className="copyright">©golftùs 2023</p>
                    </div>
                </footer>
            </div>
        )
    }
}    