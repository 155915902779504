import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import e from 'cors';

export default class RangeSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            min: this.props.min,
            max: this.props.max
        };
    }
    componentDidMount() {
        const rangeInput = document.querySelectorAll("." + this.props.name + " .range-input input"),
            range = document.querySelector("." + this.props.name + " .slider .progress");
        let priceGap = this.props.gap;

    }
    getInput(e){
        if(e.target.name === "min"){
            let calc = ((e.target.value - this.props.min) / (this.props.max - this.props.min)) * 100;
            $("." + this.props.name + " .progress").css("left", (calc + "%"));
        }else{
            let calc = (1 - ((e.target.value - this.props.min) / (this.props.max - this.props.min))) * 100;
            $("." + this.props.name + " .progress").css("right", (calc + "%"));
        }
        this.setState({[e.target.name]: e.target.value})
    }
    render() {
        return (
            <div className={"RangeSlider " + this.props.name} data-value={[this.state.min, this.state.max]} onChange={(e)=>{this.props.onChange([$("." + this.props.name + " .range-min").val(), $("." + this.props.name + " .range-max").val()])}} style={{width: this.props.width}}>
                <div className="label">{this.props.label} <span>{this.state.min} - {this.state.max}</span></div>
                <div class="slider">
                    <div class="progress"></div>
                </div>
                <div class="range-input">
                    <input type="range" class="range-min" min={this.props.min} max={this.props.max} step={this.props.step} name="min" value={this.state.min} onChange={(e)=>{this.getInput(e); console.log(e)}} />
                    <input type="range" class="range-max" min={this.props.min} max={this.props.max} step={this.props.step} value={this.state.max} name="max" onChange={(e)=>this.getInput(e)} />
                </div>
            </div>
        )
    }
}    