import React, { Component } from 'react';
import axios from 'axios';
import localhostName from "../config.json";
import ModalBoot from '../components/Modal';
import $ from 'jquery';

export default class CityScams extends Component {
    constructor(props) {
        super(props);
        this.state = {
            city: this.props.city_name,
            videos: [],
            video_type: "walk",
            selected_video: { id: { videoId: "" } },
            clubs: []
        };
        this.showFormButton = this.showFormButton.bind(this);
        this.playVideo = this.playVideo.bind(this);
    }
    componentDidMount() {
        axios.get(localhostName.host + "/Data/Scams/Cities/" + this.props.cityName)
            .then(res => {
                let videos = res.data;
                this.setState({ videos: videos });
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    updateContents() {
    }
    renderVideos() {
        let videos = this.state.videos;
        let frequency_rate = {1: "Very Low",2: "Low",3:"Medium",4: "High",5: "Very High"}
        return videos.map((video) => {
            return (
                <div className="youtube_video" >
                    <div className="title">{video.name}</div>
                    <div className="frequency">Frequency: <span>{frequency_rate[video.frequency]} {video.frequency}/5</span></div>
                    <div className="desc">{video.desc}</div>
                    <div className="desc protect"><span>How to protect:</span> {video.how_to_protect}</div>
                </div>
            )
        });
    }
    changeType(type) {
        this.setState({ video_type: type });
    }
    showFormButton(targetShow, change) {
        this.setState({ [targetShow]: change });
    }
    playVideo(video) {
        this.setState({ selected_video: video });
        this.showFormButton("playVideo", true);
    }
    render() {
        let selected_video = this.state.selected_video;
        return (
            <div className="city_videos city_food city_scams">
                <div className="video_topbar">
                    <div className="filter_buttons">
                        <h3>Common Scams in {this.props.cityName}</h3>
                        {/*<div className={"f_button " + (this.state.video_type === "walk" ? "selected" : "")}  onClick={()=>{this.changeType("walk")}}><img class="icon" src="/icons/walk.png" /><div>Most sold</div></div>
                        <div className={"f_button " + (this.state.video_type === "golf" ? "selected" : "")} onClick={()=>{this.changeType("golf")}}><img class="icon" src="/icons/golf-course-64.png" /><div>Most popular</div></div>
                        <div className={"f_button " + (this.state.video_type === "sightseeing" ? "selected" : "")} onClick={()=>{this.changeType("sightseeing")}}><img class="icon" src="/icons/sightseeing.png" /><div>Cheapest</div></div>
                        <div className={"f_button " + (this.state.video_type === "food" ? "selected" : "")} onClick={()=>{this.changeType("food")}}><img class="icon" src="/icons/food.png" /><div>Unique</div></div>*/}
                    </div>
                </div>
                <div className="videos_list" key={this.state.videos}>
                    {this.renderVideos()}
                </div>
                <ModalBoot show={this.state.playVideo} hide={this.showFormButton} content={"playVideo"} className="modal_youtubeVideo">
                    <iframe width="800" height="600"
                        src={"https://www.youtube.com/embed/" + selected_video.id.videoId + "?autoplay=1"} allow='autoplay'>
                    </iframe>
                </ModalBoot >
            </div>
        )
    }
}    