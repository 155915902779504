import React, {Component} from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import $ from 'jquery';
import CityOverview from './CityOverview';

export default class BucketCollectButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            language: "de",
            actualArtwork: {},
            clicked: false,
        };
    }
    componentDidMount() {
        $(".BucketButtonModal .btn-close").click(function(){
            $(".BucketButtonModal").removeClass("show");
        });
        $(document).click(function(event) {
            if (!$(event.target).closest(".BucketButtonModal #logreg-forms").length) {
                $(".BucketButtonModal").removeClass("show");
            };
            if ($(event.target).closest(".to_collect").length) {
                $(".BucketButtonModal").addClass("show");
                $(".must_logged").html("!You have to be logged in to add something to your collection.");

            };
            if ($(event.target).closest(".to_bucketlist").length) {
                $(".BucketButtonModal").addClass("show");
                $(".must_logged").html("!You have to be logged in to add something to your bucket list.");
            };
        });       
    }
    login(e){
        e.preventDefault();
        this.setState({clicked: true});
    }
    render() {
        return (
            <div className="daily_report container">
                <div className="row">
                    <h1>To Do</h1>
                    <div className="col-md-3">
                        <div>
                            Alle Scorings für alle Städte
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div>
                            Profilseite
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div>
                            Anmeldung & Bezahlung
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div>
                            Mobile Version Anpassungen
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div>
                            Historic Kenzeichen für die Golf Plätze: Historic: {">"} 50 Jahre; Super Historic: {">"} 100 Jahre
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div>
                            Tee Time Costs Kennzeichen für die Golf Plätze
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div>
                            Suchen & Sortieren bei den Golf Plätzen
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div>
                            Instagram Photo Feed für den jewiligen Golfplatz
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div>
                            Architekt des Golfplatzes
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div>
                            Fläche und Zahl der Löcher für die Golfplätze
                        </div>
                    </div>
                </div>
                <div className="row">
                    <h1>Daily Report</h1>
                    <div className="row">
                        <h2>11.12.2022</h2>
                    </div>
                </div>
                <style>
                    {".daily_report{padding: 50px;} .row{column-gap: 15px;justify-content: center;} .col-md-3 {box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;padding: 25px;width: 350px;}"}
                </style>
            </div> 
        )
    }
}    