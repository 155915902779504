import React, { Component } from 'react';
import axios from 'axios';
import localhostName from "../config.json";
import { addCommas } from "../functions/functions.js"
import $ from 'jquery';

export default class Costs extends Component {
    constructor(props) {
        super(props);
        let default_costs = {
            mumbe: {
                restaurant: {
                    res_meal_two: 0,
                    res_beer: 0,
                    res_cappucciono: 0,
                    res_water: 0
                },
                markets: {
                    water: 0,
                    beer: 0,
                    wine: 0,
                    cigarettes: 0
                },
                transport: {
                    onewayticket: 0,
                    taxistart: 0,
                    taxi1km: 0,
                },
                leisure: {
                    fitness: 0,
                    tennis: 0,
                    cinema: 0
                },
                rent: {
                    apartmentcentre1bed: 0,
                    apartmentoutside1bed: 0,
                    apartmentcentre3bed: 0,
                    apartmentoutside3bed: 0,
                },
                buy: {
                    buy_apartmentcentre: 0,
                    buy_apartmentoutside: 0,
                },
                salaries: {
                    netmonthly: 0,
                    mortgagerate: 0
                }
            }
        };
        this.state = {
            score_desc: [],
            costs: default_costs,
            compare_active: false,
            compare_city: {},
            compare_city_costs: default_costs,
            compare_input: "",
            cities_list: (this.props.cities_list || []),
        };
    }
    componentDidMount() {
        axios.get(localhostName.host + "/Data/Costs/Cities/" + this.props.cityName)
            .then(res => {
                console.log(res);
                let costs = res.data;
                this.setState({ costs: costs });
            })
            .catch(function (error) {
                console.log(error);
            });
        $(document).ready(function () {
            var classToRemove = 'show';
            var $targetElement = $('.compare_city_input');
            $targetElement.on('click', function (event) {
                if ($(event.target).attr("class") === "select") {
                    setTimeout(function () {
                        $targetElement.removeClass(classToRemove);
                    }, 1000);
                } else {
                    $targetElement.addClass(classToRemove);
                };
            });
            $(document).on('click', function (event) {
                if (!$targetElement.is(event.target) && $targetElement.has(event.target).length === 0) {
                    $targetElement.removeClass(classToRemove);
                };
            });
        });
    }
    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    }
    select_compare_city(city) {
        axios.get(localhostName.host + "/Data/Costs/Cities/" + city.name)
            .then(res => {
                console.log(res);
                this.setState({ compare_city_costs: res.data, compare_active: true, compare_city: city });
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    renderCosts() {
        let costs = this.state.costs;
        let costs_compare = this.state.compare_city_costs;
        console.log(costs);
        console.log(costs_compare);
        function getPriceMum(prop1, prop2) {
            return costs.mumbe[prop1][prop2];
        };
        function getPriceDir(prop1) {
            return costs[prop1];
        };
        function getPriceMumCompare(prop1, prop2) {
            return costs_compare.mumbe[prop1][prop2];
        };
        function getPriceDirCompare(prop1) {
            return costs_compare[prop1];
        };
        let costs_list = [
            {
                name: "cost_expat",
                en: "Total Cost for 3 Nights for 2 People",
                icon: "",
                type: "points",
                cheap: 450,
                expensive: 1600,
                price: getPriceDir("3nights_2person"),
                price_compare: getPriceDirCompare("3nights_2person")

            },
            {
                name: "hotel",
                en: "Hotel 1 Night 2 Person(Median)",
                icon: "",
                type: "points",
                cheap: 40,
                expensive: 250,
                price: getPriceDir("hotel_night"),
                price_compare: getPriceDirCompare("hotel_night")

            },
            {
                name: "teetime",
                en: "Tee Time (Golf) Price avg.",
                icon: "",
                type: "points",
                cheap: 40,
                expensive: 180,
                price: getPriceDir("avg_teetime"),
                price_compare: getPriceDirCompare("avg_teetime")

            },
            {
                name: "cheapest_teetime",
                en: "Cheapest Tee Time (Golf) Price",
                icon: "",
                type: "points",
                cheap: 35,
                expensive: 80,
                price: getPriceDir("cheapest_teetime"),
                price_compare: getPriceDirCompare("cheapest_teetime")

            },
            {
                name: "res_meal_two",
                en: "Meal for 2 People (Mid-Range Restaurant)",
                icon: "",
                cheap: 20,
                expensive: 90,
                price: getPriceMum("restaurant", "res_meal_two"),
                price_compare: getPriceMumCompare("restaurant", "res_meal_two"),

            },
            {
                name: "res_beer",
                en: "Beer (0.5 liter) in Restaurant/Bar",
                icon: "",
                cheap: 1,
                expensive: 7,
                price: getPriceMum("restaurant", "res_beer"),
                price_compare: getPriceMumCompare("restaurant", "res_beer"),

            },
            {
                name: "res_cappucciono",
                en: "Coffee in Restaurant/Cafe",
                icon: "",
                cheap: 1,
                expensive: 5,
                price: getPriceMum("restaurant", "res_cappucciono"),
                price_compare: getPriceMumCompare("restaurant", "res_cappucciono"),

            },
            {
                name: "res_water",
                en: "Water in Restaurant/Cafe",
                icon: "",
                cheap: 0.5,
                expensive: 2.5,
                price: getPriceMum("restaurant", "res_water"),
                price_compare: getPriceMumCompare("restaurant", "res_water"),

            },
            {
                name: "beer",
                en: "Beer (0.5 liter) in Supermarket",
                icon: "",
                cheap: 0.7,
                expensive: 2.5,
                price: getPriceMum("markets", "beer"),
                price_compare: getPriceMumCompare("markets", "beer"),

            },
            {
                name: "wine",
                en: "Wine in Supermarket",
                icon: "",
                cheap: 4,
                expensive: 12,
                price: getPriceMum("markets", "wine"),
                price_compare: getPriceMumCompare("markets", "wine"),

            },
            {
                name: "cigarettes",
                en: "Cigarettes (20Pack)",
                icon: "",
                cheap: 2,
                expensive: 10,
                price: getPriceMum("markets", "cigarettes"),
                price_compare: getPriceMumCompare("markets", "cigarettes"),

            },
            {
                name: "apartmentcentre1bed",
                en: "1br studio rent in center",
                icon: "",
                cheap: 250,
                expensive: 1400,
                price: getPriceMum("rent", "apartmentcentre1bed"),
                price_compare: getPriceMumCompare("rent", "apartmentcentre1bed"),

            },
            {
                name: "apartmentcentre3bed",
                en: "3br studio rent in center",
                icon: "",
                cheap: 400,
                expensive: 2500,
                price: getPriceMum("rent", "apartmentcentre3bed"),
                price_compare: getPriceMumCompare("rent", "apartmentcentre3bed"),

            },
            {
                name: "onewayticket",
                en: "Public Transport (One Way Ticket)",
                icon: "",
                cheap: 0.25,
                expensive: 3.20,
                price: getPriceMum("transport", "onewayticket"),
                price_compare: getPriceMumCompare("transport", "onewayticket"),

            },
            {
                name: "taxi1km",
                en: "Taxi per 1km",
                icon: "",
                cheap: 0.3,
                expensive: 2.8,
                price: getPriceMum("transport", "taxi1km"),
                price_compare: getPriceMumCompare("transport", "taxi1km"),

            },
            {
                name: "taxi5km",
                en: "Taxi 5km Drive",
                icon: "",
                cheap: 2,
                expensive: 20,
                price: (getPriceMum("transport", "taxi1km") * 5) + getPriceMum("transport", "taxistart"),
                price_compare: (getPriceMum("transport", "taxi1km") * 5) + getPriceMum("transport", "taxistart"),
                mumbe: true

            },
            {
                name: "fitness",
                en: "Fitness Club (monthly)",
                icon: "",
                cheap: 19,
                expensive: 75,
                price: getPriceMum("leisure", "fitness"),
                price_compare: getPriceMumCompare("leisure", "fitness"),

            },
            {
                name: "tennis",
                en: "Tennis Court (1 Hour)",
                icon: "",
                cheap: 7.5,
                expensive: 30,
                price: getPriceMum("leisure", "tennis"),
                price_compare: getPriceMumCompare("leisure", "tennis"),

            },
            {
                name: "cinema",
                en: "Cinema",
                icon: "",
                cheap: 3.5,
                expensive: 13.5,
                price: getPriceMum("leisure", "cinema"),
                price_compare: getPriceMumCompare("leisure", "cinema"),

            },
            {
                name: "buy_apartmentcentre",
                en: "Buy Apartment, Price per Square Meter",
                icon: "",
                cheap: 950,
                expensive: 85000,
                price: getPriceMum("buy", "buy_apartmentcentre"),
                price_compare: getPriceMumCompare("buy", "buy_apartmentcentre"),

            },
            {
                name: "netmonthly",
                en: "Salaries",
                icon: "",
                cheap: 280,
                expensive: 3700,
                price: getPriceMum("salaries", "netmonthly"),
                price_compare: getPriceMumCompare("salaries", "netmonthly"),

            },
        ];
        return costs_list.map((cost) => {
            let score_rate = ((cost.cheap + cost.expensive) / (cost.price * 5) * 100);
            let score_rateW = ((cost.cheap + cost.expensive) / (cost.price * 5) * 100);
            let score_rateWCompare = ((cost.cheap + cost.expensive) / (cost.price_compare * 5) * 100);
            let expensive = "";
            if (cost.name === "netmonthly") {
                score_rate = (-1) * (score_rate - 100);
            };
            let color = "";
            function get_color(rating) {
                if (rating > 85) {
                    return "#2bde73";
                } else if (rating > 65) {
                    return "#2bde73";
                } else if (rating > 35) {
                    return "#ffc924";
                } else if (rating > 15) {
                    return "rgb(251 121 118)";
                }
                else {
                    return "#ff4742";
                }
            }
            function price_expensive(rating) {
                if (rating > 85) {
                    return "very cheap";
                } else if (rating > 65) {
                    return "cheap";
                } else if (rating > 35) {
                    return "moderate";
                } else if (rating > 15) {
                    return "expensive";
                }
                else {
                    return "very expensive";
                }
            }
            if (cost.name === "netmonthly") {
                expensive.replace("expensive", "high");
                expensive.replace("cheap", "low");
            };
            return (
                <div className="score_single">
                    <div className="score_name">
                        <img class="icon" src={cost.icon} />
                        {cost.en}
                    </div>
                    <div className={"score_name price " + (this.state.compare_active ? "showCompare" : "")}>
                        <div className="compare_city_name main_city">{this.props.cityName}</div>
                        {"$" + addCommas(cost.price || "")}
                        <div className="expensive_text" style={{ color: get_color(score_rateW) }}>{price_expensive(score_rateW)}</div>
                        <div className="compare_city_name">{this.state.compare_city.name}</div>
                        <span className="compare_price">{"$" + addCommas(cost.price_compare || "")}</span>
                        <div className="compare_text expensive_text" style={{ color: get_color(score_rateWCompare) }}>{price_expensive(score_rateWCompare)}</div>
                    </div>
                </div>
            )
        })
    }
    render() {
        let cities_list = this.state.cities_list;
        cities_list = cities_list.filter(c => c.name.includes(this.state.compare_input));
        return (
            <div className="city_scores city_costs">
                <div className='city_desc_short'>
                    <div>{this.state.costs.desc}</div>
                </div>
                <div className="tab_sub_topbar">
                    <div className="compare_city">
                        <span>Compare with</span>
                        <div className="compare_city_input">
                            <input placeholder="City" id="compare_input" onChange={(e) => this.onChange(e)} />
                            <div className="list_compare_cities">
                                {cities_list.map((c) => {
                                    return (
                                        <div className="compare_city">
                                            <div className="name">{c.name}, {c.country}</div>
                                            <div className="select" onClick={() => this.select_compare_city(c)}>compare</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderCosts()}
            </div>
        )
    }
}    