import React, {Component} from 'react';
import axios from 'axios';
import localhostName from "../config.json";
import { Link } from "react-router-dom";
import $ from 'jquery';
import CityOverview from './CityOverview';

export default class BucketCollectButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    componentDidMount() {
        $(".BucketButtonModal .btn-close").click(function(){
            $(".BucketButtonModal").removeClass("show");
        });      
    }
    add(type) {
        let add_new = { 
            name: this.props.name,
            item: this.props.id,
         };
         if(type === "done"){
            add_new.done = true;
         }else if(type === "bucket"){
            add_new.bucket = true;
         };
        axios.post(localhostName.host + '/api/users/additemdone', add_new, { withCredentials: true })
            .then(res => {
                alert("saved");
                this.props.reload();
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    clickButton(type,e){
        e.preventDefault();
        if(this.props.logged){
            this.add(type)
        }else{
            let text = "!You have to be logged in to add something to your bucket list.";
            if(type === "done"){
                text = "!You have to be logged in to add something to your " + (this.props.done_name || "Done") + " list.";
            }
            $(".BucketButtonModal").addClass("show");
            $(".must_logged").html(text);
        }
    }
    render() {
        return (
            <div className={"BucketCollectButton " + ((this.props.user_data[this.props.name] || {done: []}).done.includes(this.props.id) ? "played " : "") + ((this.props.user_data[this.props.name] || {bucket: []}).bucket.includes(this.props.id) ? "bucket_listed" : "")}>
                <div className="to_collect" onClick={(e)=>{this.clickButton("done",e)}}>+{this.props.done_name || "Done"}</div>
                <div className="to_bucketlist" onClick={(e)=>{this.clickButton("bucket",e)}}>+Bucket List</div> 
                <div className="to_bucketlist listed" onClick={(e)=>{this.clickButton("bucket_remove",e)}}><i className="fa fa-check"></i>On Bucket List</div> 
                <div className="to_collect finished" onClick={(e)=>{this.clickButton("done_remove",e)}}><i className="fa fa-check"></i>{this.props.done_name || "Done"}</div>
            </div> 
        )
    }
}    