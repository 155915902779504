import React, { Component } from 'react';
import axios from 'axios';
import LoadingSVG from './LoadingSVG';
import localhostName from "../config.json";
import { Tooltip } from 'react-tooltip';
import $ from 'jquery';

export default class CityScores extends Component {
    constructor(props) {
        super(props);
        this.state = {
            language: "de",
            actualArtwork: {},
            artworks: [],
            devoPics: [],
            settings: { _id: "df" },
            city_scores: [],
            data_loaded: false,
            userInfo: this.props.userInfo,
            compare_active: false,
            compare_city: {},
            compare_city_scores: [],
            compare_input: "",
            cities_list: (this.props.cities_list || []),
            score_desc: [],
            score_desc_main: [],
            sort_by: "main"
        };
    }
    componentDidMount() {
        axios.get(localhostName.host + "/Data/Scores/Cities/" + this.props.cityName)
            .then(res => {
                let city_scores = (Array.isArray(res.data) ? res.data : []);
                this.setState({ data_loaded: true, city_scores: city_scores });
            })
            .catch(function (error) {
                console.log(error);
            });
        let score_desc_main = [
            {
                name: "totalscore",
                en: "Total Score",
                icon: "/icons/sigma.png",
                type: "points",
                output_type: "good"

            },
            {
                name: "golfscore",
                en: "Golf Score",
                icon: "/icons/golf-player.png",
                type: "points",
                output_type: "good"

            },
            {
                name: "numbercourses",
                en: "Number of Golf Courses",
                icon: "/icons/golf-course-64.png",
                type: "points",
                output_type: "good"

            },
            {
                name: "cost_we",
                en: "Costs (3 Nights & 2 Persons)",
                icon: "/icons/hotel Kopie.png",
                type: "points",
                output_type: "good"

            },
            {
                name: "sightseeing",
                en: "Sightseeing",
                icon: "/icons/sightseeing.png",
                type: "points",

            },
            {
                name: "safety",
                en: "Safety",
                icon: "/icons/safety.png",
                type: "points",
                output_type: "good"

            },
            {
                name: "weather",
                en: "Weather",
                icon: "/icons/cloudy.png",
                type: "points",
                output_type: "good"

            },
            {
                name: "nightlife",
                en: "Nightlife",
                icon: "/icons/drink.png",
                type: "points",
                output_type: "good"

            },
            {
                name: "food",
                en: "Food",
                icon: "/icons/cutlery.png",
                type: "points",
                output_type: "good"

            },
            {
                name: "quality_life",
                en: "Quality of Life",
                icon: "/icons/quality-of-life2.png",
                type: "points",
                output_type: "good"

            },
            {
                name: "tourism",
                en: "Tourism",
                icon: "/icons/tourism.png",
                type: "points",
                output_type: "good"

            }
        ];
        let score_desc = [
            {
                name: "qualitycourses",
                en: "Quality of Golf Courses",
                icon: "/icons/golf-course.png",
                type: "points",
                output_type: "good"

            },
            {
                name: "golfevents",
                en: "Golf Events",
                icon: "/icons/golf-event.png",
                type: "points",
                output_type: "good"

            },
            {
                name: "golfmembers",
                en: "Number of Golf Players",
                icon: "/icons/golf-players.png",
                type: "points",
                output_type: "good"

            },
            {
                name: "photo_places",
                en: "Unique Photo Places",
                icon: "/icons/camera.png",
                type: "points",

            },
            {
                name: "accommodation",
                en: "Accommodation",
                icon: "/icons/hotel.png",
                type: "points",
                output_type: "good"

            },
            {
                name: "englishspeaking",
                en: "English speaking",
                icon: "/icons/englishspeaking.png",
                type: "points",
                output_type: "good"

            },
            {
                name: "hospitals",
                en: "Hospitals",
                icon: "/icons/hospital.png",
                type: "points",
                output_type: "good"

            },
            {
                name: "food_safety",
                en: "Food Safety",
                icon: "/icons/hospital.png",
                type: "points",
                output_type: "good"

            },
            {
                name: "beach",
                en: "Beach",
                icon: "/icons/island.png",
                type: "points",
                output_type: "good"

            },
            {
                name: "green_space",
                en: "Green Space",
                icon: "/icons/forest.png",
                type: "points",
                output_type: "good"

            },
            {
                name: "247",
                en: "24/7 City",
                icon: "/icons/24-hours.png",
                type: "points",
                output_type: "good"

            },
            {
                name: "beer_culture",
                en: "Beer Culture",
                icon: "/icons/beerfest.png",
                type: "points",
                output_type: "good"

            },
            {
                name: "swinger_culture",
                en: "Swinger Culture",
                icon: "/icons/parents.png",
                type: "points",
                output_type: "good"

            },
            {
                name: "moral_freedom",
                en: "Moral Freedom",
                icon: "/icons/freedom.png",
                type: "points",
                output_type: "good"

            },
            {
                name: "humor",
                en: "Humor",
                icon: "/icons/humor.png",
                type: "points",
                output_type: "good"

            },
            {
                name: "friendlytoforeigners",
                en: "Friendliness to foreigners",
                icon: "/icons/friends.png",
                type: "points",
                output_type: "good"

            },
            {
                "name": "family_friendly",
                en: "Family Friendly",
                icon: "/icons/family.png",
                type: "points",
                output_type: "good"
            },
            {
                name: "education",
                en: "Education Level",
                icon: "/icons/education.png",
                type: "points",
                output_type: "good"

            },
            {
                name: "walkability",
                en: "Walkability",
                icon: "/icons/walk2.png",
                type: "points",
                output_type: "good"

            },
            {
                name: "publictransport",
                en: "Public Transport",
                icon: "/icons/publictransport.png",
                type: "points",
                output_type: "good"

            },
            {
                name: "traffic",
                en: "Traffic",
                icon: "/icons/traffic.png",
                type: "points",
                output_type: "good"

            },
            {
                "name": "events",
                en: "Events",
                icon: "/icons/red-carpet.png",
                type: "points",
                output_type: "good"
            },
            {
                name: "shopping",
                en: "Shopping",
                icon: "/icons/shop.png",
                type: "points",
                output_type: "good"

            },
            {
                name: "artsculture",
                en: "Arts & Culture",
                icon: "/icons/many_art_galleries.png",
                type: "points",
                output_type: "good"

            },
            {
                name: "ruleoflaw",
                en: "Rule of Law",
                icon: "/icons/ruleoflaw.png",
                type: "points",
                output_type: "good"

            },
            {
                name: "cashless",
                en: "Cashless",
                icon: "/icons/swipe.png",
                type: "points",
                output_type: "good"

            },
            {
                name: "airquality",
                en: "Air Quality",
                icon: "/icons/airquality.png",
                type: "points",
                output_type: "good"

            },
            {
                name: "fashionlooks",
                en: "Fashion & Looks",
                icon: "/icons/suit.png",
                type: "points",
                output_type: "good"

            },
            {
                "name": "fitness_culture",
                en: "Fitness Culture",
                icon: "/icons/fitness.png",
                type: "points",
                output_type: "good"
            },
            {
                "name": "activities",
                en: "Leisure Activities",
                icon: "/icons/activities.png",
                type: "points",
                output_type: "good"
            },
            {
                "name": "diversity",
                en: "Diversity",
                icon: "/icons/diversity.png",
                type: "points",
                output_type: "good"
            },
            {
                name: "creativity",
                en: "Creativity",
                icon: "/icons/creativity.png",
                type: "points",
                output_type: "good"

            },
            {
                name: "musicals",
                en: "Musicals",
                icon: "/icons/musicals.png",
                type: "points",
                output_type: "good"

            },
            {
                name: "architecture",
                en: "Architecture",
                icon: "/icons/architecture.png",
                type: "points",
                output_type: "good"

            },
            {
                "name": "beauty_men",
                en: "Attractive Men",
                icon: "/icons/man2.png",
                type: "points",
                output_type: "good"
            },
            {
                "name": "beauty_women",
                en: "Attractive Women",
                icon: "/icons/woman.png",
                type: "points",
                output_type: "good"
            },
            {
                "name": "dating",
                en: "Dating",
                icon: "/icons/dating.png",
                type: "points",
                output_type: "good"
            },
            {
                name: "averagesalary",
                en: "💸 Average Salary",
                icon: "",
                type: "label",

            },
            {
                name: "economy",
                en: "Economy",
                icon: "/icons/economic.png",
                type: "points",
                output_type: "good"

            },
            {
                name: "wealthy",
                en: "Wealthy",
                icon: "/icons/wealthy.png",
                type: "points",
                output_type: "good"

            },
            {
                name: "publicwifi",
                en: "Public Wifi Accessibility",
                icon: "/icons/wifi.png",
                type: "points",
                output_type: "good"

            },
            {
                name: "smartcity",
                en: "Smart City",
                icon: "/icons/smartcity2.png",
                type: "points",
                output_type: "good"

            }
        ];
        if(this.props.city_nearest && this.props.city_nearest.name && !window.compare_city){
            this.select_compare_city(this.props.city_nearest);
        }else if(window.compare_city){
            this.select_compare_city(window.compare_city);
        };
        this.setState({ score_desc: score_desc, score_desc_main: score_desc_main });
        {/*let random_city = this.state.cities_list[Math.floor(Math.random() * this.state.cities_list.length)];
        this.select_compare_city(random_city);*/}
        $(document).ready(function() {
            var classToRemove = 'show';
            var $targetElement = $('.compare_city_input');
            $targetElement.on('click', function(event) {
                if($(event.target).attr("class") === "select"){
                    setTimeout(function(){
                        $targetElement.removeClass(classToRemove);
                    },1000);
                }else{
                    $targetElement.addClass(classToRemove);
                };
            });
            $(document).on('click', function(event) {
                if (!$targetElement.is(event.target) && $targetElement.has(event.target).length === 0) {
                    $targetElement.removeClass(classToRemove);
                };
            });
        });
    }
    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    }
    sort_by(value) {
        this.setState({ sort_by: value });
    }
    renderScores(type) {
        // Rendering all projects; 8 projects per page
        let Scores = [...(type === "main" ? this.state.score_desc_main : this.state.score_desc)];
        let city_scores = this.state.city_scores;
        let compare_city_scores = this.state.compare_city_scores;
        let count = 1;
        let count_s = 1;
        Scores.map((score) => {
            let index = city_scores.findIndex((data) => data.name === score.name);
            let index_s = Scores.findIndex((s) => s.name === score.name);
            score.score = (city_scores[index] || {}).score_results;
            Scores[index_s].score_results = score.score;
            Scores[index_s].priority = count_s;
            count_s += 1;
        });
        if (this.state.sort_by === "worst_scores") {
            Scores.sort((a, b) => (a.score_results > b.score_results) ? 1 : ((b.score_results > a.score_results) ? -1 : 0));
        };
        if (this.state.sort_by === "top_scores") {
            Scores.sort((a, b) => (b.score_results > a.score_results) ? 1 : ((a.score_results > b.score_results) ? -1 : 0));
        };
        if (this.state.sort_by === "name") {
            Scores.sort((a, b) => (a.en > b.en) ? 1 : ((b.en > a.en) ? -1 : 0));
        };
        if (this.state.sort_by === "main") {
            Scores  = (type === "main" ? this.state.score_desc_main : this.state.score_desc);
        };
        return Scores.map((score) => {
            let index = city_scores.findIndex((data) => data.name === score.name);
            let index_compare = compare_city_scores.findIndex((data) => data.name === score.name);
            score.score = (city_scores[index] || {}).score_results;
            let score_rate = (score.score / 5 * 100);
            let score_rate_compare = ((compare_city_scores[index_compare] || {}).score_results / 5 * 100);
            if (score.output_type == "good") {
                if (score_rate > 85) {
                    score.output = "Great ";
                } else if (score_rate > 65) {
                    score.output = "Good ";
                } else if (score_rate > 45) {
                    score.output = "Okay ";
                } else if (score_rate > 30) {
                    score.output = "Bad ";
                }
                else {
                    score.output = "Very Bad ";
                }
            };
            function text_output(rating) {
                if (score.output_type == "good") {
                    if (rating > 85) {
                        return "Great ";
                    } else if (rating > 65) {
                        return "Good ";
                    } else if (rating > 45) {
                        return "Okay ";
                    } else if (rating > 30) {
                        return "Bad ";
                    } else if (rating == 0 || rating == undefined) {
                        return "No Rating ";
                    }
                    else {
                        return "Very Bad ";
                    }
                };
            };
            function color_output(rating) {
                if (rating > 85) {
                    return "#2bde73";
                } else if (rating > 65) {
                    return "#2bde73";
                } else if (rating > 45) {
                    return "#ffc924";
                } else if (rating > 30) {
                    return "#ff4742";
                }
                else {
                    score.color = "#ff4742";
                }
            };
            let id = Math.random().toString(16).slice(2);
            let desc = "<div class='info_tooltip'><div class='name'>" + score.en + "</div>" + (city_scores[index] || {}).desc + "</div>";
            count += 1;
            if (score.score) {
                return (
                    <div className="score_single" data-tooltip-id={id} data-tooltip-html={(city_scores[index] || {}).desc ? desc : ""}>
                        <img src="/icons/info_i.png" className="info_i" />
                        <div class="progress-circle" style={{ "--percent1": (score_rate + "%"), "--percent2": (score_rate + "%"), "--color1": score.color }}>
                            <div class="progress-circle-inner">
                                <div class="progress-circle-value">{score.score}</div>
                            </div>
                        </div>
                        {score.icon.length > 0 ?
                            <div className="score_name">
                                <img className="icon" src={score.icon} /><div>{score.en}</div>
                            </div>
                            : <div className="score_name"><span>{score.en}</span></div>}
                        <div className={"score_result " + (this.state.compare_active ? "showCompare" : "")}>
                            <div className="compare_city_name main_city">{this.props.cityName}</div>
                            <div className="outer">
                                <div className="inner" style={{ width: (score_rate + "%"), background: color_output(score_rate) }}></div>
                                <div className="score_output">{score.output || score.score} <span className="score_number">[{score.score}]</span></div>
                            </div>
                            <div className="compare_city_name">{this.state.compare_city.name}</div>
                            <div className="outer compare">
                                <div className="inner" style={{ width: (score_rate_compare + "%"), background: color_output(score_rate_compare) }}></div>
                                <div className="score_output">{text_output(score_rate_compare)} <span className="score_number">{(compare_city_scores[index_compare] || {}).score_results > 0 ? <span>[{(compare_city_scores[index_compare] || {}).score_results}]</span> : ""}</span></div>
                            </div>
                        </div>
                        <div className='desc_short'><div >{(city_scores[index] || {}).desc}</div></div>
                        <Tooltip className="scores_tooltip" id={id} />
                    </div>
                );
            };
        });
    }
    select_compare_city(city) {
        axios.get(localhostName.host + "/Data/Scores/Cities/" + city.name)
            .then(res => {
                let city_scores = (Array.isArray(res.data) ? res.data : []);
                this.setState({ compare_city_scores: city_scores, compare_active: true, compare_city: city });
                if((window.compare_city || {}).name !== city.name){
                    window.compare_city = (window.cities_geo || []).find(c => c.name === city.name);
                };
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    render() {
        let cities_list = this.state.cities_list;
        cities_list = cities_list.filter(c => c.name.includes(this.state.compare_input));
        return (
            <div className={"city_scores " + (!this.state.data_loaded ? "data_loading" : "")} >
                <div className="tab_sub_topbar">
                    <div className="compare_city">
                        <span>Compare with</span>
                        <div className="compare_city_input">
                            <input placeholder="Select City" id="compare_input" onChange={(e) => this.onChange(e)} />
                            <div className="list_compare_cities">
                                {cities_list.map((c) => {
                                    return (
                                        <div className="compare_city_select">
                                            <div className="name">{c.name}, {c.country}</div>
                                            <div className="select" onClick={() => this.select_compare_city(c)}>compare</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="sort_by">
                        <select value={this.state.sort_by} onChange={(e) => this.sort_by(e.target.value)}>
                            <optgroup>
                                <option value="main">Sort</option>
                                <option value="top_scores">Best Scores</option>
                                <option value="worst_scores">Worst Scores</option>
                                <option value="name">Name</option>
                            </optgroup>
                        </select>
                    </div>
                </div>
                <div className="loading_svg">
                    <LoadingSVG />
                </div>
                <div className="all_scores" key={this.state.sort_by}>
                    <div className="main_scores">
                        <h2>Main Scores</h2>
                        {this.renderScores("main")}
                    </div>
                    <div className="more_scores">
                        <h2>More Scores</h2>
                        {this.renderScores("more")}
                    </div>
                </div>
            </div>
        )
    }
}    