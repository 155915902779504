import React, { Component } from 'react';
import axios from 'axios';
import localhostName from "../config.json";
import $ from 'jquery';

export default class Infos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            score_desc: [],
            infos_emoji: {},
            infos_obj: {},
            infos_data: {}

        };
    }
    componentDidMount() {
        let infos_data = [];
        axios.get(localhostName.host + "/Data/Infos/Cities/" + this.props.cityName)
            .then(res => {
                infos_data = res.data;
                this.setState({ infos_data: infos_data });
            })
            .catch(function (error) {
                console.log(error);
            });
        let infos_obj = {
            "Country": "Country",
            "Continent": "Continent",
            "OfficialLanguage": "Official Language",
            "Currency": "Currency",
            "MostCommonWeather": "Most Common Weather",
            "Population": "Population",
            "Climate": "Climate",
            "BestTimeToVisit": "Best Time To Visit",
            "BestTimeToPlayGolf": "Best Time To Play Golf",
            "PopulationDensity": "Population Density",
            "CityFoundationYear": "City Foundation Year",
            "AverageSalary": "Average Salary",
            "VisitorsPerYear": "Visitors Per Year",
            "BestTaxiApp": "Best Taxi App",
            "MetroNetworkSize": "Metro Network Size",
            "PublicTransportTime": "Public Transport Time",
            "TapWater": "Tap Water",
            "Power": "Power",
            "Tipping": "Tipping",
            "AirportPassengers": "Airport Passengers",
            "TravelApps": "Travel Apps",
            "BestFoodDeliveryApps": "Best Food Delivery Apps",
            "BestBikeRentals": "Best Bike Rentals",
            "BestScooterRentals": "Best Scooter Rentals",
            "BestCarRentals": "Best Car Rentals",
            "PublicDrinking": "Public Drinking",
            "LargestPark": "Largest Park",
            "NumberUniversityStudents": "Number University Students",
            "BestSimCard": "Best Sim Card",
            "MostTouristsFrom": "Most Tourists From",
            "ShoppingDistricts": "Shopping Districts",
            "MostCommonSupermarkets": "Most Common Supermarkets",
            "TimeShopsClose": "Time Shops Close",
            "NightlifeNeighbourhoods": "Nightlife Neighbourhoods",
            "FamousHistoricalFigures": "Tokugawa Ieyasu, Emperor Meiji",
            "DrivingSide": "Driving Side",
            "MostPopularSportTeams": "Popular Sport Teams",
            "MostDrunkBeerBrands": "Most Drunk Beer Brands",
            "MainIndustries": "Main Industries",
            "MajorReligion": "Major Religion",
            "FamousCityLegends": "Famous City Legends",
            "FamousBirthplaces": "Famous Birthplaces",
            "LargestMuseum": "Largest Museum",
            "LargestCompanies": "Largest Companies",
            "OldestGolfCourses": "Oldest Golf Courses",
            "MostCommonCarModels": "Most Common Car Models",
            "EventWebsites": "Event Websites",
            "BestTouristsTourOperators": "Best Tourists Tour Operators",
            "MostKnownGolfers": "Most Known Golfers",
            "GolfWebsites": "Golf Websites"
        };
        let infos_emoji = {
            "Country": "asia.png",
            "Continent": "coronavirus.png",
            "OfficialLanguage": "translation.png",
            "Currency": "currency.png",
            "MostCommonWeather": "cloudy.png",
            "Population": "crowd.png",
            "Climate": "climate.png",
            "BestTimeToVisit": "time_visit.png",
            "BestTimeToPlayGolf": "golf-event.png",
            "TravelApps": "travel_apps.png",
            "ShoppingDistricts": "shopping_district.png",
            "PopulationDensity": "people.png",
            "CityFoundationYear": "foundation.png",
            "AverageSalary": "reduce-cost.png",
            "VisitorsPerYear": "tourism.png",
            "BestTaxiApp": "taxi.png",
            "MetroNetworkSize": "train.png",
            "PublicTransportTime": "publictransport.png",
            "TapWater": "water-tap.png",
            "Power": "electricity.png",
            "Tipping": "tip.png",
            "AirportPassengers": "airport.png",
            "BestFoodDeliveryApps": "cutlery.png",
            "BestBikeRentals": "bicycle.png",
            "BestScooterRentals": "hand-gesture.png",
            "BestCarRentals": "car-rent.png",
            "PublicDrinking": "drink-water.png",
            "LargestPark": "grassland.png",
            "NumberUniversityStudents": "education.png",
            "BestSimCard": "sim-card.png",
            "MostTouristsFrom": "traveler.png",
            "MostCommonSupermarkets": "shooping_cart.png",
            "TimeShopsClose": "shop.png",
            "NightlifeNeighbourhoods": "drink.png",
            "FamousHistoricalFigures": "historic_person.png",
            "DrivingSide": "driving_side.png",
            "MostPopularSportTeams": "919420.png",
            "MostDrunkBeerBrands": "beerfest.png",
            "MainIndustries": "economic.png",
            "MajorReligion": "religion.png",
            "FamousCityLegends": "legend.png",
            "FamousBirthplaces": "famous_person.png",
            "LargestMuseum": "many_museums.png",
            "LargestCompanies": "companies.png",
            "OldestGolfCourses": "golf-course-64.png",
            "MostCommonCarModels": "car_models.png",
            "EventWebsites": "events.png",
            "BestTouristsTourOperators": "tourist_operator.png",
            "MostKnownGolfers": "famous_golfer.png",
            "GolfWebsites": "golf.png"
        };
        this.setState({ infos_emoji: infos_emoji, infos_obj });
    }
    updateContents() {
    }
    renderInfos() {
        let Infos = this.state.infos_obj;
        let Infos_Emo = this.state.infos_emoji;
        let infos_data = this.state.infos_data;
        return Object.keys(Infos).map((info) => {
            if (infos_data[info] && infos_data[info] !== "None as of my last update in 2021" && infos_data[info] !== "Unknown") {
                return (
                    <div className="info_box">
                        <div className="box">
                            <div className="para">
                                <img src={"/icons/" + Infos_Emo[info]} />{Infos[info]}
                            </div>
                            <div className="value">
                                {infos_data[info]}
                            </div>
                        </div>
                    </div>
                );
            };
        });
    }
    render() {
        return (
            <div className="city_infos">
                {this.renderInfos()}
            </div>
        )
    }
}    