import React, {Component} from 'react';
import axios from 'axios';
import localhostName from "../config.json";
import {addCommas} from "../functions/functions.js"
import $ from 'jquery';

export default class CityProsCons extends Component {
    constructor(props) {
        super(props);
        this.state = {
            proscons: {pros: [], cons: []},
            compare_active: false,
            compare_city: {},
            compare_input: "",
            selected_type: "pro",
            cities_list: (this.props.cities_list || []),
        };
    }
    componentDidMount() {
        axios.get(localhostName.host + "/Data/ProsCons/Cities/" + this.props.cityName)
            .then(res => {
                this.setState({ data_loaded: true, proscons: res.data });
            })
            .catch(function (error) {
                console.log(error);
            });    
    }
    onChangePro = e => {
        this.setState({ selected_type: e });
    }
    select_compare_city(city){
        axios.get(localhostName.host + "/Data/Costs/Cities/" + city.name)
            .then(res => {
                console.log(res);
                this.setState({ compare_city_costs: res.data, compare_active: true, compare_city: city });
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    render() {
        let proscons = this.state.proscons;
        let pro_counter = 0;
        let con_counter = 0;
        return (
            <div className="city_scores city_proscons">
                {/*<div className="video_topbar">
                    <div className="filter_buttons">
                        <h3>For</h3>
                        <div className={"f_button " + (this.state.video_type === "walk" ? "selected" : "")} onClick={() => { this.changeType("walk") }}><img class="icon" src="/icons/walk.png" /><div>All</div></div>
                        <div className={"f_button " + (this.state.video_type === "golf" ? "selected" : "")} onClick={() => { this.changeType("golf") }}><img class="icon" src="/icons/golf-course-64.png" /><div>Buddy Trip</div></div>
                        <div className={"f_button " + (this.state.video_type === "sightseeing" ? "selected" : "")} onClick={() => { this.changeType("sightseeing") }}><img class="icon" src="/icons/sightseeing.png" /><div>Family Trip</div></div>
                        <div className={"f_button " + (this.state.video_type === "food" ? "selected" : "")} onClick={() => { this.changeType("food") }}><img class="icon" src="/icons/food.png" /><div>Business Trip</div></div>
                        <div className={"f_button " + (this.state.video_type === "travel" ? "selected" : "")} onClick={() => { this.changeType("travel") }}><img class="icon" src="/icons/tourism.png" /><div>Single Trip</div></div>
                    </div>
                </div>*/}
                <div className="proscons_box">
                    <div className="select_bar">
                        <div className={"select_button pro " + (this.state.selected_type === "pro" ? "selected" : "")} onClick={()=>this.onChangePro("pro")}>Pros</div>
                        <div className={"select_button con " + (this.state.selected_type === "con" ? "selected" : "")} onClick={()=>this.onChangePro("con")}>Cons</div>
                    </div>
                    {this.state.selected_type === "pro" ? <div className="argument pro">
                        <div className="ar_headline">From a golf traveler's perspective, here are the top 15 positive things often said after their first visit to {this.props.cityName}:</div>
                        <div className="text_list">
                            {proscons.pros.map((p)=>{
                                pro_counter += 1;
                                return(
                                    <div className="single_argument">
                                        <div><span>{pro_counter}. {p}</span></div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>: ""}
                    {this.state.selected_type === "con" ? <div className="argument cons">
                        <div className="ar_headline">From a golf traveler's perspective, here are the top 10 negative things often said after their first visit to {this.props.cityName}:</div>
                        <div className="text_list">
                            {proscons.cons.map((c)=>{
                                con_counter += 1;
                                return(
                                    <div className="single_argument">
                                        <div><span>{con_counter}. {c}</span></div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>: ""}
                </div>
            </div>            
        )
    }
}    