import React, { Component } from 'react';
import axios from 'axios';
import localhostName from "../config.json";
import LoadingSVG from './LoadingSVG';
import ModalBoot from '../components/Modal';

export default class CityBadges extends Component {
    constructor(props) {
        super(props);
        this.state = {
            city: this.props.city_name,
            badges: (window.badges || []),
            badge_type: "badge_souvenirs",
            selected_badge: { id: { badgeId: "" } },
            selected_video: { id: { videoId: "" } },
            data_loaded: true,
        };
        this.showFormButton = this.showFormButton.bind(this);
    }
    playVideo(video) {
        this.setState({ selected_video: video });
        this.showFormButton("playVideo", true);
    }
    renderBadges() {
        let badges = this.state.badges;
        badges = badges.filter(b => b.cities.includes(this.props.cityName));
        console.log(this.state.badges);
        if (!badges) {
            return (<span></span>)
        }
        return badges.map((badge) => {
            return (
                <div className="badge_single" >
                    <div className="box">
                        <img className="thumb" src={"/badges/" + badge.thumb} onError={(e) => {e.target.src = "/badges/default.png";}} />
                        <div>
                            <div className="title">{badge.name} {badge.number}</div>
                            <div className="desc">{badge.desc}</div>
                        </div>
                        <div class="BucketCollectButton"><div class="to_collect">+Collect</div><div class="to_bucketlist">+Bucket List</div></div>
                    </div>
                </div>
            )
        });
    }
    changeType(type) {
        this.setState({ badge_type: type });
    }
    showFormButton(targetShow, change) {
        this.setState({ [targetShow]: change });
    }
    render() {
        return (
            <div className="city_badges">
                <h2>Badges for {this.props.cityName}</h2>
                <div className="what_badges">
                    <div>
                    Golftus Badges are collectible rewards that you can earn by playing at specific golf courses or specific cities and countries. These badges can range from easy to obtain to challenging to achieve, making them a fun and exciting way to track your golfing journey and experiences.
                    </div>
                </div>
                <div className={"badges_list " + (!this.state.data_loaded ? "data_loading" : "")} key={this.state.badges}>
                    <div className="loading_svg">
                        <LoadingSVG />
                    </div>
                    {this.renderBadges()}
                </div>
                <ModalBoot show={this.state.badgesExpl} hide={this.showFormButton} content={"badgesExpl"} className="modal_youtubeVideo">
                    <h2>What are Badges?</h2>
                    <div>
                        Golftus Badges are collectible rewards that you can earn by playing at specific golf courses or specific cities and countries. These badges can range from easy to obtain to challenging to achieve, making them a fun and exciting way to track your golfing journey and experiences.
                    </div>
                </ModalBoot >
            </div>
        )
    }
}    