import React, { Component } from 'react';
import { Link } from "react-router-dom";
import localhostName from "./config.json";
import $ from 'jquery';
import { getCookie } from '../functions/functions.js';
let lang = getCookie("lang");

export default class Article extends Component {
  constructor() {
    super();
    this.state = {

    };
  }
  componentDidMount() {
    let url = new URL(window.location.href);
    let article_name = url.searchParams.get("a");
    if (article_name) {
      $("article .article_content").load(localhostName.host + `/articles/${article_name}.html`,function(res){
        if(res.length > 1){
          $(".page_name_a").addClass("show");
        }else{
          $(".page_name_a").removeClass("show");
        }
      });
    };
  }
  render() {
    return (
      <div className="container article">
        <article>
          <div className="article_content">
          </div>
        </article>
      </div>
    )
  }
}

