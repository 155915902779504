import React, { Component } from 'react';
import { InstagramEmbed } from 'react-social-media-embed';


export default class Instagram extends Component {
    componentDidMount() {
        // Load the Instagram Embed API script
        const script = document.createElement('script');
        script.src = 'https://www.instagram.com/embed.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            // Clean up the script when the component is unmounted
            document.body.removeChild(script);
        };
    }
    render() {
        return (
            <div className="insta_photos">
                {(this.props.urls || []).map(insta => {
                    return (
                        <InstagramEmbed url={"https://www.instagram.com/p/" + insta} width={"320px"} />
                    )
                })}
            </div>
        )
    }
}  