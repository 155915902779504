import React, { Component } from 'react';
import GolfCourses from './GolfCourses';
import GolfEntertainment from './GolfEntertainment';

export default class CityGolf extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: "course",
        };
        this.showFormButton = this.showFormButton.bind(this);
    }
    componentDidMount() {
        let url = new URL(window.location.href);
        let course_id = url.searchParams.get("c");
        this.setState({ course_id });
    }
    showFormButton(targetShow, change) {
        this.setState({ [targetShow]: change });
    }
    render() {
        return (
            <div className="city_golf_overview">
                <div className="overview_filter_settings">
                    <div>Show</div>
                    <div className="filter">
                        <div className={this.state.type === "course" ? "selected" : ""} onClick={() => this.showFormButton("type", "course")}><img className="image_icon" src="/icons/golf.png" />Courses</div>
                        <div className={this.state.type === "entertainment" ? "selected" : ""} onClick={() => this.showFormButton("type", "entertainment")}><img className="image_icon" src="/icons/net.png" />Golf Entertainment & Training</div>
                    </div>
                </div>
                {this.state.type === "course" ? <GolfCourses reload={this.props.reload} course_id={this.state.course_id} user_data={this.props.user_data || {}} key={this.props.key} logged={this.props.logged} cityName={this.props.cityName} /> : ""}
                {this.state.type === "entertainment" ? <GolfEntertainment reload={this.props.reload} course_id={this.state.course_id} user_data={this.props.user_data || {}} key={this.props.key} logged={this.props.logged} cityName={this.props.cityName} /> : ""}
            </div>
        )
    }
}    