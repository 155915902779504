import React, { Component } from 'react';
import axios from 'axios';
import localhostName from "../config.json";
import LoadingSVG from './LoadingSVG';
import $ from 'jquery';

function hide(e){
    console.log(e);
    $(e).css("display", "none");
    $(e).parent().find(".text").css("display", "inherit");
};
export default class CityQuiz extends Component {
    constructor(props) {
        super(props);
        this.state = {
            city: this.props.city_name,
            quizes: [],
            quiz_type: "quiz_souvenirs",
            selected_quiz: { id: { quizId: "" } },
            selected_video: { id: { videoId: "" } },
            data_loaded: false,
        };
        this.showFormButton = this.showFormButton.bind(this);
    }
    componentDidMount() {
        axios.get(localhostName.host + "/Data/Quiz/Cities/" + this.props.cityName)
            .then(res => {
                let quizes = res.data;
                this.setState({ data_loaded: true, quizes: quizes });
            })
            .catch(function (error) {
                console.log(error);
            });
        
    }
    playVideo(video) {
        this.setState({ selected_video: video });
        this.showFormButton("playVideo", true);
    }
    renderQuiz() {
        let quizes = this.state.quizes;
        function shuffle(a) {
            var j, x, i;
            for (i = a.length - 1; i > 0; i--) {
                j = Math.floor(Math.random() * (i + 1));
                x = a[i];
                a[i] = a[j];
                a[j] = x;
            }
            return a;
        };
        shuffle(quizes);
        return quizes.map((quiz) => {
            return (
                <div className="quiz_single" >
                    <div className="box">
                        <div>
                            <div className="question">{quiz.question}</div>
                            <div className="options">{quiz.options.map(option => {return(<div>{option}</div>)})}</div>
                        </div>
                        <div className="answer">
                            <div className="button" onClick={(e)=> hide(e.target)}>Show answer</div>
                            <div className="text">{quiz.answer}</div>
                        </div>
                    </div>
                </div>
            )
        });
    }
    changeType(type) {
        this.setState({ quiz_type: type });
    }
    showFormButton(targetShow, change) {
        this.setState({ [targetShow]: change });
    }
    render() {
        let selected_quiz = this.state.selected_quiz;
        return (
            <div className="city_quizes">
                <div className={"quizes_list " + (!this.state.data_loaded ? "data_loading" : "")} key={this.state.quizes}>
                    <div className="loading_svg">
                        <LoadingSVG />
                    </div>
                    {this.renderQuiz()}
                </div>
            </div>
        )
    }
}    